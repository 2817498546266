import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
	AccountCircle as AccountCircleIcon,
	ExitToApp as ExitToAppIcon,
	Home as HomeIcon,
	PinDrop as PinDropIcon,
	LocalAtm as LocalAtmIcon,
	AccessTime as AccessTimeIcon,
	LocalOffer as LocalOfferIcon,
	Loyalty as LoyaltyIcon,
	LocalActivity as LocalActivityIcon,
	AttachMoney as AttachMoneyIcon,
} from '@material-ui/icons';
import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';

import { logoutUser } from '../../../utilities/userAuthentication';

import CustomerDetailsPage from './subPages/CustomerDetailsPage/CustomerDetailsPage';
import DashboardHome from './subPages/DashboardHome/DashboardHome';
import MyCheckInsPage from './subPages/MyCheckInsPage/MyCheckInsPage';
import MyLoyaltyAwardsPage from './subPages/MyLoyaltyAwardsPage/MyLoyaltyAwardsPage';
import MyLoyaltyPointsPage from './subPages/MyLoyaltyPointsPage/MyLoyaltyPointsPage';
import MyReviewsPage from './subPages/MyReviewsPage/MyReviewsPage';

// Defining which route path render which Component.
const baseRoutePath = '/dashboard';
export const routePaths = {
	dashboardHome: {
		path: baseRoutePath,
		component: <DashboardHome />,
	},
	customerDetails: {
		path: `${baseRoutePath}/customerDetails`,
		component: <CustomerDetailsPage />,
	},
	myCheckIns: {
		path: `${baseRoutePath}/myCheckIns`,
		component: <MyCheckInsPage />,
	},
	myLoyaltyAwards: {
		path: `${baseRoutePath}/myLoyaltyAwards`,
		component: <MyLoyaltyAwardsPage />,
	},
	myReviews: {
		path: `${baseRoutePath}/myReviews`,
		component: <MyReviewsPage />,
	},
	myLoyaltyPoints: {
		path: `${baseRoutePath}/myLoyaltyPoints`,
		component: <MyLoyaltyPointsPage />,
	},
};

export const CustomerDashboard__MenuLinks = (props) => {
	const { currentRoutePath, history } = props;

	return (
		<div>
			<List>
				<Link to={routePaths.dashboardHome.path}>
					<ListItem
						button
						selected={currentRoutePath === routePaths.dashboardHome.path}>
						<ListItemIcon>
							<HomeIcon />
						</ListItemIcon>
						<ListItemText primary='Home' />
					</ListItem>
				</Link>

				<Link to={routePaths.customerDetails.path}>
					<ListItem
						button
						selected={currentRoutePath === routePaths.customerDetails.path}>
						<ListItemIcon>
							<AccountCircleIcon />
						</ListItemIcon>
						<ListItemText primary='Account Details' />
					</ListItem>
				</Link>

				<Divider />

				<Link to={routePaths.myCheckIns.path}>
					<ListItem
						button
						selected={currentRoutePath === routePaths.myCheckIns.path}>
						<ListItemIcon>
							<PinDropIcon />
						</ListItemIcon>
						<ListItemText primary='My CheckIns' />
					</ListItem>
				</Link>

				<Link to={routePaths.myLoyaltyAwards.path}>
					<ListItem
						button
						selected={currentRoutePath === routePaths.myLoyaltyAwards.path}>
						<ListItemIcon>
							<LoyaltyIcon />
						</ListItemIcon>
						<ListItemText primary='My Loyalty Awards' />
					</ListItem>
				</Link>

				<Link to={routePaths.myReviews.path}>
					<ListItem
						button
						selected={currentRoutePath === routePaths.myReviews.path}>
						<ListItemIcon>
							<LocalActivityIcon />
						</ListItemIcon>
						<ListItemText primary='My Reviews' />
					</ListItem>
				</Link>

				<Link to={routePaths.myLoyaltyPoints.path}>
					<ListItem
						button
						selected={currentRoutePath === routePaths.myLoyaltyPoints.path}>
						<ListItemIcon>
							<AttachMoneyIcon />
						</ListItemIcon>
						<ListItemText primary='My Total Points' />
					</ListItem>
				</Link>

				<Link to=''>
					<ListItem button selected={false}>
						<ListItemIcon>
							<LocalAtmIcon />
						</ListItemIcon>
						<ListItemText primary='Voucher & Coupons' />
					</ListItem>
				</Link>

				<Link to=''>
					<ListItem button selected={false}>
						<ListItemIcon>
							<AccessTimeIcon />
						</ListItemIcon>
						<ListItemText primary='Last Minute Deals' />
					</ListItem>
				</Link>

				<Link to=''>
					<ListItem button selected={false}>
						<ListItemIcon>
							<LocalOfferIcon />
						</ListItemIcon>
						<ListItemText primary='Upcoming Offers' />
					</ListItem>
				</Link>
			</List>

			<Divider />

			<List>
				<ListItem
					button
					onClick={() => {
						logoutUser({ redirectTo: '/login' });
					}}>
					<ListItemIcon>
						<ExitToAppIcon />
					</ListItemIcon>
					<ListItemText primary='Log Out' />
				</ListItem>
			</List>
		</div>
	);
};

export const CustomerDashboard__MenuRoutes = (props) => {
	return (
		<Switch>
			<Route path={routePaths.customerDetails.path}>
				{routePaths.customerDetails.component}
			</Route>

			<Route path={routePaths.myCheckIns.path}>
				{routePaths.myCheckIns.component}
			</Route>

			<Route path={routePaths.myLoyaltyAwards.path}>
				{routePaths.myLoyaltyAwards.component}
			</Route>

			<Route path={routePaths.myReviews.path}>
				{routePaths.myReviews.component}
			</Route>

			<Route path={routePaths.myLoyaltyPoints.path}>
				{routePaths.myLoyaltyPoints.component}
			</Route>

			<Route path={routePaths.dashboardHome.path}>
				{routePaths.dashboardHome.component}
			</Route>
		</Switch>
	);
};
