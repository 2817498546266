import {
	PinDrop as PinDropIcon,
	Email as EmailIcon,
	Call as CallIcon,
} from '@material-ui/icons';
import { DateTime } from 'luxon';
import React from 'react';

import './CustomerCheckInItemCard.css';

const CustomerCheckInItemCard = (props) => {
	const {
		checkInValues = {},
		showUserDetails = false, // Determine, showing sections like 'CustomerCheckInItemCard__details__customerDetails' and 'CustomerCheckInItemCard__points__totalUserPointValue'
	} = props;

	const {
		Detail = '',
		Points = '',
		CompanyName = '',
		LocationName = '',
		CreatedDate = new Date().toISOString(),
		CustomersDetail = [{}],
	} = checkInValues;

	const {
		FirstName = '',
		LastName = '',
		Email = '',
		MobileNumber = '',
		TelephoneNumber = '',
		TotalPoints = '',
	} = CustomersDetail[0];

	return (
		<div className='CustomerCheckInItemCard'>
			<div className='CustomerCheckInItemCard__logo'>
				<PinDropIcon />
			</div>

			<div className='CustomerCheckInItemCard__details'>
				<div className='CustomerCheckInItemCard__details__title'>
					CheckIn at {CompanyName} - {LocationName}
				</div>

				<div className='CustomerCheckInItemCard__details__desc'>
					<div>{Detail}</div>
				</div>

				<br />

				<div className='CustomerCheckInItemCard__details__bottom'>
					{showUserDetails && (
						<div className='CustomerCheckInItemCard__details__customerDetails'>
							<div className='CustomerCheckInItemCard__details__customerDetails__name'>
								<span>
									{FirstName} {LastName}
								</span>
							</div>

							<div>
								<span>
									{Email && (
										<>
											<EmailIcon /> {Email}
										</>
									)}
								</span>
								<span>
									{(MobileNumber || TelephoneNumber) && (
										<>
											<CallIcon />
											{MobileNumber} / {TelephoneNumber}
										</>
									)}
								</span>
							</div>
						</div>
					)}

					<div className='CustomerCheckInItemCard__details__time'>
						<div>{DateTime.fromISO(CreatedDate).toRelative()}</div>
					</div>
				</div>
			</div>

			<div className='CustomerCheckInItemCard__points'>
				<div>
					<div className='CustomerCheckInItemCard__points__pointValue'>
						<div>
							<div>{Points}</div>
							<div>POINTS</div>
						</div>
					</div>

					{showUserDetails && TotalPoints && (
						<div className='CustomerCheckInItemCard__points__totalUserPointValue'>
							TOTAL : {TotalPoints}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CustomerCheckInItemCard;
