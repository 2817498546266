import { createAsyncThunk } from '@reduxjs/toolkit';

import { getCheckInDocuments as getCheckInDocumentsAPI } from '../../../../utilities/apiRequests/witmegLoyaltyServerRequests';

import sliceConstants from './customerCheckInsPageSliceConstants';

export const getCheckInDocuments = createAsyncThunk(
	`${sliceConstants.name}/getCheckInDocuments`,
	async (options = {}, thunkAPI) => {
		const { reqData } = options;

		try {
			return await getCheckInDocumentsAPI({ ...reqData });
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

const extraActions = { getCheckInDocuments };

export default extraActions;
