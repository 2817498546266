import { createAsyncThunk } from '@reduxjs/toolkit';

import {
	getReviewDocuments as getReviewDocumentsAPI,
	addReviewPost as addReviewPostAPI,
} from '../../../../utilities/apiRequests/witmegLoyaltyServerRequests';

import sliceConstants from './myReviewsPageSliceConstants';

export const getReviewDocuments = createAsyncThunk(
	`${sliceConstants.name}/getReviewDocuments`,
	async (options = {}, thunkAPI) => {
		const { reqData } = options;

		try {
			return await getReviewDocumentsAPI({ ...reqData });
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

export const addReviewPost = createAsyncThunk(
	`${sliceConstants.name}/addReviewPost`,
	async (options = {}, thunkAPI) => {
		try {
			const { reqBody } = options;

			return await addReviewPostAPI(reqBody);
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

const extraActions = { getReviewDocuments, addReviewPost };

export default extraActions;
