import { MenuItem } from '@material-ui/core';
import { countries as countryList } from 'countries-list';
import * as Yup from 'yup';

export const CustomerDetailsPageValidationSchema = Yup.object().shape({
	UserName: Yup.string().required('Required'),
	Email: Yup.string().email('Invalid Email').required('Required'),
	Password: Yup.string().min(
		8,
		'Password should be at least 8 characters long',
	),
	OriginatedOrganizationID: Yup.string().required('Required'),

	FirstName: Yup.string()
		.min(2, 'Too Short')
		.max(50, 'Too Long')
		.required('Required'),
	LastName: Yup.string()
		.min(2, 'Too Short')
		.max(50, 'Too Long')
		.required('Required'),
	CompanyName: Yup.string().min(2, 'Too Short'),
	Addresses: Yup.array()
		.of(
			Yup.object().shape({
				AddressType: Yup.string(),
				AddressLine1: Yup.string().min(1, 'Too Short').required('Required'),
				AddressLine2: Yup.string(),
				StateProvinceRegion: Yup.string()
					.min(1, 'Too Short')
					.required('Required'),
				PostZipCode: Yup.string().min(1, 'Too Short').required('Required'),
				City: Yup.string().min(1, 'Too Short').required('Required'),
				Country: Yup.string().required('Required'),
				Email: Yup.string().email('Invalid Email').required('Required'),
				Phone: Yup.string().min(1, 'Too Short').required('Required'),
				Fax: Yup.string(),
			}),
		)
		.nullable(),

	DefaultPaymentType: Yup.string(),
	PaymentTypes: Yup.string().nullable(),
	PaymentCardDetails: Yup.string().nullable(),
	CustomerVatNo: Yup.string(),

	RegisteredDate: Yup.string().required('Required'),
	IsActive: Yup.string().required('Required'),
});

export const CustomerDetailsPageInitialValues = {
	UserName: '',
	Email: '',
	Password: '',
	OriginatedOrganizationID: '',

	FirstName: '',
	LastName: '',
	Addresses: [
		{
			AddressType: '',
			AddressLine1: '',
			AddressLine2: '',
			StateProvinceRegion: '',
			PostZipCode: '',
			City: '',
			Country: '',
			Email: '',
			Phone: '',
			Fax: '',
		},
	],

	DefaultPaymentType: '',
	PaymentTypes: '',
	PaymentCardDetails: '',
	CustomerVatNo: '',

	RegisteredDate: '',
	IsActive: '',
};

export const formattedCountryList = Object.values(countryList)
	.sort((a, b) => {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;
	})
	.map((country) => {
		return (
			<MenuItem key={country.name} value={country.name}>
				{country.name}
			</MenuItem>
		);
	});
