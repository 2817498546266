import {
	Email as EmailIcon,
	Call as CallIcon,
	Loyalty as LoyaltyIcon,
} from '@material-ui/icons';
import { DateTime } from 'luxon';
import React from 'react';

import './CustomerLoyaltyAwardsItemCard.css';

const CustomerLoyaltyAwardsItemCard = (props) => {
	const {
		loyaltyAwardValues = {},
		showUserDetails = false, // Determine, showing sections like 'CustomerLoyaltyAwardsItemCard__details__customerDetails' and 'CustomerLoyaltyAwardsItemCard__points__totalUserPointValue'
	} = props;

	const {
		SchemeType = '',
		Detail = '',
		InvoiceNo = '',
		InvoiceAmount = '',
		AwardingPoints = '',
		CompanyName = '',
		LocationName = '',
		CreatedDate = '',
		CustomersDetail = [{}],
	} = loyaltyAwardValues;

	const {
		FirstName = '',
		LastName = '',
		Email = '',
		MobileNumber = '',
		TelephoneNumber = '',
		TotalPoints = '',
		Currency = '',
	} = CustomersDetail[0];

	return (
		<div className='CustomerLoyaltyAwardsItemCard'>
			<div className='CustomerLoyaltyAwardsItemCard__logo'>
				<LoyaltyIcon />
			</div>

			<div className='CustomerLoyaltyAwardsItemCard__details'>
				<div className='CustomerLoyaltyAwardsItemCard__details__title'>
					Awarded at {CompanyName} - {LocationName}
				</div>

				<div className='CustomerLoyaltyAwardsItemCard__details__schemeType'>
					<div>
						<strong>For :</strong> {SchemeType}
					</div>
				</div>

				<div className='CustomerLoyaltyAwardsItemCard__details__desc'>
					<div>
						<strong>Detail :</strong> {Detail}
					</div>
				</div>

				{InvoiceNo && InvoiceAmount > 0 && (
					<div className='CustomerLoyaltyAwardsItemCard__details__invoiceDetails'>
						<div>
							<strong>Invoice : </strong>
							{Currency} {InvoiceAmount}/- ({InvoiceNo})
						</div>
					</div>
				)}

				<br />

				<div className='CustomerLoyaltyAwardsItemCard__details__bottom'>
					{showUserDetails && (
						<div className='CustomerLoyaltyAwardsItemCard__details__customerDetails'>
							<div className='CustomerLoyaltyAwardsItemCard__details__customerDetails__name'>
								<span>
									{FirstName} {LastName}
								</span>
							</div>

							<div>
								<span>
									{Email && (
										<>
											<EmailIcon /> {Email}
										</>
									)}
								</span>
								<span>
									{(MobileNumber || TelephoneNumber) && (
										<>
											<CallIcon />
											{MobileNumber} / {TelephoneNumber}
										</>
									)}
								</span>
							</div>
						</div>
					)}

					<div className='CustomerLoyaltyAwardsItemCard__details__time'>
						<div>{DateTime.fromISO(CreatedDate).toRelative()}</div>
					</div>
				</div>
			</div>

			<div className='CustomerLoyaltyAwardsItemCard__points'>
				<div>
					<div className='CustomerLoyaltyAwardsItemCard__points__pointValue'>
						<div>
							<div>{AwardingPoints}</div>
							<div>POINTS</div>
						</div>
					</div>

					{showUserDetails && TotalPoints && (
						<div className='CustomerLoyaltyAwardsItemCard__points__totalUserPointValue'>
							TOTAL : {TotalPoints}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default CustomerLoyaltyAwardsItemCard;
