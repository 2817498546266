import withWidth from '@material-ui/core/withWidth';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { loginTypes } from '../../configs/constants';

import LoginForm from './forms/LoginForm/LoginForm';

import './LoginPage.css';

class LoginPage extends Component {
	render() {
		const { loginType = loginTypes.customer } = this.props;
		const { width: currentPageBreakpointSize, history, location } = this.props;
		const { state } = location;

		const reactRouterState = state || {};

		// Passing common props for used in MaterialUI Inputs
		const formInputSize =
			currentPageBreakpointSize === 'xs' ? 'small' : 'medium';
		const commonMaterialUI__InputProps = {
			fullWidth: true,
			variant: 'outlined',
			size: formInputSize,
		};

		return (
			<div className='LoginPage'>
				<div className='LoginPage__container'>
					<div className='LoginPage__header'>
						<img
							src='https://cdn.neurolage.com/ecologital/witmeg_logo.png'
							alt=''
						/>
						<h1>
							{loginType === loginTypes.business
								? 'Business Login'
								: 'Customer Login'}
						</h1>
					</div>
					<div className='LoginPage__body'>
						<LoginForm
							history={history}
							commonMaterialUI__InputProps={commonMaterialUI__InputProps}
							reactRouterState={reactRouterState}
							loginType={loginType}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withWidth()(LoginPage));
