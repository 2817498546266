import {
	InputAdornment,
	CircularProgress,
	FormHelperText,
} from '@material-ui/core';
import { Email as EmailIcon } from '@material-ui/icons';
import React from 'react';

import FormikTextField from '../../../../components/widgets/formikInputs/materialUI/FormikTextField/FormikTextField';
import './SignupEmailEntryFormControls.css';

export default function SignupEmailEntryFormControls(props) {
	const {
		// ***** Props Passdown From Formik ***** (Such as errors, touched, getFieldProps, etc...)
		handleSubmit,
		isSubmitting,
		status, // Manually setted values by us. In our case used to set any API errors occurred while submitting the form.

		// **** Custom Props Manually Passed By Us *****
		commonMaterialUI__InputProps,
	} = props;

	return (
		<form
			className='SignupEmailEntryFormControls'
			onSubmit={handleSubmit}
			noValidate // To disable HTLML5 builtin validations.
		>
			<div className='SignupEmailEntryFormControls__header'>
				<p>
					Please enter your email to start signup process. We will send you a
					verification code to that email. So we can confirm, this email is
					indeed belong to you.
				</p>
			</div>

			<div className='SignupEmailEntryFormControls__inputs'>
				<FormikTextField
					name='Email'
					materialUIProps={{
						...commonMaterialUI__InputProps,
						required: true,
						placeholder: 'Email*',
						InputProps: {
							startAdornment: (
								<InputAdornment position='start'>
									<EmailIcon color='disabled' />{' '}
								</InputAdornment>
							),
						},
					}}
				/>
			</div>

			<div className='SignupEmailEntryFormControls__footer'>
				<FormHelperText error filled>
					{status.error}
				</FormHelperText>

				<button
					type='submit'
					className='SignupEmailEntryFormControls__footer__signupButton'
					disabled={isSubmitting}>
					<div>SEND VERIFICATION CODE</div>
					<div className='SignupEmailEntryFormControls__footer__signupButton__progress'>
						{isSubmitting && <CircularProgress size={20} />}
					</div>
				</button>
			</div>
		</form>
	);
}
