import { Menu, MenuItem } from '@material-ui/core';
import { AccountCircle as AccountCircleIcon } from '@material-ui/icons';
import React from 'react';

import { LogoutUserButtonWrapper } from '../../../../../utilities/userAuthentication';

import './DashboardHome.css';

export default class DashboardHome extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			userMenuAnchorEl: null, // Used to toggle UserMenu Open/Close.
		};

		this.toggleUserMenu = this.toggleUserMenu.bind(this);
	}

	toggleUserMenu(e) {
		const { userMenuAnchorEl } = this.state;

		this.setState({
			userMenuAnchorEl: userMenuAnchorEl ? null : e.currentTarget,
		});
	}

	render() {
		const { userMenuAnchorEl } = this.state;

		return (
			<div className='DashboardHomeBis'>
				<div className='DashboardHomeBis__header'>
					<div className='DashboardHomeBis__header__right'>
						<div className='DashboardHomeBis__header__userMenu'>
							<AccountCircleIcon onClick={this.toggleUserMenu} />
							<Menu
								id='simple-menu'
								anchorEl={userMenuAnchorEl}
								keepMounted
								open={Boolean(userMenuAnchorEl)}
								onClose={this.toggleUserMenu}
								getContentAnchorEl={null}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}>
								<MenuItem
									onClick={() => {
										this.toggleUserMenu();
									}}>
									<LogoutUserButtonWrapper>Log Out</LogoutUserButtonWrapper>
								</MenuItem>
							</Menu>
						</div>
					</div>
				</div>

				<div className='DashboardHomeBis__body'>
					<div className='DashboardHomeBis__body__welcome'>
						<div>
							<img
								src='https://cdn.neurolage.com/ecologital/witmeg_logo.png'
								alt='Witmeg Logo'
							/>
							<h1>Welcome to Loyalty Business Dashboard</h1>
							<h2>Select Necessary Section From Main Menu To Get Started</h2>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
