import { createAsyncThunk } from '@reduxjs/toolkit';
import lodash from 'lodash';

import { reqStatusTypes } from '../../../../configs/constants';
import globalValues from '../../../../configs/globalValues';
import {
	encryptLocationId as encryptLocationIdAPI,
	getLocationDetailsByLocationId as getLocationDetailsByLocationIdAPI,
} from '../../../../utilities/apiRequests/witmegApiServerRequests';
import { handleAsyncThunkCancellation } from '../../../reduxHelpers';

import sliceConstants from './qrCodeGeneratorPageSliceConstants';

export const generateQrValue = createAsyncThunk(
	`${sliceConstants.name}/generateQrValue`,
	async (options = {}, thunkAPI) => {
		try {
			const {
				selectedOrganization,
				selectedCompany,
				selectedLocation,
				LocationID,
			} = options;

			const baseURL = globalValues.baseURLS.WEBSITE__BASEURL;
			const orgAddingPartialURL = `${baseURL}/org/customer/register/`;
			let qrValue = ''; // For Ex. Final value will be like "http://localhost:3000/org/customer/register/92b9e994acc7525a2148180df39c490d1ebb943eebae2fd6d4f8858941746f02e98c9555dff3c9b4"
			const qrValueDescription = `${selectedOrganization.label} - ${selectedCompany.label} - ${selectedLocation.label}`;

			// Checking is there a pre generated "Encrypted Location Id" for selected location.
			const locationDetails = await getLocationDetailsByLocationIdAPI({
				LocationId: LocationID,
			});
			const preGeneratedEncryptedLocationId =
				locationDetails.LoyaltyCustomerRegistrationURL;

			if (preGeneratedEncryptedLocationId) {
				qrValue = orgAddingPartialURL + preGeneratedEncryptedLocationId;
			} else {
				const encryptedLocationId = await encryptLocationIdAPI({
					ProcessString: LocationID,
				});
				qrValue = orgAddingPartialURL + encryptedLocationId;
			}

			console.customLog('DEV', 'LOCATION QR CODE : ', qrValue, LocationID);

			return {
				LocationID,
				generatedQrValue: qrValue,
				generatedQrValueDescription: qrValueDescription,
			};
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
	{
		condition: (options, thunkAPI) => {
			const { getState } = thunkAPI;
			const { LocationID } = options;

			const fullState = getState();
			const currentPathState = lodash.get(
				fullState,
				sliceConstants.currentStatePath,
			);

			const { allGeneratedQrValuesAndDescByLocationId } = currentPathState;

			const dataOfRequestedLocation =
				allGeneratedQrValuesAndDescByLocationId[LocationID];

			if (!dataOfRequestedLocation) {
				return true;
			}

			const { generatedQrValueLastUpdated } = dataOfRequestedLocation;

			return handleAsyncThunkCancellation({
				passedActionValues: options,
				reqStatusValue: reqStatusTypes.succeeded,
				reqLastUpdatedValue: generatedQrValueLastUpdated,
			});
		},
		dispatchConditionRejection: true,
	},
);

const extraActions = {
	generateQrValue,
};

export default extraActions;
