import { Formik } from 'formik';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { signupCustomer } from '../../../../utilities/apiRequests/witmegApiServerRequests';
import { sendEmail } from '../../../../utilities/apiRequests/witmegEmailServerRequests';
import { generateSignupCompleteEmail } from '../../../../utilities/email/emailTemplates/SignupCompleteEmailTemplate';
import { saveLoggedUser } from '../../../../utilities/userAuthentication';

import SignupFormControls from './SignupFormControls';

const SignupFormValidationSchema = Yup.object().shape({
	FirstName: Yup.string()
		.min(2, 'Too Short')
		.max(50, 'Too Long')
		.required('Required'),
	LastName: Yup.string()
		.min(2, 'Too Short')
		.max(50, 'Too Long')
		.required('Required'),
	Email: Yup.string().email('Invalid Email').required('Required'),
	Password: Yup.string()
		.min(8, 'Password should be at least 8 characters long')
		.required('Required'),
	ConfirmPassword: Yup.string()
		.oneOf([Yup.ref('Password'), null], 'Passwords does not match')
		.required('Required'),
	AddressLine1: Yup.string().min(1, 'Too Short').required('Required'),
	AddressLine2: Yup.string(),
	StateProvinceRegion: Yup.string().min(1, 'Too Short').required('Required'),
	PostZipCode: Yup.string().min(1, 'Too Short').required('Required'),
	City: Yup.string().min(1, 'Too Short').required('Required'),
	Country: Yup.string().required('Required'),
	Phone: Yup.string().min(1, 'Too Short').required('Required'),
});

const signupFormInitialValues = {
	FirstName: '',
	LastName: '',
	Email: '',
	Password: '',
	ConfirmPassword: '',
	AddressLine1: '',
	AddressLine2: '',
	StateProvinceRegion: '',
	PostZipCode: '',
	City: '',
	Country: '',
	Phone: '',
};
export default class SignupForm extends Component {
	render() {
		const {
			history: routerHistory,
			customFormData,
			commonMaterialUI__InputProps,
			reactRouterState,
		} = this.props;

		const { Email } = customFormData;
		const { shouldRedirect, redirectPath } = reactRouterState;

		return (
			<div className='SignupForm'>
				<div>
					<Formik
						initialStatus={{}}
						initialValues={{ ...signupFormInitialValues, Email }}
						validationSchema={SignupFormValidationSchema}
						onSubmit={async (formValues, { setSubmitting, setStatus }) => {
							try {
								const apiReqBody = {
									FirstName: formValues.FirstName,
									LastName: formValues.LastName,
									UserName: formValues.Email,
									Password: formValues.Password,
									Email: formValues.Email,
									OriginatedOrganizationID: 'ecologital',
									Addresses: [
										{
											AddressLine1: formValues.AddressLine1,
											AddressLine2: formValues.AddressLine2,
											City: formValues.City,
											StateProvinceRegion: formValues.StateProvinceRegion,
											PostZipCode: formValues.PostZipCode,
											Country: formValues.Country,
											Phone: formValues.Phone,
											Email: formValues.Email,
										},
									],
								};

								const resData = await signupCustomer(apiReqBody);

								const { AccessToken, RefreshToken } = resData;
								saveLoggedUser({
									AccessToken,
									RefreshToken,
									Email: formValues.Email,
								});

								// All is good. Customer Created.
								// Sending User a Welcome Email
								await sendEmail({
									To: formValues.Email,
									Subject: 'Welcome to WitMeg Loyalty',
									HTMLContent: generateSignupCompleteEmail({
										userName: `${formValues.FirstName} ${formValues.LastName}`,
									}),
								});

								toast.success('You have successfully signed-up .');
								setSubmitting(false);
								setStatus({
									error: '',
								});
								setTimeout(() => {
									const redirectTo = shouldRedirect
										? redirectPath
										: '/dashboard';

									routerHistory.push(redirectTo);
								}, 100);
							} catch (error) {
								const errMsg = error.customErrMsg || error.message;

								setStatus({
									error: errMsg,
								});
								setSubmitting(false);
							}
						}}
						component={(formikProps) => (
							<SignupFormControls
								{...formikProps}
								commonMaterialUI__InputProps={commonMaterialUI__InputProps}
							/>
						)}
					/>
				</div>
			</div>
		);
	}
}
