import React from 'react';
import ReactDOMServer from 'react-dom/server';

const styles = {
	WitMegEmail: {
		maxWidth: '600px',
		padding: '100px 75px',
		fontSize: '15px',
	},

	// Header
	WitMegEmail__header: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '40px',
	},
	WitMegEmail__header__img: {
		maxWidth: '200px',
	},

	// Body
	WitMegEmail__body: {},

	WitMegEmail__body__p: {
		marginBottom: '15px',
	},

	WitMegEmail__body__p___ResetLink: {
		background: '#368686',
		padding: '10px',
		textAlign: 'center',
		color: 'white',
		fontSize: '20px',
		fontWeight: 'bold',
		maxWidth: '70%',
		borderRadius: '5px',
		margin: '0 auto 20px auto',
	},

	WitMegEmail__body__p___ResetLink__a: {
		textDecoration: 'none',
		color: 'white',
	},

	WitMegEmail__body__p___salutation: {
		marginTop: '50px',
	},

	// Footer
	WitMegEmail__footer: {
		marginTop: '50px',
		textAlign: 'center',
		background: '#e8e8e8',
		padding: '10px',
	},
};

export const PasswordResetLinkEmailTemplate = (props) => {
	const { emailData = {} } = props;
	const { email = '', passwordResetLink = '' } = emailData;
	const year = new Date().getFullYear();

	return (
		<div style={styles.WitMegEmail}>
			<div style={styles.WitMegEmail__header}>
				<img
					className='WitMegEmail__header__img'
					style={styles.WitMegEmail__header__img}
					src='https://cdn.neurolage.com/ecologital/witmeg_logo.png'
					alt=''
				/>
			</div>
			<div style={styles.WitMegEmail__body}>
				<p style={styles.WitMegEmail__body__p}>Hello,</p>

				<p style={styles.WitMegEmail__body__p}>
					You have requested password reset link for your WitMeg Loyalty
					Account. ({email})
				</p>

				<p style={styles.WitMegEmail__body__p}>
					So, Please use below link to reset your password. Note that this link
					will expire shortly.
				</p>

				<p
					style={{
						...styles.WitMegEmail__body__p,
						...styles.WitMegEmail__body__p___ResetLink,
					}}>
					<a
						style={styles.WitMegEmail__body__p___ResetLink__a}
						href={passwordResetLink}
						target='__blank'>
						Goto Password Reset Link
					</a>
				</p>

				<p
					style={{
						...styles.WitMegEmail__body__p,
						...styles.WitMegEmail__body__p___salutation,
					}}>
					Witmeg Loyalty
				</p>
			</div>

			<div style={styles.WitMegEmail__footer}>
				Copyright &#169; {year} Witmeg | All Rights Reserved
			</div>
		</div>
	);
};

export function generatePasswordResetLinkEmail(emailData = {}) {
	return ReactDOMServer.renderToStaticMarkup(
		<PasswordResetLinkEmailTemplate emailData={emailData} />,
	);
}
