/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/sort-comp */
import { Loyalty as LoyaltyIcon } from '@material-ui/icons';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import CustomerLoyaltyAwardsItemCard from '../../../../../components/pageSections/dashboardPage/common/CustomerLoyaltyAwardsItemCard/CustomerLoyaltyAwardsItemCard';
import DashboardSubInfoSection from '../../../../../components/pageSections/dashboardPage/common/DashboardSubInfoSection/DashboardSubInfoSection';
import LocationSelector from '../../../../../components/pageSections/dashboardPage/common/LocationSelector/LocationSelector';
import PageLoader from '../../../../../components/widgets/loaders/PageLoader/PageLoader';
import { reqStatusTypes } from '../../../../../configs/constants';
import { sectionName as businessDashboardSectionName } from '../../../../../redux/slices/businessDashboard/businessDashboardConstants';
import { actions as businessDashboardSliceActions } from '../../../../../redux/slices/businessDashboard/businessDashboardSlice/businessDashboardSlice';
import { actions as customerLoyaltyAwardsPageSliceActions } from '../../../../../redux/slices/businessDashboard/customerLoyaltyAwardsPageSlice/customerLoyaltyAwardsPageSlice';
import { getLoggedUserDetails } from '../../../../../utilities/userAuthentication';

import './CustomerLoyaltyAwardsPage.css';

class CustomerLoyaltyAwardsPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedOptions: {},
		};

		this.fetchNecessaryInitialPageData = this.fetchNecessaryInitialPageData.bind(
			this,
		);
		this.handleGetFullOrgDetailsOfBusinessUser = this.handleGetFullOrgDetailsOfBusinessUser.bind(
			this,
		);
	}

	// Fetching Initial Org. Details used in Select Boxes.
	async handleGetFullOrgDetailsOfBusinessUser() {
		const { businessDashboardSliceActions } = this.props;

		const {
			getFullOrgDetailsOfBusinessUser: getFullOrgDetailsOfBusinessUserAction,
		} = businessDashboardSliceActions;

		const loggedUserDetails = getLoggedUserDetails();
		const { userid } = loggedUserDetails;

		await getFullOrgDetailsOfBusinessUserAction({
			UserId: userid,
			actionOptions: {
				useDurations: true,
				minimumRefetchDuration: 60 * 10, // Ten Minutes.
			},
		});
	}

	async fetchNecessaryInitialPageData() {
		// Fetching Initial Org. Details.
		await this.handleGetFullOrgDetailsOfBusinessUser();
	}

	componentDidMount() {
		this.fetchNecessaryInitialPageData();
	}

	render() {
		const { selectedOptions } = this.state;
		const { selectedLocation } = selectedOptions;

		const {
			customerLoyaltyAwardsPageSliceState,
			customerLoyaltyAwardsPageSliceActions,
			businessDashboardSliceState,
		} = this.props;

		const {
			currentLocationLoyaltyAwardsDocuments,
			getLoyaltyAwardsDocumentsReqStatus,
			getLoyaltyAwardsDocumentsReqError,
		} = customerLoyaltyAwardsPageSliceState;

		const {
			getLoyaltyAwardsDocuments: getLoyaltyAwardsDocumentsAction,
			resetAllCustomerLoyaltyAwardsPageSliceRequests: resetAllCustomerLoyaltyAwardsPageSliceRequestsAction,
		} = customerLoyaltyAwardsPageSliceActions;

		const {
			currentUserFullOrgDetails,
			getFullOrgDetailsOfBusinessUserReqStatus,
			getFullOrgDetailsOfBusinessUserReqError,
		} = businessDashboardSliceState;

		// ***** Multiple custom dynamic constants for easy usage. ******

		// Used to show a Loader to indicate some main async functionalities are in process.(Like fetchings started on ComponentDidMount).
		const isMainPageActionsRunning =
			getFullOrgDetailsOfBusinessUserReqStatus === reqStatusTypes.pending;

		const isMainPageActionsError = getFullOrgDetailsOfBusinessUserReqError;

		// Used to show a Loader to indicate some sub async functionalities in process. (Like getting specific settings of specific location)
		const isSubPageActionsRunning =
			getLoyaltyAwardsDocumentsReqStatus === reqStatusTypes.pending;

		const isSubPageActionsRunningError =
			getLoyaltyAwardsDocumentsReqStatus === reqStatusTypes.failed &&
			getLoyaltyAwardsDocumentsReqError;

		const isLocationSelected =
			selectedLocation && Boolean(selectedLocation.label);

		// Indicating Main Page Data Loading.
		if (isMainPageActionsRunning || isMainPageActionsError) {
			return (
				<div className='CustomerLoyaltyAwardsPage GC-DP-DashboardPage'>
					<PageLoader
						isFetching={isMainPageActionsRunning}
						loadingMsg='Please wait while we are fetching the details.'
						isError={isMainPageActionsError}
						errorMsg={isMainPageActionsError}
						onErrorTryAgainFn={() => this.fetchNecessaryInitialPageData()}
						shouldHideWhenEmpty
					/>
				</div>
			);
		}

		return (
			<div className='CustomerLoyaltyAwardsPage GC-DP-DashboardPage'>
				<div>
					<div className='CustomerLoyaltyAwardsPage__header GC-DP-DashboardPageHeader'>
						<h1>CUSTOMER LOYALTY AWARDS</h1>
					</div>

					<div className='CustomerLoyaltyAwardsPage__body GC-DP-DashboardPageBody'>
						<div className='CustomerLoyaltyAwardsPage__body__locationSelector GC-DP-DashboardPage__LocationSelectorWrapper'>
							<LocationSelector
								orgDetails={currentUserFullOrgDetails}
								instructionMsg='Please select the Location you want to see Loyalty Awards by
								filtering through Organization and Company List. Then click on
								the button.'
								onButtonClickFn={async (options) => {
									await getLoyaltyAwardsDocumentsAction({
										reqData: {
											LocationID: options.selectedLocation.value,
											limit: 50,
										},
									});

									// Updating our Page's local state with selected options.
									this.setState({
										selectedOptions: options,
									});
								}}
							/>
						</div>

						{/* Used to indicate sub actions like "Loading LoyaltyAwrdItems For Selected Location"  */}
						{(isSubPageActionsRunning || isSubPageActionsRunningError) && (
							<div className='CustomerLoyaltyAwardsPage__body__subPageActionLoader'>
								<PageLoader
									isFetching={isSubPageActionsRunning}
									loadingMsg=''
									isError={isSubPageActionsRunningError}
									errorMsg={isSubPageActionsRunningError}
									onErrorTryAgainTitle='Cancel'
									onErrorTryAgainFn={() => {
										// Resetting all CustomerLoyaltyAwardsPageSliceRequests to RESET errors.
										// So user will be able cancel error view and see initial component and retry again.
										resetAllCustomerLoyaltyAwardsPageSliceRequestsAction();

										// Resetting Local State
										this.setState({
											selectedOptions: {},
										});
									}}
									shouldHideWhenEmpty
								/>
							</div>
						)}

						{/* When No Location Is Selected */}
						{!isSubPageActionsRunning &&
							!isSubPageActionsRunningError &&
							!isLocationSelected && (
								<DashboardSubInfoSection
									className='CustomerLoyaltyAwardsPage__body__getStarted'
									type='GETSTARTED'
									description='Select A Location To Get Started.'
									icon={<LoyaltyIcon color='disabled' />}
								/>
							)}

						{/* When Selected Location's LoyaltyAwardDetails are Fetched Successfully. */}
						{!isSubPageActionsRunning &&
							!isSubPageActionsRunningError &&
							isLocationSelected && (
								<div className='CustomerLoyaltyAwardsPage__body__loyaltyAwards'>
									{/* When Selected Location Don't Have any LoyaltyAwards */}
									{currentLocationLoyaltyAwardsDocuments.length === 0 && (
										<DashboardSubInfoSection
											className='CustomerLoyaltyAwardsPage__body__loyaltyAwards__awardsItems--noItems'
											type='NOITEMS'
											description='	No Loyalty Awards Available For Selected Location.'
										/>
									)}

									{/* When Selected Location Have Loyalty Awards */}
									{currentLocationLoyaltyAwardsDocuments.length > 0 && (
										<div className='CustomerLoyaltyAwardsPage__body__loyaltyAwards__awardsItems'>
											{currentLocationLoyaltyAwardsDocuments
												.slice()
												.sort((a, b) => {
													return (
														new Date(b.CreatedDate) - new Date(a.CreatedDate)
													);
												})
												.map((loyaltyAwardDoc, index) => {
													return (
														<CustomerLoyaltyAwardsItemCard
															key={index}
															loyaltyAwardValues={loyaltyAwardDoc}
															showUserDetails
														/>
													);
												})}
										</div>
									)}
								</div>
							)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		businessDashboardSliceState:
			state[businessDashboardSectionName].businessDashboard,
		customerLoyaltyAwardsPageSliceState:
			state[businessDashboardSectionName].customerLoyaltyAwardsPage,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundBusinessDashboardSliceActions = bindActionCreators(
		businessDashboardSliceActions,
		dispatch,
	);
	const boundCustomerLoyaltyAwardsPageSliceActions = bindActionCreators(
		customerLoyaltyAwardsPageSliceActions,
		dispatch,
	);
	return {
		businessDashboardSliceActions: boundBusinessDashboardSliceActions,
		customerLoyaltyAwardsPageSliceActions: boundCustomerLoyaltyAwardsPageSliceActions,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CustomerLoyaltyAwardsPage);
