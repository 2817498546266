/* eslint-disable no-param-reassign */
import { current as getStateAsPlainObj } from '@reduxjs/toolkit';

const standardReducers = {
	setLinkedLocationData: (state, action) => {
		const { isLocationLinked, linkedLocationDetails } = action.payload;

		state.isLocationLinked = isLocationLinked;
		state.currentlyLinkedLocationDetails = linkedLocationDetails;
	},
};

export default standardReducers;
