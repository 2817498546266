/* eslint-disable no-param-reassign */
import { reqStatusTypes } from '../../../../configs/constants';

import { getFullOrgDetailsOfBusinessUser } from './businessDashboardSliceExtraActions';

const extraReducers = {
	// ******************************* getFullOrgDetailsOfBusinessUser() *******************************
	[getFullOrgDetailsOfBusinessUser.pending]: (state, action) => {
		state.getFullOrgDetailsOfBusinessUserReqStatus = reqStatusTypes.pending;
		state.getFullOrgDetailsOfBusinessUserReqError = '';
	},

	[getFullOrgDetailsOfBusinessUser.fulfilled]: (state, action) => {
		state.getFullOrgDetailsOfBusinessUserReqStatus = reqStatusTypes.succeeded;
		state.getFullOrgDetailsOfBusinessUserReqLastUpdated = new Date().toISOString();
		state.currentUserFullOrgDetails = action.payload;
	},

	[getFullOrgDetailsOfBusinessUser.rejected]: (state, action) => {
		state.getFullOrgDetailsOfBusinessUserReqStatus = reqStatusTypes.failed;
		state.getFullOrgDetailsOfBusinessUserReqError = action.error.message;
		state.currentUserFullOrgDetails = [];
	},
};

export default extraReducers;
