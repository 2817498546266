import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import {
	isUserLoggedIn,
	checkIsBusinessUser,
} from '../../utilities/userAuthentication';

import './HomePage.css';

const HomePage = () => {
	const userLogged = isUserLoggedIn();
	const isBusinessUser = checkIsBusinessUser();

	if (userLogged && isBusinessUser) {
		return <Redirect to='/businessDashboard' />;
	}
	if (userLogged) {
		return <Redirect to='/dashboard' />;
	}

	return (
		<div className='HomePage'>
			<div>
				<div className='HomePage__header'>
					<img
						src='https://cdn.neurolage.com/ecologital/witmeg_logo.png'
						alt='WitMeg Logo'
					/>
					<h1>Welcome to Loyalty</h1>
				</div>

				<div className='HomePage__buttons'>
					<Link to='/login' className='HomePage__buttons__login'>
						Login
					</Link>
					<Link to='/signup' className='HomePage__buttons__signup'>
						Sign Up
					</Link>
				</div>
			</div>
		</div>
	);
};

export default HomePage;
