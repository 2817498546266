import { reqStatusTypes } from '../../../../configs/constants';
import { sectionName } from '../customerDashboardConstants';

const sliceName = 'dashboardHomePage';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,

	initialState: {
		// ******************************* dummyFn() *******************************
		dummyFnReqStatus: reqStatusTypes.idle,
		dummyFnReqError: '',
		dummyValue: 'DUMMY',
	},
};

export default sliceConstants;
