/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/sort-comp */
import { Button } from '@material-ui/core';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import CustomerReviewItemCard from '../../../../../components/pageSections/dashboardPage/common/CustomerReviewItemCard/CustomerReviewItemCard';
import DashboardSubInfoSection from '../../../../../components/pageSections/dashboardPage/common/DashboardSubInfoSection/DashboardSubInfoSection';
import AddReviewModal from '../../../../../components/pageSections/dashboardPage/modals/AddReviewModal/AddReviewModal';
import PageLoader from '../../../../../components/widgets/loaders/PageLoader/PageLoader';
import { reqStatusTypes } from '../../../../../configs/constants';
import { sectionName as customerDashboardSectionName } from '../../../../../redux/slices/customerDashboard/customerDashboardConstants';
import { actions as myReviewsPageSliceActions } from '../../../../../redux/slices/customerDashboard/myReviewsPageSlice/myReviewsPageSlice';
import { actions as userSliceActions } from '../../../../../redux/slices/userSlice/userSlice';
import { getLoggedUserDetails } from '../../../../../utilities/userAuthentication';

import './MyReviewsPage.css';

class MyReviewsPage extends Component {
	async handleGetReviewDocuments() {
		const { myReviewsPageSliceActions } = this.props;

		const {
			getReviewDocuments: getReviewDocumentsAction,
		} = myReviewsPageSliceActions;

		const { userid } = getLoggedUserDetails();

		return await getReviewDocumentsAction({
			reqData: { CustomerID: userid, limit: 50 },
		});
	}

	async fetchNecessaryInitialPageData() {
		await this.handleGetReviewDocuments();
	}

	componentDidMount() {
		this.fetchNecessaryInitialPageData();
	}

	render() {
		const {
			myReviewsPageSliceState,
			myReviewsPageSliceActions,
			userSliceState,
		} = this.props;

		const {
			getReviewDocumentsReqStatus,
			getReviewDocumentsReqError,
			myReviewDocuments,
		} = myReviewsPageSliceState;

		const { addReviewPost: addReviewPostAction } = myReviewsPageSliceActions;

		const { customerDetails, currentlyLinkedLocationDetails } = userSliceState;

		// ***** Multiple custom dynamic constants for easy usage. ******

		// Used to show a Loader to indicate some main async functionalities are in process.(Like fetchings started on ComponentDidMount).
		const isMainPageActionsRunning =
			getReviewDocumentsReqStatus === reqStatusTypes.pending;

		const isMainPageActionsError = getReviewDocumentsReqError;

		// Indicating Main Page Data Loading.
		if (isMainPageActionsRunning || isMainPageActionsError) {
			return (
				<div className='MyReviewsPage GC-DP-DashboardPage'>
					<PageLoader
						isFetching={isMainPageActionsRunning}
						loadingMsg='Please wait while we are fetching the details.'
						isError={isMainPageActionsError}
						errorMsg={isMainPageActionsError}
						onErrorTryAgainFn={() => this.fetchNecessaryInitialPageData()}
						shouldHideWhenEmpty
					/>
				</div>
			);
		}

		return (
			<div className='MyReviewsPage GC-DP-DashboardPage'>
				<div>
					<div className='MyReviewsPage__header GC-DP-DashboardPageHeader'>
						<h1>MY REVIEWS</h1>
					</div>

					<div className='MyReviewsPage__body GC-DP-DashboardPageBody'>
						{/* IMPORTANT NOTE :
									For now "Adding Review" capability is not supposed to be in Web Interface. (Only in mobile app). So in here we are just using it for checking purposes.
									Also note that this <AddReviewModal/> is not fully finished and only have base functionalities and not doing any checks to avoid multiple/unauthorized reviews.  */}
						{/* <div>
							<AddReviewModal
								reviewRelatedDetails={{
									// Details about to who/which/where we are adding review to.
									ReviewType: 'Company', // Can be 'Company' OR 'Product'.

									CustomerName:
										customerDetails.FirstName + customerDetails.LastName,
									CompanyName: currentlyLinkedLocationDetails.CompanyName,
									LocationName: currentlyLinkedLocationDetails.LocationName,

									CustomerID: customerDetails.UserID,
									CompanyID: currentlyLinkedLocationDetails.CompanyID,
									LocationID: currentlyLinkedLocationDetails.LocationID,
									OrganizationID: currentlyLinkedLocationDetails.OrganizationID,
								}}
								addReviewPostAction={addReviewPostAction}
								customToggleButtonComp={(options) => {
									const { nativeToggleFn } = options;

									return (
										<div
											style={{ display: 'flex', justifyContent: 'flex-end' }}>
											<Button
												variant='contained'
												color='primary'
												type='button'
												onClick={() => {
													nativeToggleFn();
												}}>
												Add Review
											</Button>
										</div>
									);
								}}
							/>
						</div> */}

						<div className='MyReviewsPage__body__reviews'>
							{/* When User Don't Have any Reviews */}
							{myReviewDocuments.length === 0 && (
								<DashboardSubInfoSection
									className='MyReviewsPage__body__reviews__reviewItems--noItems'
									type='NOITEMS'
									description='Currently, No Reviews Available For You.'
								/>
							)}

							{/* When User Have Reviews */}
							{myReviewDocuments.length > 0 && (
								<div className='MyReviewsPage__body__reviews__reviewItems'>
									{myReviewDocuments
										.slice()
										.sort((a, b) => {
											return new Date(b.CreatedDate) - new Date(a.CreatedDate);
										})
										.map((reviewDoc, index) => {
											return (
												<CustomerReviewItemCard
													key={index}
													reviewValues={reviewDoc}
													showUserDetails={false}
												/>
											);
										})}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		myReviewsPageSliceState: state[customerDashboardSectionName].myReviewsPage,
		userSliceState: state.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundMyReviewsPageSliceActions = bindActionCreators(
		myReviewsPageSliceActions,
		dispatch,
	);

	const boundUserSliceActions = bindActionCreators(userSliceActions, dispatch);

	return {
		myReviewsPageSliceActions: boundMyReviewsPageSliceActions,
		userSliceActions: boundUserSliceActions,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyReviewsPage);
