/* eslint-disable no-param-reassign */
import { reqStatusTypes } from '../../../../configs/constants';

import { getCheckInDocuments } from './myCheckInsPageSliceExtraActions';

const extraReducers = {
	// ******************************* getCheckInDocuments() *******************************
	[getCheckInDocuments.pending]: (state, action) => {
		state.getCheckInDocumentsReqStatus = reqStatusTypes.pending;
		state.getCheckInDocumentsReqError = '';
	},

	[getCheckInDocuments.fulfilled]: (state, action) => {
		state.getCheckInDocumentsReqStatus = reqStatusTypes.succeeded;
		state.myCheckInDocuments = action.payload;
	},

	[getCheckInDocuments.rejected]: (state, action) => {
		state.getCheckInDocumentsReqStatus = reqStatusTypes.failed;
		state.getCheckInDocumentsReqError = action.error.message;
		state.myCheckInDocuments = [];
	},
};

export default extraReducers;
