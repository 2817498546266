import { reqStatusTypes } from '../../../../configs/constants';
import { sectionName } from '../businessDashboardConstants';

const sliceName = 'customerCheckInsPage';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,

	initialState: {
		// ******************************* getCheckInDocuments() *******************************
		getCheckInDocumentsReqStatus: reqStatusTypes.idle,
		getCheckInDocumentsReqError: '',
		currentLocationCheckInDocuments: [],
	},
};

export default sliceConstants;
