/* eslint-disable no-param-reassign */
import { reqStatusTypes } from '../../../../configs/constants';

const standardReducers = {
	resetAllCustomerCheckInsPageSliceRequests: (state, action) => {
		// ******************************* getCheckInDocuments() *******************************
		state.getCheckInDocumentsReqStatus = reqStatusTypes.idle;
		state.getCheckInDocumentsReqError = '';
	},
};

export default standardReducers;
