/* eslint-disable prefer-const */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-small-switch */
import apiEndpoints from '../../configs/apiEndpoints';
import { APIError, errorLogger } from '../errorHandlers';

import axiosWitmegLoyaltyServer from './axiosInstances/axiosWitmegLoyaltyServerInstance';

export async function addLoyaltyCustomerToBusiness(reqBody = {}, options = {}) {
	try {
		const apiReqBody = {
			...reqBody,
		};

		const apiReqUrl = apiEndpoints.addLoyaltyCustomerToBusiness;

		const resObj = await axiosWitmegLoyaltyServer({
			url: apiReqUrl,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		// Handling some errors come with Statue "True"
		if (Status) {
			if (typeof Result === 'string') {
				switch (true) {
					case Result.includes('Customer already exist'): {
						throw new APIError(Result);
					}

					default: {
						break;
					}
				}
			}
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Customer already exist'): {
					customErrMsg = 'You already exist on this organization.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

// NOTE : You can check 'Already Exist' using any of 'CustomerID/Email/MobileNumber' AND 'LocationID'.
export async function checkLoyaltyCustomerAlreadyExistInBusiness(
	reqBody = {},
	options = {},
) {
	try {
		const { CustomerID, Email, MobileNumber } = reqBody;
		let RequestID = '';
		let ReqURL = '';

		// Determine which API Endpoint and Search Paramter to use depending on which of "CustomerID / Email / MobileNumber" Provided.
		switch (true) {
			case Boolean(CustomerID): {
				RequestID = CustomerID;
				ReqURL =
					apiEndpoints.checkLoyaltyCustomerAlreadyExistInBusinessByCustomerId;
				break;
			}

			case Boolean(Email): {
				RequestID = Email;
				ReqURL =
					apiEndpoints.checkLoyaltyCustomerAlreadyExistInBusinessByCustomerEmail;
				break;
			}

			case Boolean(MobileNumber): {
				RequestID = MobileNumber;
				ReqURL =
					apiEndpoints.checkLoyaltyCustomerAlreadyExistInBusinessByCustomerMobileNumber;
				break;
			}

			default:
				ReqURL =
					apiEndpoints.checkLoyaltyCustomerAlreadyExistInBusinessByCustomerId;
				break;
		}

		const apiReqBody = {
			RequestID,
			LocationID: reqBody.LocationID,
		};

		const apiReqUrl = ReqURL;

		const resObj = await axiosWitmegLoyaltyServer({
			url: apiReqUrl,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		// Handling some errors come with Statue "True"
		if (Status) {
			if (typeof Result === 'string') {
				switch (true) {
					default: {
						break;
					}
				}
			}
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

// NOTE : You can get 'Already Exist Customer Details of Business' using any of 'CustomerID/Email/MobileNumber' AND 'LocationID'.
export async function getLoyaltyCustomerDetailsInBusiness(
	reqBody = {},
	options = {},
) {
	try {
		const { CustomerID, Email, MobileNumber } = reqBody;
		let RequestID = '';
		let ReqURL = '';

		// Determine which API Endpoint and Search Paramter to use depending on which of "CustomerID / Email / MobileNumber" Provided.
		switch (true) {
			case Boolean(CustomerID): {
				RequestID = CustomerID;
				ReqURL = apiEndpoints.getLoyaltyCustomerDetailsInBusinessByCustomerId;
				break;
			}

			case Boolean(Email): {
				RequestID = Email;
				ReqURL =
					apiEndpoints.getLoyaltyCustomerDetailsInBusinessByCustomerEmail;
				break;
			}

			case Boolean(MobileNumber): {
				RequestID = MobileNumber;
				ReqURL =
					apiEndpoints.getLoyaltyCustomerDetailsInBusinessByCustomerMobileNumber;
				break;
			}

			default:
				ReqURL = apiEndpoints.getLoyaltyCustomerDetailsInBusinessByCustomerId;
				break;
		}

		const apiReqBody = {
			RequestID,
			LocationID: reqBody.LocationID,
		};

		const apiReqUrl = ReqURL;

		const resObj = await axiosWitmegLoyaltyServer({
			url: apiReqUrl,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		// Handling some errors come with Statue "True"
		if (Status) {
			if (typeof Result === 'string') {
				switch (true) {
					default: {
						break;
					}
				}
			}
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Customer does not exist'): {
					customErrMsg = "You don't exist on this organization.";
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function addLoyaltySchemeSettings(reqBody = {}, options = {}) {
	try {
		const apiReqBody = {
			...reqBody,
		};

		const resObj = await axiosWitmegLoyaltyServer({
			url: `${apiEndpoints.addLoyaltySchemeSettings}`,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function updateLoyaltySchemeSettings(reqBody = {}, options = {}) {
	try {
		const apiReqBody = {
			...reqBody,
		};

		const resObj = await axiosWitmegLoyaltyServer({
			url: `${apiEndpoints.updateLoyaltySchemeSettings}`,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getGlobalLoyaltySchemeSettings(
	reqBody = {},
	options = {},
) {
	try {
		const resObj = await axiosWitmegLoyaltyServer({
			url: `${apiEndpoints.getGlobalLoyaltySchemeSettings}`,
			method: 'GET',
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getLoyaltySchemeSettingsByLocationId(
	reqBody = {},
	options = {},
) {
	try {
		const { LookupID } = reqBody;

		const apiReqBody = {
			LookupID,
		};

		const resObj = await axiosWitmegLoyaltyServer({
			url: `${apiEndpoints.getLoyaltySchemeSettingsByLocationId}`,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			if (Result.includes('Unable to insert loyaltySchemeSetting data')) {
				// Handling "No Settings Available" Without Error.
				return {};
			}

			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getLoyaltySchemeSettingsByLocationSettingId(
	reqBody = {},
	options = {},
) {
	try {
		const { LookupID } = reqBody;

		const apiReqBody = {
			LookupID,
		};

		const resObj = await axiosWitmegLoyaltyServer({
			url: `${apiEndpoints.getLoyaltySchemeSettingsByLocationSettingId}`,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			if (Result.includes('Unable to insert loyaltySchemeSetting data')) {
				// Handling "No Settings Available" Without Error.
				return {};
			}

			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getLoyaltyPointsForAllLocationsByCustomerId(
	reqBody = {},
	options = {},
) {
	try {
		const { CustomerID } = reqBody;

		const apiReqUrl = `${apiEndpoints.getLoyaltyCustomerPointsForAllLocationsByCustomerId}/${CustomerID}`;

		const resObj = await axiosWitmegLoyaltyServer({
			url: apiReqUrl,
			method: 'GET',
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

// NOTE : You can get 'CheckIn Documents' using any of 'DocumentID/LocationID/CustomerID'.
export async function getCheckInDocuments(reqBody = {}, options = {}) {
	try {
		const {
			DocumentID,
			LocationID,
			CustomerID,
			skip = 0,
			limit = 20,
		} = reqBody;

		let RequestID = '';
		let ReqURL = '';
		let PaginationsURLRouteParams = ''; // Ex. /{skip}/{limit}

		// Determine which API Endpoint and Search Paramter to use depending on which of "DocumentID/LocationID/CustomerID" Provided.
		switch (true) {
			case Boolean(DocumentID): {
				RequestID = DocumentID;
				ReqURL = apiEndpoints.getCheckInDocumentsByDocumentId;
				PaginationsURLRouteParams = '';
				break;
			}

			case Boolean(LocationID): {
				RequestID = LocationID;
				ReqURL = apiEndpoints.getCheckInDocumentsByLocationId;
				PaginationsURLRouteParams = `/${skip}/${limit}`;
				break;
			}

			case Boolean(CustomerID): {
				RequestID = CustomerID;
				ReqURL = apiEndpoints.getCheckInDocumentsByCustomerId;
				PaginationsURLRouteParams = `/${skip}/${limit}`;
				break;
			}

			default:
				ReqURL = apiEndpoints.getCheckInDocumentsByDocumentId;
				break;
		}

		const apiReqUrl = `${ReqURL}/${RequestID}${PaginationsURLRouteParams}`;

		const resObj = await axiosWitmegLoyaltyServer({
			url: apiReqUrl,
			method: 'GET',
			...options,
		});

		let { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		// Making "getCheckInDocumentsByDocumentId" result is similar to other endpoints result pattern.
		if (ReqURL === apiEndpoints.getCheckInDocumentsByDocumentId) {
			Result = [Result];
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				// From "apiEndpoints.getCheckInDocumentsByDocumentId"
				case errMsg.includes('Unable to get data in DB'): {
					customErrMsg = 'Provided DocumentID is Invalid';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

// NOTE : You can get 'Loyalty Awards Documents' using any of 'DocumentID/LocationID/CustomerID'.
export async function getLoyaltyAwardsDocuments(reqBody = {}, options = {}) {
	try {
		const {
			DocumentID,
			LocationID,
			CustomerID,
			skip = 0,
			limit = 20,
		} = reqBody;

		let RequestID = '';
		let ReqURL = '';
		let PaginationsURLRouteParams = ''; // Ex. /{skip}/{limit}

		// Determine which API Endpoint and Search Paramter to use depending on which of "DocumentID/LocationID/CustomerID" Provided.
		switch (true) {
			case Boolean(DocumentID): {
				RequestID = DocumentID;
				ReqURL = apiEndpoints.getLoyaltyAwardsDocumentsByDocumentId;
				PaginationsURLRouteParams = '';
				break;
			}

			case Boolean(LocationID): {
				RequestID = LocationID;
				ReqURL = apiEndpoints.getLoyaltyAwardsDocumentsByLocationId;
				PaginationsURLRouteParams = `/${skip}/${limit}`;
				break;
			}

			case Boolean(CustomerID): {
				RequestID = CustomerID;
				ReqURL = apiEndpoints.getLoyaltyAwardsDocumentsByCustomerId;
				PaginationsURLRouteParams = `/${skip}/${limit}`;
				break;
			}

			default:
				ReqURL = apiEndpoints.getLoyaltyAwardsDocumentsByDocumentId;
				break;
		}

		const apiReqUrl = `${ReqURL}/${RequestID}${PaginationsURLRouteParams}`;

		const resObj = await axiosWitmegLoyaltyServer({
			url: apiReqUrl,
			method: 'GET',
			...options,
		});

		let { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		// Making "getLoyaltyAwardsDocumentsByDocumentId" result is similar to other endpoints result pattern.
		if (ReqURL === apiEndpoints.getLoyaltyAwardsDocumentsByDocumentId) {
			Result = [Result];
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				// From "apiEndpoints.getLoyaltyAwardsDocumentsByDocumentId"
				case errMsg.includes('Unable to get data in DB'): {
					customErrMsg = 'Provided DocumentID is Invalid';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

// NOTE : You can get 'Loyalty Review Documents' using any of 'DocumentID/LocationID/CustomerID'.
export async function getReviewDocuments(reqBody = {}, options = {}) {
	try {
		const {
			DocumentID,
			LocationID,
			CustomerID,
			skip = 0,
			limit = 20,
		} = reqBody;

		let RequestID = '';
		let ReqURL = '';
		let PaginationsURLRouteParams = ''; // Ex. /{skip}/{limit}

		// Determine which API Endpoint and Search Paramter to use depending on which of "DocumentID/LocationID/CustomerID" Provided.
		switch (true) {
			case Boolean(DocumentID): {
				RequestID = DocumentID;
				ReqURL = apiEndpoints.getReviewDocumentsByDocumentId;
				PaginationsURLRouteParams = '';
				break;
			}

			case Boolean(LocationID): {
				RequestID = LocationID;
				ReqURL = apiEndpoints.getReviewDocumentsByLocationId;
				PaginationsURLRouteParams = `/${skip}/${limit}`;
				break;
			}

			case Boolean(CustomerID): {
				RequestID = CustomerID;
				ReqURL = apiEndpoints.getReviewDocumentsByCustomerId;
				PaginationsURLRouteParams = `/${skip}/${limit}`;
				break;
			}

			default:
				ReqURL = apiEndpoints.getReviewDocumentsByDocumentId;
				break;
		}

		const apiReqUrl = `${ReqURL}/${RequestID}${PaginationsURLRouteParams}`;

		const resObj = await axiosWitmegLoyaltyServer({
			url: apiReqUrl,
			method: 'GET',
			...options,
		});

		let { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		// Making "getReviewDocumentsByDocumentId" result is similar to other endpoints result pattern.
		if (ReqURL === apiEndpoints.getReviewDocumentsByDocumentId) {
			Result = [Result];
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				// From "apiEndpoints.getReviewDocumentsByDocumentId"
				case errMsg.includes('Unable to get data in DB'): {
					customErrMsg = 'Provided DocumentID is Invalid';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function authorizeReviewToBePost(reqBody = {}, options = {}) {
	try {
		const { LookupID } = reqBody;

		const apiReqBody = {
			LookupID,
		};

		const resObj = await axiosWitmegLoyaltyServer({
			url: `${apiEndpoints.authorizeReviewToBePost}`,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Unable to update LoyaltyReview data in DB'): {
					customErrMsg = 'Review Authorizing Failed. Invalid ReviewID.';
					break;
				}

				default: {
					customErrMsg =
						'Error Occurred While Authorizing The Review. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function addReviewPost(reqBody = {}, options = {}) {
	try {
		const {
			ReviewType,
			Review,
			Star,

			CustomerName,
			CompanyName,
			LocationName,

			CustomerID,
			CompanyID,
			LocationID,
			OrganizationID,
		} = reqBody;

		const apiReqBody = {
			ReviewType,
			Review,
			Star,
			CustomerName,
			CompanyName,
			LocationName,
			CustomerID,
			CompanyID,
			LocationID,
			OrganizationID,
		};

		const resObj = await axiosWitmegLoyaltyServer({
			url: `${apiEndpoints.addReviewPost}`,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}
