/* eslint-disable no-param-reassign */
import { reqStatusTypes } from '../../../../configs/constants';

const standardReducers = {
	resetGenerateQrValueFnInvokes: (state, action) => {
		state.generateQrValueReqStatus = reqStatusTypes.idle;
		state.generateQrValueReqError = '';
		state.generatedQrValue = '';
		state.generatedQrValueDescription = '';
	},
};

export default standardReducers;
