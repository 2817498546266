/* eslint-disable no-param-reassign */
import { reqStatusTypes } from '../../../../configs/constants';

import { dummyFn } from './customerDashboardSliceExtraActions';

const extraReducers = {
	// ******************************* dummyFn() *******************************
	[dummyFn.pending]: (state, action) => {
		state.dummyReqStatus = reqStatusTypes.pending;
		state.dummyReqError = '';
	},

	[dummyFn.fulfilled]: (state, action) => {
		state.dummyReqStatus = reqStatusTypes.succeeded;
		state.dummyValue = action.payload;
	},

	[dummyFn.rejected]: (state, action) => {
		state.dummyReqStatus = reqStatusTypes.failed;
		state.getFullOrgDetailsOfBusinessUserReqError = action.error.message;
		state.dummyReqError = [];
	},
};

export default extraReducers;
