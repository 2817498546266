/* eslint-disable no-shadow */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-pascal-case */
import { withWidth } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Menu as MenuIcon } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { bindActionCreators, unwrapResult } from '@reduxjs/toolkit';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PageLoader from '../../components/widgets/loaders/PageLoader/PageLoader';
import { dashboardTypes } from '../../configs/constants';
import { sectionName as customerDashboardSectionName } from '../../redux/slices/customerDashboard/customerDashboardConstants';
import { actions as customerDashboardSliceActions } from '../../redux/slices/customerDashboard/customerDashboardSlice/customerDashboardSlice';
import { actions as userSliceActions } from '../../redux/slices/userSlice/userSlice';
import { getLoggedUserDetails } from '../../utilities/userAuthentication';

import {
	BusinessDashboard__MenuLinks,
	BusinessDashboard__MenuRoutes,
} from './businessDashboard/businessDashboard';
import {
	CustomerDashboard__MenuLinks,
	CustomerDashboard__MenuRoutes,
} from './customerDashboard/customerDashboard';

import './DashboardPage.css';

// These styles wrapped with "withStyles()" HOC and that pass down 'classes' props which we can used with components.
const drawerWidth = 240;
const muiStyles = (theme) => {
	return {
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			background: '#14213d',
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		},
		drawerPaper: {
			// Making on smaller resolutions drawer below the AppHeader.
			[theme.breakpoints.up('sm')]: {
				marginTop: '64px',
			},
			[theme.breakpoints.down('xs')]: {
				marginTop: '56px',
			},
			width: drawerWidth,
		},
		drawerContainer: {
			overflow: 'auto',
		},
	};
};
class DashboardPage extends Component {
	constructor(props) {
		super(props);

		const { width: currentPageBreakpointSize } = this.props;
		const isSmallBreakpoint =
			currentPageBreakpointSize === 'sm' || currentPageBreakpointSize === 'xs';

		this.state = {
			isPreFetching: false,
			isPreFetchingError: '',
			isMainMenuOpen: !isSmallBreakpoint,
		};

		this.handleFetchCustomerDetails = this.handleFetchCustomerDetails.bind(
			this,
		);
	}

	async handleFetchCustomerDetails() {
		const { userSliceActions } = this.props;
		const { getCustomerDetails: getCustomerDetailsAction } = userSliceActions;

		const loggedUserDetails = getLoggedUserDetails();
		const { Email } = loggedUserDetails;

		return await getCustomerDetailsAction({ Email }).then(unwrapResult);
	}

	async runPreFetchActions() {
		this.setState({
			isPreFetching: true,
			isPreFetchingError: '',
		});

		const loggedUserDetails = getLoggedUserDetails();
		const { isBusinessUser } = loggedUserDetails;

		const preFetchActionFns = [];

		// Adding Customer Dashboard Related Actions.
		if (!isBusinessUser) {
			preFetchActionFns.push(this.handleFetchCustomerDetails);
		}

		// Executing all added actions and getting Promises.
		const preFetchActionFnsPromises = preFetchActionFns.map((action) => {
			return action();
		});

		// Checking all actions are successfully ran.
		await Promise.all(preFetchActionFnsPromises)
			.then(() => {
				return this.setState({
					isPreFetching: false,
				});
			})
			.catch(() => {
				return this.setState({
					isPreFetching: false,
					isPreFetchingError: 'Error Occurred While Loading The Dashboard.',
				});
			});
	}

	componentDidMount() {
		this.runPreFetchActions();
	}

	render() {
		const { dashboardType } = this.props;
		const {
			history,
			location,
			classes: muiClasses,
			width: currentPageBreakpointSize,
		} = this.props;

		const { isMainMenuOpen, isPreFetching, isPreFetchingError } = this.state;

		// Used to determine Main Menu should display as absolute kind way or along with other content.
		const isSmallBreakpoint =
			currentPageBreakpointSize === 'sm' || currentPageBreakpointSize === 'xs';
		const mainMenuVariation = isSmallBreakpoint ? 'temporary' : 'persistent';

		const currentRoutePath = location.pathname;

		if (isPreFetching || isPreFetchingError) {
			return (
				<div className='DashboardPage'>
					<div className='DashboardPageLoader'>
						<div>
							<img
								src='https://cdn.neurolage.com/ecologital/witmeg_logo.png'
								alt=''
							/>
							<div className='DashboardPageLoader__loader'>
								<PageLoader
									isFetching={isPreFetching}
									loadingMsg=''
									isError={isPreFetchingError}
									errorMsg={isPreFetchingError}
									onErrorTryAgainFn={() => this.runPreFetchActions()}
									shouldHideWhenEmpty
								/>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return (
			<div className='DashboardPage'>
				<div>
					<div className='DashboardPage__header'>
						<AppBar className={muiClasses.appBar}>
							<Toolbar>
								<IconButton
									edge='start'
									color='inherit'
									aria-label='menu'
									onClick={() => {
										this.setState({ isMainMenuOpen: !isMainMenuOpen });
									}}>
									<MenuIcon />
								</IconButton>

								<Typography variant='h6'>
									{dashboardType === dashboardTypes.business
										? 'Business Dashboard'
										: 'Customer Dashboard'}
								</Typography>
							</Toolbar>
						</AppBar>
					</div>

					<div className='DashboardPage__body'>
						<Drawer
							variant={mainMenuVariation}
							open={isMainMenuOpen}
							onClose={() => {
								this.setState({ isMainMenuOpen: false });
							}}
							anchor='left'
							style={{
								display: isMainMenuOpen ? 'initial' : 'none',
							}}
							className={muiClasses.drawer}
							classes={{
								paper: muiClasses.drawerPaper,
							}}>
							<div
								className={`DashboardPage__mainMenu ${muiClasses.drawerContainer}`}>
								<div className='DashboardPage__mainMenu__header'>
									<img
										src='https://cdn.neurolage.com/ecologital/witmeg_logo.png'
										alt='Witmeg Logo'
									/>
									<h1>Loyalty</h1>
								</div>

								<div className='DashboardPage__mainMenu__links'>
									{dashboardType === dashboardTypes.business ? (
										<BusinessDashboard__MenuLinks
											currentRoutePath={currentRoutePath}
											history={history}
										/>
									) : (
										<CustomerDashboard__MenuLinks
											currentRoutePath={currentRoutePath}
											history={history}
										/>
									)}
								</div>
							</div>
						</Drawer>

						<div className='DashboardPage__selectedPageContent'>
							{dashboardType === dashboardTypes.business ? (
								<BusinessDashboard__MenuRoutes />
							) : (
								<CustomerDashboard__MenuRoutes />
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userSliceState: state.user,
		customerDashboardSliceState:
			state[customerDashboardSectionName].customerDashboard,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundUserSliceActions = bindActionCreators(userSliceActions, dispatch);
	const boundCustomerDashboardSliceActions = bindActionCreators(
		customerDashboardSliceActions,
		dispatch,
	);

	return {
		userSliceActions: boundUserSliceActions,
		customerDashboardSliceActions: boundCustomerDashboardSliceActions,
	};
};

const DashboardPageWithMultipleHOC = withRouter(
	withWidth()(withStyles(muiStyles)(DashboardPage)),
);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(DashboardPageWithMultipleHOC);
