import {
	configureStore,
	getDefaultMiddleware,
	combineReducers,
} from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';

import globalValues from '../configs/globalValues';

import { sectionName as businessDashboardSectionName } from './slices/businessDashboard/businessDashboardConstants';
import businessDashboardSliceReducer, {
	name as businessDashboardSliceReducerName,
} from './slices/businessDashboard/businessDashboardSlice/businessDashboardSlice';
import customerCheckInsPageSliceReducer, {
	name as customerCheckInsPageSliceReducerName,
} from './slices/businessDashboard/customerCheckInsPageSlice/customerCheckInsPageSlice';
import customerLoyaltyAwardsPageSliceReducer, {
	name as customerLoyaltyAwardsPageSliceReducerName,
} from './slices/businessDashboard/customerLoyaltyAwardsPageSlice/customerLoyaltyAwardsPageSlice';
import customerReviewsPageSliceReducer, {
	name as customerReviewsPageSliceReducerName,
} from './slices/businessDashboard/customerReviewsPageSlice/customerReviewsPageSlice';
import loyaltySchemeSettingsPageSliceReducer, {
	name as loyaltySchemeSettingsPageSliceReducerName,
} from './slices/businessDashboard/loyaltySchemeSettingsPageSlice/loyaltySchemeSettingsPageSlice';
import qrCodeGeneratorPageSliceReducer, {
	name as qrCodeGeneratorPageSliceReducerName,
} from './slices/businessDashboard/qrCodeGeneratorPageSlice/qrCodeGeneratorPageSlice';
import { sectionName as customerDashboardSectionName } from './slices/customerDashboard/customerDashboardConstants';
import customerDashboardSliceReducer, {
	name as customerDashboardSliceReducerName,
} from './slices/customerDashboard/customerDashboardSlice/customerDashboardSlice';
import dashboardHomePageSliceReducer, {
	name as dashboardHomePageSliceReducerName,
} from './slices/customerDashboard/dashboardHomePageSlice/dashboardHomePageSlice';
import myCheckInsPageSliceReducer, {
	name as myCheckInsPageSliceReducerName,
} from './slices/customerDashboard/myCheckInsPageSlice/myCheckInsPageSlice';
import myLoyaltyAwardsPageSliceReducer, {
	name as myLoyaltyAwardsPageSliceReducerName,
} from './slices/customerDashboard/myLoyaltyAwardsPageSlice/myLoyaltyAwardsPageSlice';
import myLoyaltyPointsPageSliceReducer, {
	name as myLoyaltyPointsPageSliceReducerName,
} from './slices/customerDashboard/myLoyaltyPointsPageSlice/myLoyaltyPointsPageSlice';
import myReviewsPageSliceReducer, {
	name as myReviewsPageSliceReducerName,
} from './slices/customerDashboard/myReviewsPageSlice/myReviewsPageSlice';
import userSliceReducer, {
	name as userSliceReducerName,
} from './slices/userSlice/userSlice';

/**
 * NOTE : We are using "redux-toolkit" helpers to setup and use Redux in our React App.
 */

const isDevelopment = globalValues.environment.IS_DEVELOPMENT;

const reduxLogger = createLogger({
	collapsed: true,
});

const middleware = [
	...getDefaultMiddleware(), //  PreConfigured "thunk, immutableStateInvariant, serializableStateInvariant"
];

if (isDevelopment) {
	middleware.push(reduxLogger); // This must be the last middleware in chain.
}

//  NOTE : This store is pre configured with ReduxDevTools.
export const reduxStore = configureStore({
	reducer: combineReducers({
		[userSliceReducerName]: userSliceReducer,
		[businessDashboardSectionName]: combineReducers({
			[businessDashboardSliceReducerName]: businessDashboardSliceReducer,
			[customerCheckInsPageSliceReducerName]: customerCheckInsPageSliceReducer,
			[customerLoyaltyAwardsPageSliceReducerName]: customerLoyaltyAwardsPageSliceReducer,
			[customerReviewsPageSliceReducerName]: customerReviewsPageSliceReducer,
			[qrCodeGeneratorPageSliceReducerName]: qrCodeGeneratorPageSliceReducer,
			[loyaltySchemeSettingsPageSliceReducerName]: loyaltySchemeSettingsPageSliceReducer,
		}),
		[customerDashboardSectionName]: combineReducers({
			[customerDashboardSliceReducerName]: customerDashboardSliceReducer,
			[dashboardHomePageSliceReducerName]: dashboardHomePageSliceReducer,
			[myCheckInsPageSliceReducerName]: myCheckInsPageSliceReducer,
			[myLoyaltyAwardsPageSliceReducerName]: myLoyaltyAwardsPageSliceReducer,
			[myReviewsPageSliceReducerName]: myReviewsPageSliceReducer,
			[myLoyaltyPointsPageSliceReducerName]: myLoyaltyPointsPageSliceReducer,
		}),
	}),
	middleware: [...middleware],
	devTools: !!isDevelopment,
});
