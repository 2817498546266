/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable react/sort-comp */
import { Menu, MenuItem } from '@material-ui/core';
import {
	AccountCircle as AccountCircleIcon,
	Eco as EcoIcon,
} from '@material-ui/icons';
import { bindActionCreators } from '@reduxjs/toolkit';
import React from 'react';
import { connect } from 'react-redux';

import CustomerCheckInItemCard from '../../../../../components/pageSections/dashboardPage/common/CustomerCheckInItemCard/CustomerCheckInItemCard';
import CustomerLoyaltyAwardsItemCard from '../../../../../components/pageSections/dashboardPage/common/CustomerLoyaltyAwardsItemCard/CustomerLoyaltyAwardsItemCard';
import CustomerReviewItemCard from '../../../../../components/pageSections/dashboardPage/common/CustomerReviewItemCard/CustomerReviewItemCard';
import DashboardItemCard from '../../../../../components/pageSections/dashboardPage/common/DashboardItemCard/DashboardItemCard';
import PageLoader from '../../../../../components/widgets/loaders/PageLoader/PageLoader';
import { reqStatusTypes } from '../../../../../configs/constants';
import { sectionName as customerDashboardSectionName } from '../../../../../redux/slices/customerDashboard/customerDashboardConstants';
import { actions as customerDashboardSliceActions } from '../../../../../redux/slices/customerDashboard/customerDashboardSlice/customerDashboardSlice';
import { actions as dashboardHomePageSliceActions } from '../../../../../redux/slices/customerDashboard/dashboardHomePageSlice/dashboardHomePageSlice';
import { actions as myCheckInsPageSliceActions } from '../../../../../redux/slices/customerDashboard/myCheckInsPageSlice/myCheckInsPageSlice';
import { actions as myLoyaltyAwardsPageSliceActions } from '../../../../../redux/slices/customerDashboard/myLoyaltyAwardsPageSlice/myLoyaltyAwardsPageSlice';
import { actions as myLoyaltyPointsPageSliceActions } from '../../../../../redux/slices/customerDashboard/myLoyaltyPointsPageSlice/myLoyaltyPointsPageSlice';
import { actions as myReviewsPageSliceActions } from '../../../../../redux/slices/customerDashboard/myReviewsPageSlice/myReviewsPageSlice';
import {
	LogoutUserButtonWrapper,
	getLoggedUserDetails,
} from '../../../../../utilities/userAuthentication';

import './DashboardHome.css';

// Temporary Component To Shown in Empty DashboardHom ItemsCards.
const TempContent = () => {
	return (
		<div
			style={{
				alignItems: 'center',
				display: 'flex',
				height: '100%',
				justifyContent: 'center',
				textAlign: 'center',
			}}>
			<div>
				<EcoIcon color='disabled' style={{ fontSize: 50 }} />
				<div style={{ color: 'gray', fontSize: '0.8em' }}>COMING SOON</div>
			</div>
		</div>
	);
};

class DashboardHome extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			userMenuAnchorEl: null, // Used to toggle UserMenu Open/Close.
		};

		this.toggleUserMenu = this.toggleUserMenu.bind(this);
	}

	toggleUserMenu(e) {
		const { userMenuAnchorEl } = this.state;

		this.setState({
			userMenuAnchorEl: userMenuAnchorEl ? null : e.currentTarget,
		});
	}

	async handleGetCheckInDocuments() {
		const { myCheckInsPageSliceActions } = this.props;

		const {
			getCheckInDocuments: getCheckInDocumentsAction,
		} = myCheckInsPageSliceActions;

		const { userid } = getLoggedUserDetails();

		return await getCheckInDocumentsAction({
			reqData: { CustomerID: userid, limit: 50 },
		});
	}

	async handleGetLoyaltyAwardsDocuments() {
		const { myLoyaltyAwardsPageSliceActions } = this.props;

		const {
			getLoyaltyAwardsDocuments: getLoyaltyAwardsDocumentsAction,
		} = myLoyaltyAwardsPageSliceActions;

		const { userid } = getLoggedUserDetails();

		return await getLoyaltyAwardsDocumentsAction({
			reqData: { CustomerID: userid, limit: 50 },
		});
	}

	async handleGetReviewDocuments() {
		const { myReviewsPageSliceActions } = this.props;

		const {
			getReviewDocuments: getReviewDocumentsAction,
		} = myReviewsPageSliceActions;

		const { userid } = getLoggedUserDetails();

		return await getReviewDocumentsAction({
			reqData: { CustomerID: userid, limit: 50 },
		});
	}

	async handleGetLoyaltyPointsForAllLocationsByCustomerId() {
		const { myLoyaltyPointsPageSliceActions } = this.props;
		const {
			getLoyaltyPointsForAllLocationsByCustomerId: getLoyaltyPointsForAllLocationsByCustomerIdAction,
		} = myLoyaltyPointsPageSliceActions;

		const { userid } = getLoggedUserDetails();

		return await getLoyaltyPointsForAllLocationsByCustomerIdAction({
			reqData: { CustomerID: userid },
		});
	}

	async fetchNecessaryInitialPageData() {
		await Promise.all([
			this.handleGetCheckInDocuments(),
			this.handleGetLoyaltyAwardsDocuments(),
			this.handleGetReviewDocuments(),
			this.handleGetLoyaltyPointsForAllLocationsByCustomerId(),
		]);
	}

	componentDidMount() {
		this.fetchNecessaryInitialPageData();
	}

	render() {
		const { userMenuAnchorEl } = this.state;

		const {
			userSliceState,
			myCheckInsPageSliceState,
			myLoyaltyAwardsPageSliceState,
			myReviewsPageSliceState,
			myLoyaltyPointsPageSliceState,
		} = this.props;

		const {
			customerDetails,
			isLocationLinked,
			currentlyLinkedLocationDetails,
		} = userSliceState;

		const {
			myCheckInDocuments,
			getCheckInDocumentsReqStatus,
			getCheckInDocumentsReqError,
		} = myCheckInsPageSliceState;

		const {
			myLoyaltyAwardsDocuments,
			getLoyaltyAwardsDocumentsReqStatus,
			getLoyaltyAwardsDocumentsReqError,
		} = myLoyaltyAwardsPageSliceState;

		const {
			myReviewDocuments,
			getReviewDocumentsReqStatus,
			getReviewDocumentsReqError,
		} = myReviewsPageSliceState;

		const {
			myLoyaltyPointsForAllLocations,
			getLoyaltyPointsForAllLocationsByCustomerIdReqStatus,
			getLoyaltyPointsForAllLocationsByCustomerIdReqError,
		} = myLoyaltyPointsPageSliceState;

		const { FirstName } = customerDetails;
		const { CompanyName, LocationName } = currentlyLinkedLocationDetails;

		// ***** Multiple custom dynamic constants for easy usage. ******

		// Used to show a Loader to indicate some main async functionalities are in process.(Like fetchings started on ComponentDidMount).
		const isMainPageActionsRunning =
			getCheckInDocumentsReqStatus === reqStatusTypes.pending ||
			getLoyaltyAwardsDocumentsReqStatus === reqStatusTypes.pending ||
			getReviewDocumentsReqStatus === reqStatusTypes.pending ||
			getLoyaltyPointsForAllLocationsByCustomerIdReqStatus ===
				reqStatusTypes.pending;

		const isMainPageActionsError = getLoyaltyPointsForAllLocationsByCustomerIdReqError;
		// NOTE : Temporally disabling PageWise ErrorPage for following errors. Because for now we are showing individual sections errors for these and they are not critical.
		// getCheckInDocumentsReqError ||
		// getLoyaltyAwardsDocumentsReqError ||
		// getReviewDocumentsReqError;

		// Getting Points data for currently linked location. (Assuming user went through LocationLinkingProcess (QR Scanning))
		const currentlyLinkedLocationPointsData = myLoyaltyPointsForAllLocations.find(
			(pointDetails) => {
				return (
					pointDetails.LocationID === currentlyLinkedLocationDetails.LocationID
				);
			},
		);
		const currentlyLinkedLocationTotalPoints = currentlyLinkedLocationPointsData
			? currentlyLinkedLocationPointsData.TotalPoints
			: false;

		const maxRecentItems = 3; // Determine how many items shown in each recent items section.

		// Indicating Main Page Data Loading.
		if (isMainPageActionsRunning || isMainPageActionsError) {
			return (
				<div className='DashboardHome GC-DP-DashboardPage'>
					<PageLoader
						isFetching={isMainPageActionsRunning}
						loadingMsg='Please wait while we are fetching the details.'
						isError={isMainPageActionsError}
						errorMsg={isMainPageActionsError}
						onErrorTryAgainFn={() => this.fetchNecessaryInitialPageData()}
						shouldHideWhenEmpty
					/>
				</div>
			);
		}

		return (
			<div className='DashboardHome'>
				<div className='DashboardHome__header'>
					<div className='DashboardHome__header__left'>
						{/* This is only shown when user goes through LocationLinkingProcess (QR Scanning) */}
						{isLocationLinked && (
							<div className='DashboardHome__header__currentLinkedLocation'>
								<span className='DashboardHome__header__currentLinkedLocation__title'>
									Current
									<br />
									Location
								</span>

								<span className='DashboardHome__header__currentLinkedLocation__value'>
									{CompanyName} - {LocationName}
								</span>
							</div>
						)}
					</div>

					<div className='DashboardHome__header__right'>
						{/* This is only shown when user goes through LocationLinkingProcess (QR Scanning) */}
						{isLocationLinked && (
							<div className='DashboardHome__header__totalPoints'>
								<span className='DashboardHome__header__totalPoints__title'>
									Current Loc. Total Points
								</span>
								<span className='DashboardHome__header__totalPoints__value'>
									{currentlyLinkedLocationTotalPoints}
								</span>
							</div>
						)}

						<div className='DashboardHome__header__userMenu'>
							<AccountCircleIcon onClick={this.toggleUserMenu} />
							<Menu
								id='simple-menu'
								anchorEl={userMenuAnchorEl}
								keepMounted
								open={Boolean(userMenuAnchorEl)}
								onClose={this.toggleUserMenu}
								getContentAnchorEl={null}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'right',
								}}>
								<MenuItem
									onClick={() => {
										this.toggleUserMenu();
									}}>
									<LogoutUserButtonWrapper>Log Out</LogoutUserButtonWrapper>
								</MenuItem>
							</Menu>
						</div>
					</div>
				</div>

				<div className='DashboardHome__body'>
					<div className='DashboardHome__body__greetings'>
						<h1>Hi {FirstName}, </h1>
					</div>

					<div className='DashboardHome__body__cards'>
						<DashboardItemCard
							header='Recent Check Ins'
							isEmpty={myCheckInDocuments.length === 0}
							isError={getCheckInDocumentsReqError}
							footerProps={{
								showDeafultFooter: true,
								footerLink: '/dashboard/myCheckIns',
							}}>
							{myCheckInDocuments
								.slice()
								.sort((a, b) => {
									return new Date(b.CreatedDate) - new Date(a.CreatedDate);
								})
								.slice(0, maxRecentItems)
								.map((checkInDoc, index) => {
									return (
										<CustomerCheckInItemCard
											key={index}
											checkInValues={checkInDoc}
											showUserDetails={false}
										/>
									);
								})}
						</DashboardItemCard>

						<DashboardItemCard
							header='Recent Loyalty Awards'
							isEmpty={myLoyaltyAwardsDocuments.length === 0}
							isError={getLoyaltyAwardsDocumentsReqError}
							footerProps={{
								showDeafultFooter: true,
								footerLink: '/dashboard/myLoyaltyAwards',
							}}>
							{myLoyaltyAwardsDocuments
								.slice(0)
								.sort((a, b) => {
									return new Date(b.CreatedDate) - new Date(a.CreatedDate);
								})
								.slice(0, maxRecentItems)
								.map((checkInDoc, index) => {
									return (
										<CustomerLoyaltyAwardsItemCard
											key={index}
											loyaltyAwardValues={checkInDoc}
											showUserDetails={false}
										/>
									);
								})}
						</DashboardItemCard>

						<DashboardItemCard
							header='Recent Reviews'
							isEmpty={myReviewDocuments.length === 0}
							isError={getReviewDocumentsReqError}
							footerProps={{
								showDeafultFooter: true,
								footerLink: '/dashboard/myReviews',
							}}>
							{myReviewDocuments
								.slice()
								.sort((a, b) => {
									return new Date(b.CreatedDate) - new Date(a.CreatedDate);
								})
								.slice(0, maxRecentItems)
								.map((reviewDoc, index) => {
									return (
										<CustomerReviewItemCard
											key={index}
											reviewValues={reviewDoc}
											showUserDetails={false}
										/>
									);
								})}
						</DashboardItemCard>

						<DashboardItemCard header='Suggested Places'>
							<TempContent />
						</DashboardItemCard>
						<DashboardItemCard header='This Weeks Offers'>
							<TempContent />
						</DashboardItemCard>
						<DashboardItemCard header='Last Minute Deals'>
							<TempContent />
						</DashboardItemCard>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userSliceState: state.user,

		customerDashboardSliceState:
			state[customerDashboardSectionName].customerDashboard,

		dashboardHomePageSliceState:
			state[customerDashboardSectionName].dashboardHomePage,

		myCheckInsPageSliceState:
			state[customerDashboardSectionName].myCheckInsPage,

		myLoyaltyAwardsPageSliceState:
			state[customerDashboardSectionName].myLoyaltyAwardsPage,

		myReviewsPageSliceState: state[customerDashboardSectionName].myReviewsPage,

		myLoyaltyPointsPageSliceState:
			state[customerDashboardSectionName].myLoyaltyPointsPage,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundCustomerDashboardSliceActions = bindActionCreators(
		customerDashboardSliceActions,
		dispatch,
	);

	const boundDashboardHomePageSliceActions = bindActionCreators(
		dashboardHomePageSliceActions,
		dispatch,
	);

	const boundMyCheckInsPageSliceActions = bindActionCreators(
		myCheckInsPageSliceActions,
		dispatch,
	);

	const boundMyLoyaltyAwardsPageSliceActions = bindActionCreators(
		myLoyaltyAwardsPageSliceActions,
		dispatch,
	);

	const boundMyReviewsPageSliceActions = bindActionCreators(
		myReviewsPageSliceActions,
		dispatch,
	);

	const boundMyLoyaltyPointsPageSliceActions = bindActionCreators(
		myLoyaltyPointsPageSliceActions,
		dispatch,
	);

	return {
		customerDashboardSliceActions: boundCustomerDashboardSliceActions,
		dashboardHomePageSliceActions: boundDashboardHomePageSliceActions,
		myCheckInsPageSliceActions: boundMyCheckInsPageSliceActions,
		myLoyaltyAwardsPageSliceActions: boundMyLoyaltyAwardsPageSliceActions,
		myReviewsPageSliceActions: boundMyReviewsPageSliceActions,
		myLoyaltyPointsPageSliceActions: boundMyLoyaltyPointsPageSliceActions,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHome);
