import { Stepper, Step, StepLabel } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import SignupEmailConfForm from './forms/SignupEmailConfForm/SignupEmailConfForm';
import SignupEmailEntryForm from './forms/SignupEmailEntryForm/SignupEmailEntryForm';
import SignupForm from './forms/SignupForm/SignupForm';

import './SignupPage.css';

// Specify Forms Steps in Order.
const formStepDetails = [
	{
		formComponent: SignupEmailEntryForm,
		stepTitle: 'Signup Email',
	},
	{
		formComponent: SignupEmailConfForm,
		stepTitle: 'Email Verification',
	},
	{
		formComponent: SignupForm,
		stepTitle: 'Signup Details',
	},
];

class SignupPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentFormStep: 0, // Determine which form will be shown.
			customFormData: {}, // Custom data that will be available in all Signup related forms.
		};

		this.setCurrentFormStep = this.setCurrentFormStep.bind(this);
		this.setCustomFormData = this.setCustomFormData.bind(this);
	}

	setCurrentFormStep(step) {
		this.setState({
			currentFormStep: step,
		});
	}

	setCustomFormData(data) {
		this.setState({
			customFormData: data,
		});
	}

	getCurrentStepsForm(currentFormStep, stepDetails) {
		return stepDetails[currentFormStep].formComponent;
	}

	render() {
		const { width: currentPageBreakpointSize, history, location } = this.props;
		const { state: reactRouterState = {} } = location;

		const { currentFormStep, customFormData } = this.state;
		const CurrentForm = this.getCurrentStepsForm(
			currentFormStep,
			formStepDetails,
		);

		// Passing common props for used in MaterialUI Inputs
		const formInputSize =
			currentPageBreakpointSize === 'xs' ? 'small' : 'medium';
		const commonMaterialUI__InputProps = {
			fullWidth: true,
			variant: 'outlined',
			size: formInputSize,
		};

		return (
			<div className='SignupPage'>
				<div className='SignupPage__container'>
					<div className='SignupPage__header'>
						<img
							src='https://cdn.neurolage.com/ecologital/witmeg_logo.png'
							alt=''
						/>
						<h1>Customer Signup</h1>

						<Stepper
							className='SignupPage__header__stepper'
							activeStep={currentFormStep}
							alternativeLabel>
							{formStepDetails.map((formStep) => (
								<Step key={formStep.stepTitle}>
									<StepLabel>{formStep.stepTitle}</StepLabel>
								</Step>
							))}
						</Stepper>
					</div>

					<div className='SignupPage__body'>
						<CurrentForm
							currentFormStep={currentFormStep}
							customFormData={customFormData}
							setCurrentFormStep={this.setCurrentFormStep}
							setCustomFormData={this.setCustomFormData}
							commonMaterialUI__InputProps={commonMaterialUI__InputProps}
							history={history}
							reactRouterState={reactRouterState}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withWidth()(SignupPage));
