import withWidth from '@material-ui/core/withWidth';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import PasswordResetRequestForm from './forms/PasswordResetRequestForm/PasswordResetRequestForm';

import './ForgotPasswordPage.css';

class ForgotPasswordPage extends Component {
	render() {
		const { width: currentPageBreakpointSize, history } = this.props;

		// Passing common props for used in MaterialUI Inputs
		const formInputSize =
			currentPageBreakpointSize === 'xs' ? 'small' : 'medium';
		const commonMaterialUI__InputProps = {
			fullWidth: true,
			variant: 'outlined',
			size: formInputSize,
		};

		return (
			<div className='ForgotPasswordPage'>
				<div className='ForgotPasswordPage__container'>
					<div className='ForgotPasswordPage__header'>
						<img
							src='https://cdn.neurolage.com/ecologital/witmeg_logo.png'
							alt=''
						/>
						<h1>Forgot Password</h1>
					</div>
					<div className='ForgotPasswordPage__body'>
						<PasswordResetRequestForm
							history={history}
							commonMaterialUI__InputProps={commonMaterialUI__InputProps}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withWidth()(ForgotPasswordPage));
