/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/sort-comp */
import { LocalActivity as LocalActivityIcon } from '@material-ui/icons';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import CustomerReviewItemCard from '../../../../../components/pageSections/dashboardPage/common/CustomerReviewItemCard/CustomerReviewItemCard';
import DashboardSubInfoSection from '../../../../../components/pageSections/dashboardPage/common/DashboardSubInfoSection/DashboardSubInfoSection';
import LocationSelector from '../../../../../components/pageSections/dashboardPage/common/LocationSelector/LocationSelector';
import PageLoader from '../../../../../components/widgets/loaders/PageLoader/PageLoader';
import { reqStatusTypes } from '../../../../../configs/constants';
import { sectionName as businessDashboardSectionName } from '../../../../../redux/slices/businessDashboard/businessDashboardConstants';
import { actions as businessDashboardSliceActions } from '../../../../../redux/slices/businessDashboard/businessDashboardSlice/businessDashboardSlice';
import { actions as customerReviewsPageSliceActions } from '../../../../../redux/slices/businessDashboard/customerReviewsPageSlice/customerReviewsPageSlice';
import { getLoggedUserDetails } from '../../../../../utilities/userAuthentication';

import './CustomerReviewsPage.css';

class CustomerReviewsPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedOptions: {},
		};

		this.fetchNecessaryInitialPageData = this.fetchNecessaryInitialPageData.bind(
			this,
		);
		this.handleGetFullOrgDetailsOfBusinessUser = this.handleGetFullOrgDetailsOfBusinessUser.bind(
			this,
		);
		this.handleGetReviewDocuments = this.handleGetReviewDocuments.bind(this);
	}

	// Fetching Initial Org. Details used in Select Boxes.
	async handleGetFullOrgDetailsOfBusinessUser() {
		const { businessDashboardSliceActions } = this.props;

		const {
			getFullOrgDetailsOfBusinessUser: getFullOrgDetailsOfBusinessUserAction,
		} = businessDashboardSliceActions;

		const loggedUserDetails = getLoggedUserDetails();
		const { userid } = loggedUserDetails;

		await getFullOrgDetailsOfBusinessUserAction({
			UserId: userid,
			actionOptions: {
				useDurations: true,
				minimumRefetchDuration: 60 * 10, // Ten Minutes.
			},
		});
	}

	async fetchNecessaryInitialPageData() {
		// Fetching Initial Org. Details.
		await this.handleGetFullOrgDetailsOfBusinessUser();
	}

	async handleGetReviewDocuments(LocationID) {
		const { customerReviewsPageSliceActions } = this.props;

		const {
			getReviewDocuments: getReviewDocumentsAction,
		} = customerReviewsPageSliceActions;

		await getReviewDocumentsAction({
			reqData: {
				LocationID,
				limit: 50,
			},
		});
	}

	componentDidMount() {
		this.fetchNecessaryInitialPageData();
	}

	render() {
		const { selectedOptions } = this.state;
		const { selectedLocation } = selectedOptions;

		const {
			customerReviewsPageSliceState,
			customerReviewsPageSliceActions,
			businessDashboardSliceState,
		} = this.props;

		const {
			currentLocationReviewDocuments,
			getReviewDocumentsReqStatus,
			getReviewDocumentsReqError,
		} = customerReviewsPageSliceState;

		const {
			getReviewDocuments: getReviewDocumentsAction,
			resetAllCustomerReviewsPageSliceRequests: resetAllCustomerReviewsPageSliceRequestsAction,
		} = customerReviewsPageSliceActions;

		const {
			currentUserFullOrgDetails,
			getFullOrgDetailsOfBusinessUserReqStatus,
			getFullOrgDetailsOfBusinessUserReqError,
		} = businessDashboardSliceState;

		// ***** Multiple custom dynamic constants for easy usage. ******

		// Used to show a Loader to indicate some main async functionalities are in process.(Like fetchings started on ComponentDidMount).
		const isMainPageActionsRunning =
			getFullOrgDetailsOfBusinessUserReqStatus === reqStatusTypes.pending;

		const isMainPageActionsError = getFullOrgDetailsOfBusinessUserReqError;

		// Used to show a Loader to indicate some sub async functionalities in process. (Like getting specific settings of specific location)
		const isSubPageActionsRunning =
			getReviewDocumentsReqStatus === reqStatusTypes.pending;

		const isSubPageActionsRunningError =
			getReviewDocumentsReqStatus === reqStatusTypes.failed &&
			getReviewDocumentsReqError;

		const isLocationSelected =
			selectedLocation && Boolean(selectedLocation.label);

		// Indicating Main Page Data Loading.
		if (isMainPageActionsRunning || isMainPageActionsError) {
			return (
				<div className='CustomerReviewsPage GC-DP-DashboardPage'>
					<PageLoader
						isFetching={isMainPageActionsRunning}
						loadingMsg='Please wait while we are fetching the details.'
						isError={isMainPageActionsError}
						errorMsg={isMainPageActionsError}
						onErrorTryAgainFn={() => this.fetchNecessaryInitialPageData()}
						shouldHideWhenEmpty
					/>
				</div>
			);
		}

		return (
			<div className='CustomerReviewsPage GC-DP-DashboardPage'>
				<div>
					<div className='CustomerReviewsPage__header GC-DP-DashboardPageHeader'>
						<h1>CUSTOMER LOYALTY REVIEWS</h1>
					</div>

					<div className='CustomerReviewsPage__body GC-DP-DashboardPageBody'>
						<div className='CustomerReviewsPage__body__locationSelector GC-DP-DashboardPage__LocationSelectorWrapper'>
							<LocationSelector
								orgDetails={currentUserFullOrgDetails}
								instructionMsg='Please select the Location you want to see Reviews by
								filtering through Organization and Company List. Then click on
								the button.'
								onButtonClickFn={async (options) => {
									await this.handleGetReviewDocuments(
										options.selectedLocation.value,
									);

									// Updating our Page's local state with selected options.
									this.setState({
										selectedOptions: options,
									});
								}}
							/>
						</div>

						{/* Used to indicate sub actions like "Loading ReviewItems For Selected Location"  */}
						{(isSubPageActionsRunning || isSubPageActionsRunningError) && (
							<div className='CustomerReviewsPage__body__subPageActionLoader'>
								<PageLoader
									isFetching={isSubPageActionsRunning}
									loadingMsg=''
									isError={isSubPageActionsRunningError}
									errorMsg={isSubPageActionsRunningError}
									onErrorTryAgainTitle='Cancel'
									onErrorTryAgainFn={() => {
										// Resetting all CustomerReviewsPageSliceRequests to RESET errors.
										// So user will be able cancel error view and see initial component and retry again.
										resetAllCustomerReviewsPageSliceRequestsAction();

										// Resetting Local State
										this.setState({
											selectedOptions: {},
										});
									}}
									shouldHideWhenEmpty
								/>
							</div>
						)}

						{/* When No Location Is Selected */}
						{!isSubPageActionsRunning &&
							!isSubPageActionsRunningError &&
							!isLocationSelected && (
								<DashboardSubInfoSection
									className='CustomerReviewsPage__body__getStarted'
									type='GETSTARTED'
									description='Select A Location To Get Started.'
									icon={<LocalActivityIcon color='disabled' />}
								/>
							)}

						{/* When Selected Location's Reviews are Fetched Successfully. */}
						{!isSubPageActionsRunning &&
							!isSubPageActionsRunningError &&
							isLocationSelected && (
								<div className='CustomerReviewsPage__body__reviews'>
									{/* When Selected Location Don't Have any Reviews */}
									{currentLocationReviewDocuments.length === 0 && (
										<DashboardSubInfoSection
											className='CustomerReviewsPage__body__reviews__reviewItems--noItems'
											type='NOITEMS'
											description='No Reviews Available For Selected Location.'
										/>
									)}

									{/* When Selected Location Have CheckIns */}
									{currentLocationReviewDocuments.length > 0 && (
										<div className='CustomerReviewsPage__body__reviews__reviewItems'>
											{currentLocationReviewDocuments
												.slice()
												.sort((a, b) => {
													return (
														new Date(b.CreatedDate) - new Date(a.CreatedDate)
													);
												})
												.map((reviewDoc, index) => {
													return (
														<CustomerReviewItemCard
															key={index}
															reviewValues={reviewDoc}
															showAdminDetails
															refreshDataFn={() => {
																this.handleGetReviewDocuments(
																	selectedLocation.value,
																);
															}}
														/>
													);
												})}
										</div>
									)}
								</div>
							)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		businessDashboardSliceState:
			state[businessDashboardSectionName].businessDashboard,
		customerReviewsPageSliceState:
			state[businessDashboardSectionName].customerReviewsPage,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundBusinessDashboardSliceActions = bindActionCreators(
		businessDashboardSliceActions,
		dispatch,
	);
	const boundCustomerReviewsPageSliceActions = bindActionCreators(
		customerReviewsPageSliceActions,
		dispatch,
	);
	return {
		businessDashboardSliceActions: boundBusinessDashboardSliceActions,
		customerReviewsPageSliceActions: boundCustomerReviewsPageSliceActions,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CustomerReviewsPage);
