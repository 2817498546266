import { createAsyncThunk } from '@reduxjs/toolkit';
import lodash from 'lodash';

import { getFullOrgDetailsOfBusinessUser as getFullOrgDetailsOfBusinessUserAPI } from '../../../../utilities/apiRequests/witmegApiServerRequests';
import { handleAsyncThunkCancellation } from '../../../reduxHelpers';

import sliceConstants from './businessDashboardSliceConstants';

export const getFullOrgDetailsOfBusinessUser = createAsyncThunk(
	`${sliceConstants.name}/getFullOrgDetailsOfBusinessUser`,
	async (options = {}, thunkAPI) => {
		try {
			const { UserId } = options;

			return await getFullOrgDetailsOfBusinessUserAPI({ UserId });
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
	{
		condition: (options, thunkAPI) => {
			const { getState } = thunkAPI;

			const fullState = getState();
			const currentPathState = lodash.get(
				fullState,
				sliceConstants.currentStatePath,
			);

			const {
				getFullOrgDetailsOfBusinessUserReqStatus,
				getFullOrgDetailsOfBusinessUserReqLastUpdated,
			} = currentPathState;

			return handleAsyncThunkCancellation({
				passedActionValues: options,
				reqStatusValue: getFullOrgDetailsOfBusinessUserReqStatus,
				reqLastUpdatedValue: getFullOrgDetailsOfBusinessUserReqLastUpdated,
			});
		},
	},
);

const extraActions = {
	getFullOrgDetailsOfBusinessUser,
};

export default extraActions;
