import {
	InputAdornment,
	CircularProgress,
	FormHelperText,
} from '@material-ui/core';
import { Person as PersonIcon } from '@material-ui/icons';
import React from 'react';

import FormikTextField from '../../../../components/widgets/formikInputs/materialUI/FormikTextField/FormikTextField';
import './PasswordResetRequestFormControls.css';

export default function PasswordResetRequestFormControls(props) {
	const {
		// ***** Props Passdown From Formik ***** (Such as errors, touched, getFieldProps, etc...)
		handleSubmit,
		isSubmitting,
		status, // Manually setted values by us. In our case used to set any API errors occurred while submitting the form.

		// **** Custom Props Manually Passed By Us *****
		commonMaterialUI__InputProps,
	} = props;

	return (
		<form
			className='PasswordResetRequestFormControls'
			onSubmit={handleSubmit}
			noValidate // To disable HTLML5 builtin validations.
		>
			<div className='SignupEmailEntryFormControls__header'>
				<p>
					Please enter your email to reset password. We will send a reset link
					to this email and you can use it to reset you password.
				</p>
			</div>

			<div className='PasswordResetRequestFormControls__inputs'>
				<FormikTextField
					name='UserEmail'
					materialUIProps={{
						...commonMaterialUI__InputProps,
						required: true,
						placeholder: 'Email*',
						InputProps: {
							startAdornment: (
								<InputAdornment position='start'>
									<PersonIcon color='disabled' />{' '}
								</InputAdornment>
							),
						},
					}}
				/>
			</div>

			<div className='PasswordResetRequestFormControls__footer'>
				<FormHelperText error filled>
					{status.error}
				</FormHelperText>

				<button
					type='submit'
					className='PasswordResetRequestFormControls__footer__resetButton'
					disabled={isSubmitting}>
					<div>PASSWORD RESET REQUEST</div>
					<div className='PasswordResetRequestFormControls__footer__resetButton__progress'>
						{isSubmitting && <CircularProgress size={20} />}
					</div>
				</button>
			</div>
		</form>
	);
}
