/* eslint-disable no-param-reassign */
import { reqStatusTypes } from '../../../../configs/constants';

const standardReducers = {
	resetAllCustomerLoyaltyAwardsPageSliceRequests: (state, action) => {
		// ******************************* getLoyaltyAwardsDocuments() *******************************
		state.getLoyaltyAwardsDocumentsReqStatus = reqStatusTypes.idle;
		state.getLoyaltyAwardsDocumentsReqError = '';
	},
};

export default standardReducers;
