import { createAsyncThunk } from '@reduxjs/toolkit';
import lodash from 'lodash';

import {
	getGlobalLoyaltySchemeSettings as getGlobalLoyaltySchemeSettingsAPI,
	addLoyaltySchemeSettings as addLoyaltySchemeSettingsAPI,
	updateLoyaltySchemeSettings as updateLoyaltySchemeSettingsAPI,
	getLoyaltySchemeSettingsByLocationId as getLoyaltySchemeSettingsByLocationIdAPI,
} from '../../../../utilities/apiRequests/witmegLoyaltyServerRequests';
import { handleAsyncThunkCancellation } from '../../../reduxHelpers';

import sliceConstants from './loyaltySchemeSettingsPageSliceConstants';

export const addLoyaltySchemeSettings = createAsyncThunk(
	`${sliceConstants.name}/addLoyaltySchemeSettings`,
	async (options = {}, thunkAPI) => {
		const { fullReqBody } = options;

		try {
			return await addLoyaltySchemeSettingsAPI(fullReqBody);
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

export const updateLoyaltySchemeSettings = createAsyncThunk(
	`${sliceConstants.name}/updateLoyaltySchemeSettings`,
	async (options = {}, thunkAPI) => {
		const { fullReqBody } = options;

		try {
			return await updateLoyaltySchemeSettingsAPI(fullReqBody);
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

export const getGlobalLoyaltySchemeSettings = createAsyncThunk(
	`${sliceConstants.name}/getGlobalLoyaltySchemeSettings`,
	async (options = {}, thunkAPI) => {
		try {
			return await getGlobalLoyaltySchemeSettingsAPI();
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
	{
		condition: (options, thunkAPI) => {
			const { getState } = thunkAPI;

			const fullState = getState();
			const currentPathState = lodash.get(
				fullState,
				sliceConstants.currentStatePath,
			);

			const {
				getGlobalLoyaltySchemeSettingsReqStatus,
				getGlobalLoyaltySchemeSettingsReqLastUpdated,
			} = currentPathState;

			return handleAsyncThunkCancellation({
				passedActionValues: options,
				reqStatusValue: getGlobalLoyaltySchemeSettingsReqStatus,
				reqLastUpdatedValue: getGlobalLoyaltySchemeSettingsReqLastUpdated,
			});
		},
	},
);

export const getLoyaltySchemeSettingsByLocationId = createAsyncThunk(
	`${sliceConstants.name}/getLoyaltySchemeSettingsByLocationId`,
	async (options = {}, thunkAPI) => {
		const { LookupID } = options;

		try {
			return await getLoyaltySchemeSettingsByLocationIdAPI({ LookupID });
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

const extraActions = {
	addLoyaltySchemeSettings,
	updateLoyaltySchemeSettings,
	getGlobalLoyaltySchemeSettings,
	getLoyaltySchemeSettingsByLocationId,
};

export default extraActions;
