/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/sort-comp */
import { PinDrop as PinDropIcon } from '@material-ui/icons';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import CustomerCheckInItemCard from '../../../../../components/pageSections/dashboardPage/common/CustomerCheckInItemCard/CustomerCheckInItemCard';
import DashboardSubInfoSection from '../../../../../components/pageSections/dashboardPage/common/DashboardSubInfoSection/DashboardSubInfoSection';
import LocationSelector from '../../../../../components/pageSections/dashboardPage/common/LocationSelector/LocationSelector';
import PageLoader from '../../../../../components/widgets/loaders/PageLoader/PageLoader';
import { reqStatusTypes } from '../../../../../configs/constants';
import { sectionName as businessDashboardSectionName } from '../../../../../redux/slices/businessDashboard/businessDashboardConstants';
import { actions as businessDashboardSliceActions } from '../../../../../redux/slices/businessDashboard/businessDashboardSlice/businessDashboardSlice';
import { actions as customerCheckInsPageSliceActions } from '../../../../../redux/slices/businessDashboard/customerCheckInsPageSlice/customerCheckInsPageSlice';
import { getLoggedUserDetails } from '../../../../../utilities/userAuthentication';

import './CustomerCheckInsPage.css';

class CustomerCheckInsPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedOptions: {},
		};

		this.fetchNecessaryInitialPageData = this.fetchNecessaryInitialPageData.bind(
			this,
		);
		this.handleGetFullOrgDetailsOfBusinessUser = this.handleGetFullOrgDetailsOfBusinessUser.bind(
			this,
		);
	}

	// Fetching Initial Org. Details used in Select Boxes.
	async handleGetFullOrgDetailsOfBusinessUser() {
		const { businessDashboardSliceActions } = this.props;

		const {
			getFullOrgDetailsOfBusinessUser: getFullOrgDetailsOfBusinessUserAction,
		} = businessDashboardSliceActions;

		const loggedUserDetails = getLoggedUserDetails();
		const { userid } = loggedUserDetails;

		await getFullOrgDetailsOfBusinessUserAction({
			UserId: userid,
			actionOptions: {
				useDurations: true,
				minimumRefetchDuration: 60 * 10, // Ten Minutes.
			},
		});
	}

	async fetchNecessaryInitialPageData() {
		// Fetching Initial Org. Details.
		await this.handleGetFullOrgDetailsOfBusinessUser();
	}

	componentDidMount() {
		this.fetchNecessaryInitialPageData();
	}

	render() {
		const { selectedOptions } = this.state;
		const { selectedLocation } = selectedOptions;

		const {
			customerCheckInsPageSliceState,
			customerCheckInsPageSliceActions,
			businessDashboardSliceState,
		} = this.props;

		const {
			currentLocationCheckInDocuments,
			getCheckInDocumentsReqStatus,
			getCheckInDocumentsReqError,
		} = customerCheckInsPageSliceState;

		const {
			getCheckInDocuments: getCheckInDocumentsAction,
			resetAllCustomerCheckInsPageSliceRequests: resetAllCustomerCheckInsPageSliceRequestsAction,
		} = customerCheckInsPageSliceActions;

		const {
			currentUserFullOrgDetails,
			getFullOrgDetailsOfBusinessUserReqStatus,
			getFullOrgDetailsOfBusinessUserReqError,
		} = businessDashboardSliceState;

		// ***** Multiple custom dynamic constants for easy usage. ******

		// Used to show a Loader to indicate some main async functionalities are in process.(Like fetchings started on ComponentDidMount).
		const isMainPageActionsRunning =
			getFullOrgDetailsOfBusinessUserReqStatus === reqStatusTypes.pending;

		const isMainPageActionsError = getFullOrgDetailsOfBusinessUserReqError;

		// Used to show a Loader to indicate some sub async functionalities in process. (Like getting specific settings of specific location)
		const isSubPageActionsRunning =
			getCheckInDocumentsReqStatus === reqStatusTypes.pending;

		const isSubPageActionsRunningError =
			getCheckInDocumentsReqStatus === reqStatusTypes.failed &&
			getCheckInDocumentsReqError;

		const isLocationSelected =
			selectedLocation && Boolean(selectedLocation.label);

		// Indicating Main Page Data Loading.
		if (isMainPageActionsRunning || isMainPageActionsError) {
			return (
				<div className='CustomerCheckInsPage GC-DP-DashboardPage'>
					<PageLoader
						isFetching={isMainPageActionsRunning}
						loadingMsg='Please wait while we are fetching the details.'
						isError={isMainPageActionsError}
						errorMsg={isMainPageActionsError}
						onErrorTryAgainFn={() => this.fetchNecessaryInitialPageData()}
						shouldHideWhenEmpty
					/>
				</div>
			);
		}

		return (
			<div className='CustomerCheckInsPage GC-DP-DashboardPage'>
				<div>
					<div className='CustomerCheckInsPage__header GC-DP-DashboardPageHeader'>
						<h1>CUSTOMER CHECK INS</h1>
					</div>

					<div className='CustomerCheckInsPage__body GC-DP-DashboardPageBody'>
						<div className='CustomerCheckInsPage__body__locationSelector GC-DP-DashboardPage__LocationSelectorWrapper'>
							<LocationSelector
								orgDetails={currentUserFullOrgDetails}
								instructionMsg='Please select the Location you want to see CheckIns by
								filtering through Organization and Company List. Then click on
								the button.'
								onButtonClickFn={async (options) => {
									await getCheckInDocumentsAction({
										reqData: {
											LocationID: options.selectedLocation.value,
											limit: 50,
										},
									});

									// Updating our Page's local state with selected options.
									this.setState({
										selectedOptions: options,
									});
								}}
							/>
						</div>

						{/* Used to indicate sub actions like "Loading checkInItems For Selected Location"  */}
						{(isSubPageActionsRunning || isSubPageActionsRunningError) && (
							<div className='CustomerCheckInsPage__body__subPageActionLoader'>
								<PageLoader
									isFetching={isSubPageActionsRunning}
									loadingMsg=''
									isError={isSubPageActionsRunningError}
									errorMsg={isSubPageActionsRunningError}
									onErrorTryAgainTitle='Cancel'
									onErrorTryAgainFn={() => {
										// Resetting all CustomerCheckInsPageSliceRequests to RESET errors.
										// So user will be able cancel error view and see initial component and retry again.
										resetAllCustomerCheckInsPageSliceRequestsAction();

										// Resetting Local State
										this.setState({
											selectedOptions: {},
										});
									}}
									shouldHideWhenEmpty
								/>
							</div>
						)}

						{/* When No Location Is Selected */}
						{!isSubPageActionsRunning &&
							!isSubPageActionsRunningError &&
							!isLocationSelected && (
								<DashboardSubInfoSection
									className='CustomerCheckInsPage__body__getStarted'
									type='GETSTARTED'
									description='Select A Location To Get Started.'
									icon={<PinDropIcon color='disabled' />}
								/>
							)}

						{/* When Selected Location's CheckIns are Fetched Successfully. */}
						{!isSubPageActionsRunning &&
							!isSubPageActionsRunningError &&
							isLocationSelected && (
								<div className='CustomerCheckInsPage__body__checkIns'>
									{/* When Selected Location Don't Have any CheckIns */}
									{currentLocationCheckInDocuments.length === 0 && (
										<DashboardSubInfoSection
											className='CustomerCheckInsPage__body__checkIns__checkInItems--noItems'
											type='NOITEMS'
											description='No CheckIns Available For Selected Location.'
										/>
									)}

									{/* When Selected Location Have CheckIns */}
									{currentLocationCheckInDocuments.length > 0 && (
										<div className='CustomerCheckInsPage__body__checkIns__checkInItems'>
											{currentLocationCheckInDocuments
												.slice()
												.sort((a, b) => {
													return (
														new Date(b.CreatedDate) - new Date(a.CreatedDate)
													);
												})
												.map((checkInDoc, index) => {
													return (
														<CustomerCheckInItemCard
															key={index}
															checkInValues={checkInDoc}
															showUserDetails
														/>
													);
												})}
										</div>
									)}
								</div>
							)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		businessDashboardSliceState:
			state[businessDashboardSectionName].businessDashboard,
		customerCheckInsPageSliceState:
			state[businessDashboardSectionName].customerCheckInsPage,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundBusinessDashboardSliceActions = bindActionCreators(
		businessDashboardSliceActions,
		dispatch,
	);
	const boundCustomerCheckInsPageSliceActions = bindActionCreators(
		customerCheckInsPageSliceActions,
		dispatch,
	);
	return {
		businessDashboardSliceActions: boundBusinessDashboardSliceActions,
		customerCheckInsPageSliceActions: boundCustomerCheckInsPageSliceActions,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CustomerCheckInsPage);
