/* eslint-disable no-param-reassign */
import { reqStatusTypes } from '../../../../configs/constants';

const standardReducers = {
	resetAllCustomerReviewsPageSliceRequests: (state, action) => {
		// ******************************* getReviewDocuments() *******************************
		state.getReviewDocumentsReqStatus = reqStatusTypes.idle;
		state.getReviewDocumentsReqError = '';
	},
};

export default standardReducers;
