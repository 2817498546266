import jwt from 'jsonwebtoken';

import globalValues from '../configs/globalValues';

import { isUserLoggedIn, getLoggedUserDetails } from './userAuthentication';

const getGenericExpTime = () => (new Date().getTime() + 1000 * 60 * 15) / 1000; // Fifteen Minutes (NOTE : Need to be seconds. Not miliseconds)
const genericSignOptions = {
	algorithm: 'RS256',
};

export function generateToken(options = {}) {
	const { privateKey = '', payload = {}, signOptions = {} } = options;

	// Processing to fix errors occurred from NEW LINE ('\n') Breaks.
	const processedPrivateKey = privateKey.replace(/\\n/g, '\n');

	return jwt.sign(payload, processedPrivateKey, signOptions);
}

export function generateToken__WitmegApiServer(options = {}) {
	const { privateKey = '', payload = {}, signOptions = {} } = options;
	const {
		WITMEG_APISERVER___PRIVATE_KEY,
		WITMEG_APISERVER___ISSUER,
		WITMEG_APISERVER___AUDIENCE,
	} = globalValues.credentials;

	const customPrivateKey = privateKey || WITMEG_APISERVER___PRIVATE_KEY;

	const customPayload = {
		iss: WITMEG_APISERVER___ISSUER,
		aud: WITMEG_APISERVER___AUDIENCE,
		exp: getGenericExpTime(),
		...payload,
	};

	// If user already logged, Adding Personal 'AccessToken' received when logging/signup process. So user specific request also possible with this token.
	const isLoggedUser = isUserLoggedIn();
	if (isLoggedUser) {
		customPayload.usertoken = getLoggedUserDetails().AccessToken;
	} else {
		delete customPayload.usertoken;
	}

	const customSignOptions = { ...genericSignOptions, ...signOptions };

	return generateToken({
		privateKey: customPrivateKey,
		payload: customPayload,
		signOptions: customSignOptions,
	});
}

export function generateToken__WitmegLoyaltyServer(options = {}) {
	const { privateKey = '', payload = {}, signOptions = {} } = options;
	const {
		WITMEG_LOYALTYSERVER___PRIVATE_KEY,
		WITMEG_LOYALTYSERVER___ISSUER,
		WITMEG_LOYALTYSERVER___AUDIENCE,
	} = globalValues.credentials;

	const customPrivateKey = privateKey || WITMEG_LOYALTYSERVER___PRIVATE_KEY;

	const customPayload = {
		iss: WITMEG_LOYALTYSERVER___ISSUER,
		aud: WITMEG_LOYALTYSERVER___AUDIENCE,
		exp: getGenericExpTime(),
		...payload,
	};

	// If user already logged, Adding Personal 'AccessToken' received when logging/signup process. So user specific request also possible with this token.
	const isLoggedUser = isUserLoggedIn();
	if (isLoggedUser) {
		customPayload.usertoken = getLoggedUserDetails().AccessToken;
	} else {
		delete customPayload.usertoken;
	}

	const customSignOptions = { ...genericSignOptions, ...signOptions };

	return generateToken({
		privateKey: customPrivateKey,
		payload: customPayload,
		signOptions: customSignOptions,
	});
}

export function generateToken__WitmegEmailServer(options = {}) {
	const { privateKey = '', payload = {}, signOptions = {} } = options;
	const {
		WITMEG_EMAILSERVER___PRIVATE_KEY,
		WITMEG_EMAILSERVER___ISSUER,
		WITMEG_EMAILSERVER___AUDIENCE,
	} = globalValues.credentials;

	const customPrivateKey = privateKey || WITMEG_EMAILSERVER___PRIVATE_KEY;

	const customPayload = {
		iss: WITMEG_EMAILSERVER___ISSUER,
		aud: WITMEG_EMAILSERVER___AUDIENCE,
		exp: getGenericExpTime(),
		...payload,
	};

	// If user already logged, Adding Personal 'AccessToken' received when logging/signup process. So user specific request also possible with this token.
	const isLoggedUser = isUserLoggedIn();
	if (isLoggedUser) {
		customPayload.usertoken = getLoggedUserDetails().AccessToken;
	} else {
		delete customPayload.usertoken;
	}

	const customSignOptions = { ...genericSignOptions, ...signOptions };

	return generateToken({
		privateKey: customPrivateKey,
		payload: customPayload,
		signOptions: customSignOptions,
	});
}
