/* eslint-disable sonarjs/cognitive-complexity */

export const genOrganizationSelectValue = (org, customValues = {}) => {
	return {
		value: customValues.OrganisationID || org.OrganisationID,
		label:
			customValues.OrganizationName ||
			customValues.OrganisationID ||
			org.OrganizationName ||
			org.OrganisationID,
		index: customValues.index || 0,
	};
};

export const genCompanySelectValue = (com, customValues = {}) => {
	return {
		value: customValues.CompanyID || com.CompanyID,
		label:
			customValues.CompanyName ||
			customValues.CompanyID ||
			com.CompanyName ||
			com.CompanyID,
		index: customValues.index || 0,
	};
};

export const genLocationSelectValue = (loc, customValues = {}) => {
	return {
		value: customValues.LocationID || loc.LocationID,
		label:
			customValues.LocationName ||
			customValues.LocationID ||
			loc.LocationName ||
			loc.LocationID,
		index: customValues.index || 0,
	};
};

export const handleAutoSelections = (props, state) => {
	/**
	 * NOTE : Keep in mind that, This function will be invoked in "STATIC" getDerivedStateFromProps Lifecycle.
	 * 				This function is used to auto select Organization/Company/Location if theres only "One" options to be selected on each category.
	 */

	const { selectedOrganization, selectedCompany } = state; // Getting already selected values if applicable.
	const { orgDetails = [] } = props;

	// Keep track of things that will be updated on Component State.
	const stateUpdates = {};

	// When there's only 'One' Organization.
	if (orgDetails.length === 1) {
		const org = orgDetails[0];

		stateUpdates.selectedOrganization = genOrganizationSelectValue(org, {
			index: 0,
		});

		// One Organization --> One Company
		if (org.Companies.length === 1) {
			const com = org.Companies[0];
			stateUpdates.selectedCompany = genCompanySelectValue(com, { index: 0 });

			// One Organization --> One Company --> One Location
			if (com.LocationIDs.length === 1) {
				const loc = com.LocationIDs[0];
				stateUpdates.selectedLocation = genLocationSelectValue(loc, {
					index: 0,
				});
			}
		}
	}

	// When Organization is already selected and there's only 'One' Company.
	if (selectedOrganization) {
		const org = orgDetails[selectedOrganization.index];

		// One Company
		if (org.Companies.length === 1) {
			const com = org.Companies[0];
			stateUpdates.selectedCompany = genCompanySelectValue(com, { index: 0 });

			// One Company --> One Location
			if (com.LocationIDs.length === 1) {
				const loc = com.LocationIDs[0];
				stateUpdates.selectedLocation = genLocationSelectValue(loc, {
					index: 0,
				});
			}
		}
	}

	// When Organization and Company is already selected and there's only 'One' Location.
	if (selectedOrganization && selectedCompany) {
		const org = orgDetails[selectedOrganization.index];
		const com = org.Companies[selectedCompany.index];

		// One Location
		if (com.LocationIDs.length === 1) {
			const loc = com.LocationIDs[0];
			stateUpdates.selectedLocation = genLocationSelectValue(loc, {
				index: 0,
			});
		}
	}

	return stateUpdates;
};
