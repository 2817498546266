/*
	NOTE: In here we use 'unstable_createMuiStrictModeTheme' instead of original 'createMuiTheme' to temporally avoid annoying error bug from MaterialUI. (This should be resolved in next version of MaterialUI.)
				See this link for more info. (https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode)
*/
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import 'normalize.css';
import './index.css';

import App from './components/App/App';
import { reduxStore } from './redux/redux';
import reportWebVitals from './reportWebVitals';
import { overrideConsoleLog } from './utilities/mix';
import routerHistory from './utilities/routerHistory';

overrideConsoleLog();
const muiTheme = createMuiTheme();

ReactDOM.render(
	<React.StrictMode>
		<Provider store={reduxStore}>
			<Router history={routerHistory}>
				<ThemeProvider theme={muiTheme}>
					<App />
				</ThemeProvider>
			</Router>
		</Provider>
	</React.StrictMode>,
	document.querySelector('#root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
