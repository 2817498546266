/* eslint-disable no-param-reassign */
import { reqStatusTypes } from '../../../../configs/constants';

import {
	getReviewDocuments,
	addReviewPost,
} from './myReviewsPageSliceExtraActions';

const extraReducers = {
	// ******************************* getReviewDocuments() *******************************
	[getReviewDocuments.pending]: (state, action) => {
		state.getReviewDocumentsReqStatus = reqStatusTypes.pending;
		state.getReviewDocumentsReqError = '';
	},

	[getReviewDocuments.fulfilled]: (state, action) => {
		state.getReviewDocumentsReqStatus = reqStatusTypes.succeeded;
		state.myReviewDocuments = action.payload;
	},

	[getReviewDocuments.rejected]: (state, action) => {
		state.getReviewDocumentsReqStatus = reqStatusTypes.failed;
		state.getReviewDocumentsReqError = action.error.message;
		state.myReviewDocuments = [];
	},

	// ******************************* addReviewPost() *******************************
	[addReviewPost.pending]: (state, action) => {
		state.addReviewPostReqStatus = reqStatusTypes.pending;
		state.addReviewPostReqError = '';
	},

	[addReviewPost.fulfilled]: (state, action) => {
		state.addReviewPostReqStatus = reqStatusTypes.succeeded;
	},

	[addReviewPost.rejected]: (state, action) => {
		state.addReviewPostReqStatus = reqStatusTypes.failed;
		state.addReviewPostReqError = action.error.message;
	},
};

export default extraReducers;
