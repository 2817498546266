import { createAsyncThunk } from '@reduxjs/toolkit';

import {
	getReviewDocuments as getReviewDocumentsAPI,
	authorizeReviewToBePost as authorizeReviewToBePostAPI,
} from '../../../../utilities/apiRequests/witmegLoyaltyServerRequests';

import sliceConstants from './customerReviewsPageSliceConstants';

export const getReviewDocuments = createAsyncThunk(
	`${sliceConstants.name}/getReviewDocuments`,
	async (options = {}, thunkAPI) => {
		const { reqData } = options;

		try {
			return await getReviewDocumentsAPI({ ...reqData });
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

export const authorizeReviewToBePost = createAsyncThunk(
	`${sliceConstants.name}/authorizeReviewToBePost`,
	async (options = {}, thunkAPI) => {
		try {
			return await authorizeReviewToBePostAPI(options);
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

const extraActions = { getReviewDocuments, authorizeReviewToBePost };

export default extraActions;
