import { createAsyncThunk } from '@reduxjs/toolkit';

import {
	fetchCustomerDetails as fetchCustomerDetailsAPI,
	updateCustomerDetails as updateCustomerDetailsAPI,
} from '../../../utilities/apiRequests/witmegApiServerRequests';

import sliceConstants from './userSliceConstants';

export const getCustomerDetails = createAsyncThunk(
	`${sliceConstants.name}/getCustomerDetails`,
	async (options = {}, thunkAPI) => {
		try {
			const { Email } = options;

			return await fetchCustomerDetailsAPI({ Email });
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

export const updateCustomerDetails = createAsyncThunk(
	`${sliceConstants.name}/updateCustomerDetails`,
	async (options = {}, thunkAPI) => {
		try {
			const { fullUpdateDetails, routeParams } = options;

			return await updateCustomerDetailsAPI(fullUpdateDetails, routeParams);
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

const extraActions = {
	getCustomerDetails,
	updateCustomerDetails,
};

export default extraActions;
