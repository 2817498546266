import globalValues from './globalValues';

const {
	WITMEG_APISERVER___BASEURL,
	WITMEG_LOYALTYSERVER___BASEURL,
	WITMEG_EMAILSERVER___BASEURL,
} = globalValues.baseURLS;

const apiEndpoints = {
	// ******************************************* API Server (Org Registry) *******************************************
	signup: `${WITMEG_APISERVER___BASEURL}/customer/global/addcustomer`,
	loginCustomer: `${WITMEG_APISERVER___BASEURL}/customer/global/login`,
	loginBusiness: `${WITMEG_APISERVER___BASEURL}/user/login`,
	checkUserExist: `${WITMEG_APISERVER___BASEURL}/customer/globalfind`,
	passwordResetRequest: `${WITMEG_APISERVER___BASEURL}/customer/global/resetlink`,

	fetchCustomerDetails: `${WITMEG_APISERVER___BASEURL}/customer/globalfetch`,
	updateCustomerDetails: `${WITMEG_APISERVER___BASEURL}/customer/global/update`, //  ---> /customer/global/update/{ecologital}/{customerid}
	addCustomerToOrg: `${WITMEG_APISERVER___BASEURL}/customer/join`, // NOTE : Don't use this. Use 'addLoyaltyCustomerToBusiness' instead.
	getOrgDetailsOfBusinessUser: `${WITMEG_APISERVER___BASEURL}/registereduser/user`, // ---> /registereduser/user/{userid}  NOTE : Don't use this. Use 'getFullOrgDetailsOfBusinessUser' since it return more details.
	getFullOrgDetailsOfBusinessUser: `${WITMEG_APISERVER___BASEURL}/registereduser/userdetail`, // ---> /registereduser/userdetail/{userid}

	encryptLocationId: `${WITMEG_APISERVER___BASEURL}/v1/location/enc/url`,
	decryptLocationId: `${WITMEG_APISERVER___BASEURL}/v1/location/dec/url`,
	decryptURLPortions: `${WITMEG_APISERVER___BASEURL}/v1/dec/url`, // NOTE : For now don't use this to decrypt locationIDs. Instead use 'decryptLocationId'.
	getLocationDetailsByLocationId: `${WITMEG_APISERVER___BASEURL}/location`, // ---> /location/{locationID}

	// *******************************************  Loyalty Server (Loyalty Registry) *******************************************
	addLoyaltyCustomerToBusiness: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyalty/customer/add`,

	checkLoyaltyCustomerAlreadyExistInBusinessByCustomerId: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyalty/findcustomer/customerid`,
	checkLoyaltyCustomerAlreadyExistInBusinessByCustomerEmail: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyalty/findcustomer/customeremail`,
	checkLoyaltyCustomerAlreadyExistInBusinessByCustomerMobileNumber: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyalty/findcustomer/customermobile`,

	getLoyaltyCustomerDetailsInBusinessByCustomerId: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyalty/getcustomer/customerid`,
	getLoyaltyCustomerDetailsInBusinessByCustomerEmail: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyalty/getcustomer/customeremail`,
	getLoyaltyCustomerDetailsInBusinessByCustomerMobileNumber: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyalty/getcustomer/customermobile`,

	addLoyaltySchemeSettings: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/LoyaltySchemeSetting/add`,
	updateLoyaltySchemeSettings: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/LoyaltySchemeSetting/update`,

	getGlobalLoyaltySchemeSettings: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/LoyaltySchemeSetting/global`,
	getLoyaltySchemeSettingsByLocationId: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/LoyaltySchemeSetting/findbylocationid`,
	getLoyaltySchemeSettingsByLocationSettingId: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/LoyaltySchemeSetting/findbyid`,

	getLoyaltyCustomerPointsForAllLocationsByCustomerId: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyalty/customer/locations`, // ---> /v1/loyalty/customer/locations/{customerid}

	getCheckInDocumentsByDocumentId: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyaltycheckin/doc`, // ---> /v1/loyaltycheckin/doc/{docId}
	getCheckInDocumentsByLocationId: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyaltycheckin/location`, // ---> /v1/loyaltycheckin/location/{locationid}/{skip}/{limit}
	getCheckInDocumentsByCustomerId: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyaltycheckin/customer`, // ---> /v1/loyaltycheckin/customer/{customerid}/{skip}/{limit}

	getLoyaltyAwardsDocumentsByDocumentId: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyaltyaward/doc`, // ---> /v1/loyaltyaward/doc/{docid}
	getLoyaltyAwardsDocumentsByLocationId: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyaltyaward/location`, // ---> /v1/loyaltyaward/location/{locationid}/{skip}/{limit}
	getLoyaltyAwardsDocumentsByCustomerId: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyaltyaward/customer`, // ---> /v1/loyaltyaward/customer/{customerid}/{skip}/{limit}

	getReviewDocumentsByDocumentId: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyaltyreview/doc`, // ---> /v1/loyaltyreview/doc/{id}
	getReviewDocumentsByLocationId: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyaltyreview/location`, // ---> /v1/loyaltyreview/location/{locationid}/{skip}/{limit}
	getReviewDocumentsByCustomerId: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyaltyreview/customer`, // ---> /v1/loyaltyreview/customer/{customerid}/{skip}/{limit}

	authorizeReviewToBePost: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyalty/postreview`,
	addReviewPost: `${WITMEG_LOYALTYSERVER___BASEURL}/v1/loyalty/addreview`,

	// *******************************************  Email Server (Email Registry) *******************************************
	sendEmail: `${WITMEG_EMAILSERVER___BASEURL}/v1/email/add`,
};

export default apiEndpoints;
