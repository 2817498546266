import { createAsyncThunk } from '@reduxjs/toolkit';

import { getLoyaltyAwardsDocuments as getLoyaltyAwardsDocumentsAPI } from '../../../../utilities/apiRequests/witmegLoyaltyServerRequests';

import sliceConstants from './customerLoyaltyAwardsPageSliceConstants';

export const getLoyaltyAwardsDocuments = createAsyncThunk(
	`${sliceConstants.name}/getLoyaltyAwardsDocuments`,
	async (options = {}, thunkAPI) => {
		const { reqData } = options;

		try {
			return await getLoyaltyAwardsDocumentsAPI({ ...reqData });
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

const extraActions = { getLoyaltyAwardsDocuments };

export default extraActions;
