/* eslint-disable prefer-const */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-small-switch */
import apiEndpoints from '../../configs/apiEndpoints';
import { emailConfigsDefaults } from '../email/emailConfigs';
import { APIError, errorLogger } from '../errorHandlers';

import axiosWitmegEmailServerInstance from './axiosInstances/axiosWitmegEmailServerInstance';

export async function sendEmail(reqBody = {}, options = {}) {
	const emailConfigs = reqBody || emailConfigsDefaults;

	try {
		const apiReqBody = {
			...emailConfigsDefaults,
			...emailConfigs,
			MessageReceivedAt: new Date().toISOString(),
		};

		const resObj = await axiosWitmegEmailServerInstance({
			url: apiEndpoints.sendEmail,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}
