export const loginTypes = {
	customer: 'CUSTOMER',
	business: 'BUSINESS',
};

export const dashboardTypes = {
	customer: 'CUSTOMER',
	business: 'BUSINESS',
};

export const reqStatusTypes = {
	idle: 'IDLE',
	pending: 'PENDING',
	succeeded: 'SUCCEEDED',
	failed: 'FAILED',
};
