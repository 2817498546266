/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	FormControlLabel,
	Switch,
} from '@material-ui/core';
import {
	ArrowDropDown as ArrowDropDownIcon,
	ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import React from 'react';

import CustomLabeledFormField from '../../../../../components/widgets/formDataViewers/CustomLabeledFormField/CustomLabeledFormField';
import FormikTextField from '../../../../../components/widgets/formikInputs/materialUI/FormikTextField/FormikTextField';
import { transformStringValueToNumber } from '../../../../../utilities/mix';

import { formattedCurrencyList } from './helperValues';

import './LoyaltySchemeSettingsFormControls.css';

// Pre Specifying Which Keys(Scheme Types Values) received from LoyaltySettingsAPI will be displayed and their details. (Ex. Label)
// This structure should be matched to LoyaltySettingsAPI's "Scheme" Key Structure.
const predefinedSchemeTypesDetails = {
	Sales: {
		genSpecialTitle: (formValues, schemeKey) => {
			const { Currency, Schemes } = formValues;

			const { SalesAmount } = Schemes[schemeKey];

			return (
				<div style={{ fontSize: '0.85em' }}>
					<strong>1 Point</strong> will be offered for Sales Amount of{' '}
					<strong>
						{Currency} {SalesAmount / 100}
					</strong>
				</div>
			);
		},
		displayDataKeys: [
			{
				dataKey: 'SalesAmount',
				label: 'Sales Amount',
			},
		],
	},
	SignUp: {
		displayDataKeys: [
			{
				dataKey: 'SignUpPoints',
				label: 'SignUp Points',
			},
		],
	},
	Referral: {
		displayDataKeys: [
			{
				dataKey: 'ReferralPoints',
				label: 'Referral Points',
			},
		],
	},
	SpecialPurchase: {
		displayDataKeys: [
			{
				dataKey: 'SpecialPurchasePoints',
				label: 'Special Purchase Points',
			},
		],
	},
	Review: {
		displayDataKeys: [
			{
				dataKey: 'ReviewPoints',
				label: 'Review Points',
			},
		],
	},
	CheckIn: {
		displayDataKeys: [
			{
				dataKey: 'CheckInPoints',
				label: 'CheckIn Points',
			},
			{
				dataKey: 'CheckInPurchasePoints',
				label: 'CheckIn Purchase Points',
			},
		],
	},
};

const LoyaltySchemeSettingsFormControls = (props) => {
	const {
		formikProps,
		isEditable,
		globalSchemeSettings,
		commonMaterialUI__InputProps,
	} = props;

	const { handleSubmit, values, setFieldValue } = formikProps;

	const globalSchemeTypesData = globalSchemeSettings.Schemes;
	const currentSchemeTypesAsArray = Object.entries(values.Schemes);

	return (
		<form
			className='LoyaltySchemeSettingsFormControls'
			onSubmit={handleSubmit}
			noValidate // To disable HTLML5 builtin validations.
		>
			<div className='LoyaltySchemeSettingsFormControls__fieldGroupsContainer'>
				<div className='LoyaltySchemeSettingsFormControls__fieldGroup'>
					<div className='LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroupsWrapper'>
						<CustomLabeledFormField
							label='Schemes'
							content={
								<div>
									{currentSchemeTypesAsArray.map((scheme, index) => {
										const [schemeKey, schemeDetails] = scheme;

										const currentSchemeTypeLiveValues =
											values.Schemes[schemeKey];
										const currentSchemeTypePredefinedDetails =
											predefinedSchemeTypesDetails[schemeKey];

										const isSchemeActive = currentSchemeTypeLiveValues.IsActive;

										return (
											<div
												className='LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroup'
												key={index}>
												<Accordion>
													<AccordionSummary expandIcon={<ExpandMoreIcon />}>
														<div className='LoyaltySchemeSettingsFormControls__fieldGroup__schemeTypeGroup__title'>
															{schemeDetails.Name ||
																globalSchemeTypesData[schemeKey].Name}
														</div>
														<FormControlLabel
															onClick={(event) => event.stopPropagation()}
															onFocus={(event) => event.stopPropagation()}
															control={
																<Switch
																	color='primary'
																	size='small'
																	onChange={(e) => {
																		setFieldValue(
																			`Schemes.${schemeKey}.IsActive`,
																			!isSchemeActive,
																		);
																	}}
																	checked={isSchemeActive}
																	disabled={!isEditable}
																/>
															}
															labelPlacement='start'
															label='ACTIVE'
														/>
													</AccordionSummary>

													<AccordionDetails>
														{currentSchemeTypePredefinedDetails &&
															currentSchemeTypePredefinedDetails.displayDataKeys.map(
																(displayData, index2) => {
																	return (
																		<div key={index2}>
																			{/* Generating and Showing special tittle if 'predefinedSchemeTypesDetails' have specified a title generator function.  */}
																			<div>
																				{currentSchemeTypePredefinedDetails.genSpecialTitle &&
																					currentSchemeTypePredefinedDetails.genSpecialTitle(
																						values,
																						schemeKey,
																					)}
																			</div>

																			<CustomLabeledFormField
																				label={displayData.label}
																				isSubItem
																				content={
																					<FormikTextField
																						name={`Schemes.${schemeKey}.${displayData.dataKey}`}
																						transformValueFn={
																							transformStringValueToNumber
																						}
																						materialUIProps={{
																							...commonMaterialUI__InputProps,
																							required: true,
																						}}
																					/>
																				}
																			/>
																		</div>
																	);
																},
															)}
													</AccordionDetails>
												</Accordion>
											</div>
										);
									})}
								</div>
							}
						/>
					</div>
				</div>

				<div className='LoyaltySchemeSettingsFormControls__fieldGroup'>
					<CustomLabeledFormField
						label='Voucher Generation Limit'
						content={
							<FormikTextField
								name='VoucherGenerationLimit'
								transformValueFn={transformStringValueToNumber}
								materialUIProps={{
									...commonMaterialUI__InputProps,
									required: true,
								}}
							/>
						}
					/>

					<CustomLabeledFormField
						label='Points Valuation'
						content={
							<FormikTextField
								name='PointsValuation'
								transformValueFn={transformStringValueToNumber}
								materialUIProps={{
									...commonMaterialUI__InputProps,
									required: true,
								}}
							/>
						}
					/>

					<CustomLabeledFormField
						label='Currency'
						content={
							<FormikTextField
								name='Currency'
								materialUIProps={{
									...commonMaterialUI__InputProps,
									select: true,
									defaultValue: 'GBP',
									required: true,
									SelectProps: {
										IconComponent: isEditable
											? ArrowDropDownIcon
											: () => {
													return <div />;
											  },
									},
								}}>
								{formattedCurrencyList}
							</FormikTextField>
						}
					/>
				</div>
			</div>
		</form>
	);
};

export default LoyaltySchemeSettingsFormControls;
