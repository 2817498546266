/* eslint-disable promise/always-return */
import { Formik } from 'formik';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { checkUserAlreadyExist } from '../../../../utilities/apiRequests/witmegApiServerRequests';
import { sendEmail } from '../../../../utilities/apiRequests/witmegEmailServerRequests';
import { generateSignupEmailVerificationEmail } from '../../../../utilities/email/emailTemplates/SignupEmailVerificationEmailTemplate';
import { genericErrorHandler } from '../../../../utilities/errorHandlers';

import SignupEmailEntryFormControls from './SignupEmailEntryFormControls';

const SignupEmailEntryFormValidationSchema = Yup.object().shape({
	Email: Yup.string().email('Invalid Email').required('Required'),
});

const SignupEmailEntryFormInitialValues = {
	Email: '',
};

export default class SignupEmailEntryForm extends Component {
	async sendEmailVerificationCode(formikProps) {
		const formValues = formikProps[0];
		const { setSubmitting, setStatus } = formikProps[1];

		try {
			const {
				currentFormStep,
				setCurrentFormStep,
				setCustomFormData,
			} = this.props;

			const emailConfirmationCode = uuidv4().split('-')[0].toUpperCase();

			await sendEmail({
				To: formValues.Email,
				Subject: 'Witmeg Loyalty Program - SignUp - Email Verification Code',
				HTMLContent: generateSignupEmailVerificationEmail({
					email: formValues.Email,
					verificationCode: emailConfirmationCode,
				}),
			});

			// All is good. EmailConfirmationCode is Sended.
			toast.success('Email Verification Code Sent Successfully.');
			setStatus({
				error: '',
			});
			setSubmitting(false);
			setCustomFormData({
				Email: formValues.Email,
				emailConfirmationCode,
			});
			setCurrentFormStep(currentFormStep + 1);
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;

			setStatus({
				error: errMsg,
			});
			setSubmitting(false);
		}
	}

	async checkUserAlreadyExist(formikProps) {
		const formValues = formikProps[0];
		const { setStatus } = formikProps[1];

		try {
			const resData = await checkUserAlreadyExist({
				Email: formValues.Email,
			})
				.then(() => {
					return true;
				})
				.catch((error) => {
					const errMsg = error.message;

					// When there is no account for provided email. (It normally throws error. So in here we catch it here and return false to indicate no user available.)
					if (errMsg.includes('no documents in result')) {
						return false;
					}

					// For any other error we throw error again. So below catch block can get it.
					throw error;
				});

			setStatus({
				error: '',
			});

			return resData;
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;

			setStatus({
				error: errMsg,
			});

			return errMsg;
		}
	}

	render() {
		const {
			history,
			commonMaterialUI__InputProps,
			reactRouterState,
		} = this.props;
		const { customValues = {} } = reactRouterState;

		const formInitialValues = {
			...SignupEmailEntryFormInitialValues,
			Email: customValues.Email || SignupEmailEntryFormInitialValues.Email,
		};

		return (
			<div className='SignupEmailEntryForm'>
				<div>
					<Formik
						initialStatus={{}}
						initialValues={formInitialValues}
						validationSchema={SignupEmailEntryFormValidationSchema}
						onSubmit={async (...formikProps) => {
							const formValues = formikProps[0];
							const { setSubmitting, setStatus } = formikProps[1];

							try {
								await this.checkUserAlreadyExist(formikProps).then(
									async (userExist) => {
										if (userExist === true) {
											toast.info(
												'Account already exist for this user. Redirected to Login.',
											);
											history.push('/login');
										} else if (userExist === false) {
											await this.sendEmailVerificationCode(formikProps);
										}
									},
								);
							} catch (error) {
								// Handling UnSpecific Errors.
								const { errMsg } = genericErrorHandler(error);

								setStatus({
									error: errMsg,
								});
								setSubmitting(false);
							}
						}}
						component={(formikProps) => (
							<SignupEmailEntryFormControls
								{...formikProps}
								commonMaterialUI__InputProps={commonMaterialUI__InputProps}
							/>
						)}
					/>
				</div>
			</div>
		);
	}
}
