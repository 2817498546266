/* eslint-disable jsx-a11y/no-static-element-interactions */
import jwtDecode from 'jwt-decode';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import browserStorage from 'store2';

import routerHistory from './routerHistory';

export function isUserLoggedIn() {
	const existingUserValueInStorage = browserStorage.get('user') || {};

	if (existingUserValueInStorage.AccessToken) {
		return true;
	}
	return false;
}

export function saveLoggedUser(passedUserDetails = {}) {
	const { AccessToken } = passedUserDetails;

	if (!AccessToken) {
		return;
	}
	const existingUserValuesInStorage = browserStorage.get('user');

	const decodedTokenInfo = jwtDecode(AccessToken);

	browserStorage.set('user', {
		...existingUserValuesInStorage,
		...passedUserDetails,
		...decodedTokenInfo,
		...{
			isBusinessUser: passedUserDetails.isBusinessUser || false,
		},
	});
}

export function logoutUser(options = {}) {
	const {
		redirectTo = '/login',
		history = routerHistory,
		preventRedirect = false,
	} = options;

	browserStorage.set('user', {});

	if (!preventRedirect && history && history.push) {
		history.push(redirectTo);
	}

	return !preventRedirect ? <Redirect to={redirectTo} /> : null;
}

export function LogoutUserButtonWrapper(props) {
	const { children, redirectTo = '/login' } = props;

	const [loggedOut, setLoggedOut] = useState(false);

	const logout = () => {
		setLoggedOut(true);
	};

	if (loggedOut) {
		return <Redirect to={redirectTo} push />;
	}

	return (
		<div
			onClick={logout}
			style={{
				width: '100%',
			}}>
			{children}
		</div>
	);
}

export function getLoggedUserDetails() {
	return browserStorage.get('user') || {};
}

export function checkIsBusinessUser() {
	const existingUserValueInStorage = getLoggedUserDetails();

	if (existingUserValueInStorage.isBusinessUser) {
		return true;
	}
	return false;
}

// NOTE : This only handle expiration by checking token exp value in local storage. Token expiration error happen from API endpoints handled in Axios Instance. And Also in <PrivateRoute ...>'s.
export function checkIsUserExpired(options = {}) {
	if (!isUserLoggedIn()) {
		return false;
	}

	const existingUserValueInStorage = getLoggedUserDetails();
	const { exp: tokenExpireTimeAsSeconds } = existingUserValueInStorage;

	const expTime = DateTime.fromSeconds(tokenExpireTimeAsSeconds);
	const currentTime = DateTime.local();

	return expTime < currentTime;
}

export function removeUserIfExpired() {
	if (isUserLoggedIn() && checkIsUserExpired()) {
		logoutUser({ preventRedirect: true });
	}
}
