import { reqStatusTypes } from '../../../../configs/constants';
import { sectionName } from '../businessDashboardConstants';

const sliceName = 'businessDashboard';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,
	initialState: {
		// ******************************* getFullOrgDetailsOfBusinessUser() *******************************
		getFullOrgDetailsOfBusinessUserReqStatus: reqStatusTypes.idle,
		getFullOrgDetailsOfBusinessUserReqError: '',
		getFullOrgDetailsOfBusinessUserReqLastUpdated: '',
		currentUserFullOrgDetails: [],
	},
};

export default sliceConstants;
