/* eslint-disable react/no-array-index-key */
import {
	Avatar,
	IconButton,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@material-ui/core';
import {
	Add as AddIcon,
	ArrowDropDown as ArrowDropDownIcon,
	ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import { DateTime } from 'luxon';
import React from 'react';

import CustomLabeledFormField from '../../../../../components/widgets/formDataViewers/CustomLabeledFormField/CustomLabeledFormField';
import FormikTextField from '../../../../../components/widgets/formikInputs/materialUI/FormikTextField/FormikTextField';

import { formattedCountryList } from './helperValues';

import './CustomerDetailsPageFormControls.css';

const CustomerDetailsPageFormControls = (props) => {
	const {
		formikProps,
		customerDetailsState,
		orgCustomerDetails,
		commonMaterialUI__InputProps,
	} = props;

	const { handleSubmit, setValues, values } = formikProps;

	const { isEditable } = customerDetailsState;

	// Using formik values or component state's values depending on Editable Mode or Not.
	const customerDetails = isEditable ? values : orgCustomerDetails;

	return (
		<form
			className='CustomerDetailsPageFormControls'
			onSubmit={handleSubmit}
			noValidate // To disable HTLML5 builtin validations.
		>
			<div className='CustomerDetailsPageFormControls__infoContainer'>
				<div className='CustomerDetailsPageFormControls__infoItemsGroup'>
					<CustomLabeledFormField
						label='Avatar'
						content={
							<Avatar
								alt='Users Avatar'
								src={customerDetails.Avatar}
								style={{ background: '#484848' }}>
								{customerDetails.FirstName[0] + customerDetails.LastName[0]}
							</Avatar>
						}
					/>
					<CustomLabeledFormField
						label='Username'
						content={
							<FormikTextField
								name='UserName'
								materialUIProps={{
									...commonMaterialUI__InputProps,
									required: true,
									disabled: true,
									className: 'GC-DP-FormikField--nonEditable',
								}}
							/>
						}
					/>
					<CustomLabeledFormField
						label='Email'
						content={
							<FormikTextField
								name='Email'
								materialUIProps={{
									...commonMaterialUI__InputProps,
									required: true,
									disabled: true,
									className: 'GC-DP-FormikField--nonEditable',
								}}
							/>
						}
					/>
					<CustomLabeledFormField
						label='Password'
						content={
							<FormikTextField
								name='Password'
								materialUIProps={{
									...commonMaterialUI__InputProps,
									required: true,
									type: 'password',
									placeholder: '*********',
								}}
							/>
						}
					/>
				</div>

				<div className='CustomerDetailsPageFormControls__infoItemsGroup'>
					<CustomLabeledFormField
						label='First Name'
						content={
							<FormikTextField
								name='FirstName'
								materialUIProps={{
									...commonMaterialUI__InputProps,
									required: true,
								}}
							/>
						}
					/>
					<CustomLabeledFormField
						label='Last Name'
						content={
							<FormikTextField
								name='LastName'
								materialUIProps={{
									...commonMaterialUI__InputProps,
									required: true,
								}}
							/>
						}
					/>
					<CustomLabeledFormField
						label='Company Name'
						content={
							<FormikTextField
								name='CompanyName'
								materialUIProps={{
									...commonMaterialUI__InputProps,
								}}
							/>
						}
					/>
					<CustomLabeledFormField
						label='Addresses'
						content={
							<div>
								{/* Temporary Solution to Add Address Block */}
								{!customerDetails.Addresses ||
								customerDetails.Addresses.length === 0 ? (
									isEditable ? (
										<IconButton
											color='primary'
											onClick={() => {
												setValues({
													...customerDetails,
													Addresses: [
														{
															AddressType: '',
															AddressLine1: '',
															AddressLine2: '',
															StateProvinceRegion: '',
															PostZipCode: '',
															City: '',
															Country: '',
															Email: '',
															Phone: '',
															Fax: '',
														},
													],
												});
											}}>
											<AddIcon />
										</IconButton>
									) : (
										'-'
									)
								) : (
									''
								)}

								{customerDetails.Addresses &&
									customerDetails.Addresses.map((addressSet, index) => {
										return (
											<div
												className='CustomerDetailsPageFormControls__infoItemsGroup__addressGroup'
												key={index}>
												<Accordion>
													<AccordionSummary expandIcon={<ExpandMoreIcon />}>
														<div className='CustomerDetailsPageFormControls__infoItemsGroup__addressGroup__title'>
															Address {index + 1}
														</div>
													</AccordionSummary>

													<AccordionDetails>
														<CustomLabeledFormField
															label='Address Type'
															content={
																<FormikTextField
																	name={`Addresses[${index}].AddressType`}
																	materialUIProps={{
																		...commonMaterialUI__InputProps,
																	}}
																/>
															}
															isSubItem
														/>
														<CustomLabeledFormField
															label='Address Line 1'
															content={
																<FormikTextField
																	name={`Addresses[${index}].AddressLine1`}
																	materialUIProps={{
																		...commonMaterialUI__InputProps,
																		required: true,
																	}}
																/>
															}
															isSubItem
														/>
														<CustomLabeledFormField
															label='Address Line 2'
															content={
																<FormikTextField
																	name={`Addresses[${index}].AddressLine2`}
																	materialUIProps={{
																		...commonMaterialUI__InputProps,
																	}}
																/>
															}
															isSubItem
														/>
														<CustomLabeledFormField
															label='State / Province / Region'
															content={
																<FormikTextField
																	name={`Addresses[${index}].StateProvinceRegion`}
																	materialUIProps={{
																		...commonMaterialUI__InputProps,
																		required: true,
																	}}
																/>
															}
															isSubItem
														/>
														<CustomLabeledFormField
															label='Post / Zip Code'
															content={
																<FormikTextField
																	name={`Addresses[${index}].PostZipCode`}
																	materialUIProps={{
																		...commonMaterialUI__InputProps,
																		required: true,
																	}}
																/>
															}
															isSubItem
														/>
														<CustomLabeledFormField
															label='City'
															content={
																<FormikTextField
																	name={`Addresses[${index}].City`}
																	materialUIProps={{
																		...commonMaterialUI__InputProps,
																		required: true,
																	}}
																/>
															}
															isSubItem
														/>
														<CustomLabeledFormField
															label='Country'
															content={
																<FormikTextField
																	name={`Addresses[${index}].Country`}
																	materialUIProps={{
																		...commonMaterialUI__InputProps,
																		select: true,
																		defaultValue: '',
																		required: true,
																		SelectProps: {
																			IconComponent: isEditable
																				? ArrowDropDownIcon
																				: () => {
																						return <div />;
																				  },
																		},
																	}}>
																	{formattedCountryList}
																</FormikTextField>
															}
															isSubItem
														/>
														<CustomLabeledFormField
															label='Email'
															content={
																<FormikTextField
																	name={`Addresses[${index}].Email`}
																	materialUIProps={{
																		...commonMaterialUI__InputProps,
																		required: true,
																	}}
																/>
															}
															isSubItem
														/>
														<CustomLabeledFormField
															label='Phone'
															content={
																<FormikTextField
																	name={`Addresses[${index}].Phone`}
																	materialUIProps={{
																		...commonMaterialUI__InputProps,
																		required: true,
																	}}
																/>
															}
															isSubItem
														/>
														<CustomLabeledFormField
															label='Fax'
															content={
																<FormikTextField
																	name={`Addresses[${index}].Fax`}
																	materialUIProps={{
																		...commonMaterialUI__InputProps,
																	}}
																/>
															}
															isSubItem
														/>
													</AccordionDetails>
												</Accordion>
											</div>
										);
									})}
							</div>
						}
					/>
				</div>

				<div className='CustomerDetailsPageFormControls__infoItemsGroup'>
					<CustomLabeledFormField
						label='Default Payment Type'
						content={
							<FormikTextField
								name='DefaultPaymentType'
								materialUIProps={{
									...commonMaterialUI__InputProps,
									disabled: true,
									className: 'GC-DP-FormikField--nonEditable',
								}}
							/>
						}
					/>
					<CustomLabeledFormField
						label='Payment Types'
						content={
							<FormikTextField
								name='PaymentTypes'
								materialUIProps={{
									...commonMaterialUI__InputProps,
									disabled: true,
									className: 'GC-DP-FormikField--nonEditable',
								}}
							/>
						}
					/>
					<CustomLabeledFormField
						label='Payment Card Details'
						content={
							<FormikTextField
								name='PaymentCardDetails'
								materialUIProps={{
									...commonMaterialUI__InputProps,
									disabled: true,
									className: 'GC-DP-FormikField--nonEditable',
								}}
							/>
						}
					/>

					<CustomLabeledFormField
						label='Customer Vat No'
						content={
							<FormikTextField
								name='CustomerVatNo'
								materialUIProps={{
									...commonMaterialUI__InputProps,
								}}
							/>
						}
					/>
				</div>

				<div className='CustomerDetailsPageFormControls__infoItemsGroup'>
					<CustomLabeledFormField
						label='Joined Date'
						content={
							<FormikTextField
								name='RegisteredDate'
								displayValueFn={(value) => {
									return DateTime.fromISO(value).toLocaleString(
										DateTime.DATE_FULL,
									);
								}}
								materialUIProps={{
									...commonMaterialUI__InputProps,
									disabled: true,
									className: 'GC-DP-FormikField--nonEditable',
								}}
							/>
						}
					/>
					<CustomLabeledFormField
						label='Active Status'
						content={
							<FormikTextField
								name='IsActive'
								displayValueFn={(value) => {
									return value ? 'Active' : 'Deactivated';
								}}
								materialUIProps={{
									...commonMaterialUI__InputProps,
									disabled: true,
									className: 'GC-DP-FormikField--nonEditable',
								}}
							/>
						}
					/>
				</div>
			</div>
		</form>
	);
};

export default CustomerDetailsPageFormControls;
