/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/sort-comp */
import { IconButton, withWidth } from '@material-ui/core';
import {
	GetApp as GetAppIcon,
	BlurLinear as BlurLinearIcon,
	CropFree as CropFreeIcon,
} from '@material-ui/icons';
import { bindActionCreators } from '@reduxjs/toolkit';
import QRCode from 'qrcode.react';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DashboardSubInfoSection from '../../../../../components/pageSections/dashboardPage/common/DashboardSubInfoSection/DashboardSubInfoSection';
import LocationSelector from '../../../../../components/pageSections/dashboardPage/common/LocationSelector/LocationSelector';
import PageLoader from '../../../../../components/widgets/loaders/PageLoader/PageLoader';
import { reqStatusTypes } from '../../../../../configs/constants';
import { sectionName as businessDashboardSectionName } from '../../../../../redux/slices/businessDashboard/businessDashboardConstants';
import { actions as businessDashboardSliceActions } from '../../../../../redux/slices/businessDashboard/businessDashboardSlice/businessDashboardSlice';
import { actions as qrCodeGeneratorPageSliceActions } from '../../../../../redux/slices/businessDashboard/qrCodeGeneratorPageSlice/qrCodeGeneratorPageSlice';
import { getLoggedUserDetails } from '../../../../../utilities/userAuthentication';

import './QrCodeGeneratorPage.css';

class QrCodeGeneratorPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedOptions: {},
		};

		this.handleGetFullOrgDetailsOfBusinessUser = this.handleGetFullOrgDetailsOfBusinessUser.bind(
			this,
		);
		this.handleGenerateQrValue = this.handleGenerateQrValue.bind(this);
		this.downloadQR = this.downloadQR.bind(this);
	}

	// Fetching Initial Org. Details used in Select Boxes.
	async handleGetFullOrgDetailsOfBusinessUser() {
		const { businessDashboardSliceActions } = this.props;

		const {
			getFullOrgDetailsOfBusinessUser: getFullOrgDetailsOfBusinessUserAction,
		} = businessDashboardSliceActions;

		const loggedUserDetails = getLoggedUserDetails();
		const { userid } = loggedUserDetails;

		await getFullOrgDetailsOfBusinessUserAction({
			UserId: userid,
			actionOptions: {
				useDurations: true,
				minimumRefetchDuration: 60 * 10, // Ten Minutes.
			},
		});
	}

	// Generating QR value by encrypting LocationId through API.
	async handleGenerateQrValue(options = {}) {
		const { selectedOptions } = this.state;
		const {
			selectedOrganization,
			selectedCompany,
			selectedLocation,
		} = selectedOptions;

		const { qrCodeGeneratorPageSliceActions } = this.props;

		const {
			generateQrValue: generateQrValueAction,
		} = qrCodeGeneratorPageSliceActions;

		const { LocationID } = options;

		await generateQrValueAction({
			selectedOrganization,
			selectedCompany,
			selectedLocation,
			LocationID,
			actionOptions: {
				useDurations: true,
				minimumRefetchDuration: 60 * 10, // Ten Minutes.
			},
		});
	}

	downloadQR(fileName = 'Location.png') {
		const canvas = document.querySelector('.QrCodeGeneratorPage canvas');
		const element = document.createElement('a');
		element.download = `QR - ${fileName}`;
		element.href = canvas
			.toDataURL('image/png')
			.replace('image/png', 'image/octet-stream');
		element.click();
	}

	componentDidMount() {
		// Fetching Initial Org. Details.
		this.handleGetFullOrgDetailsOfBusinessUser();
	}

	componentWillUnmount() {
		const { qrCodeGeneratorPageSliceActions } = this.props;
		const { resetGenerateQrValueFnInvokes } = qrCodeGeneratorPageSliceActions;

		resetGenerateQrValueFnInvokes();
	}

	render() {
		const { selectedOptions } = this.state;
		const { selectedLocation } = selectedOptions;

		const {
			width: currentPageBreakpointSize,
			businessDashboardSliceState,
			qrCodeGeneratorPageSliceState,
		} = this.props;

		const {
			currentUserFullOrgDetails,
			getFullOrgDetailsOfBusinessUserReqStatus,
			getFullOrgDetailsOfBusinessUserReqError,
		} = businessDashboardSliceState;

		const {
			generateQrValueReqStatus,
			generateQrValueReqError,
			generatedQrValue,
			generatedQrValueDescription,
		} = qrCodeGeneratorPageSliceState;

		const isFetchingOrgDetails =
			getFullOrgDetailsOfBusinessUserReqStatus === reqStatusTypes.pending;
		const isFetchingOrgDetailsError =
			getFullOrgDetailsOfBusinessUserReqStatus === reqStatusTypes.failed &&
			getFullOrgDetailsOfBusinessUserReqError;

		const isQrGenerating = generateQrValueReqStatus === reqStatusTypes.pending;
		const isQrGeneratingError =
			generateQrValueReqStatus === reqStatusTypes.failed &&
			generateQrValueReqError;
		const qrValue = generatedQrValue;
		const currentlyCreatedQrValueDescription = generatedQrValueDescription;

		// Initial page loader for indicate Org. Details fetching.
		if (isFetchingOrgDetails || isFetchingOrgDetailsError) {
			return (
				<div className='QrCodeGeneratorPage GC-DP-DashboardPage'>
					<PageLoader
						isFetching={isFetchingOrgDetails}
						loadingMsg='Please wait while we are fetching the details.'
						isError={isFetchingOrgDetailsError}
						errorMsg={isFetchingOrgDetailsError}
						onErrorTryAgainFn={this.handleGetOrgDetailsOfBusinessUser}
						shouldHideWhenEmpty
					/>
				</div>
			);
		}

		return (
			<div className='QrCodeGeneratorPage GC-DP-DashboardPage'>
				<div>
					<div className='QrCodeGeneratorPage__header GC-DP-DashboardPageHeader'>
						<h1>QR CODE GENERATOR</h1>
					</div>

					<div className='QrCodeGeneratorPage__bodyWrapper GC-DP-DashboardPageBody'>
						<div className='QrCodeGeneratorPage__locationSelector GC-DP-DashboardPage__LocationSelectorWrapper'>
							<LocationSelector
								orgDetails={currentUserFullOrgDetails}
								instructionMsg='Please select the Location you want to generate QR code by
								filtering through Organization and Company List. Then click on
								the button.'
								onButtonClickFn={async (options) => {
									// Updating our Page's local state with selected options.
									this.setState(
										{
											selectedOptions: options,
										},
										() => {
											this.handleGenerateQrValue({
												LocationID: options.selectedLocation.value,
											});
										},
									);
								}}
							/>
						</div>

						<div className='QrCodeGeneratorPage__caseHandling'>
							{/* This is displayed while Preparing/Generating QR Code. */}
							<PageLoader
								isFetching={isQrGenerating}
								loadingMsg='Please Wait. Preparing The QR Code.'
								isError={isQrGeneratingError}
								errorMsg={isQrGeneratingError}
								onErrorTryAgainFn={() =>
									this.handleGenerateQrValue({
										LocationID: selectedLocation.value,
									})
								}
								shouldHideWhenEmpty
							/>

							{/* This is displayed if no QR generation not happened yet. */}
							{!qrValue && !isQrGenerating && !isQrGeneratingError && (
								<DashboardSubInfoSection
									className='QrCodeGeneratorPage__caseHandling__getStarted'
									type='GETSTARTED'
									description='Select A Location To Get Started.'
									icon={<CropFreeIcon color='disabled' />}
								/>
							)}
						</div>

						{qrValue && !isQrGenerating && !isQrGeneratingError && (
							<div className='QrCodeGeneratorPage__qrCodeWrapper'>
								<div>
									<div className='QrCodeGeneratorPage__qrCodeWrapper__qr'>
										<QRCode
											value={qrValue}
											size={currentPageBreakpointSize === 'xs' ? 300 : 500}
											bgColor='#ffffff'
											fgColor='#000000'
											level='Q'
											includeMargin
											renderAs='canvas'
										/>
										<div>
											<b>QR FOR : </b>
											{currentlyCreatedQrValueDescription}
										</div>
									</div>

									<div className='QrCodeGeneratorPage__qrCodeWrapper__buttons'>
										<div
											className='QrCodeGeneratorPage__qrCodeWrapper__buttons__download'
											onClick={() => {
												this.downloadQR(currentlyCreatedQrValueDescription);
											}}>
											<IconButton
												variant='contained'
												color='primary'
												disabled={!qrValue}
												size='medium'>
												<GetAppIcon />
											</IconButton>
											<div>DOWNLOAD</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		businessDashboardSliceState:
			state[businessDashboardSectionName].businessDashboard,
		qrCodeGeneratorPageSliceState:
			state[businessDashboardSectionName].qrCodeGeneratorPage,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundBusinessDashboardSliceActions = bindActionCreators(
		businessDashboardSliceActions,
		dispatch,
	);
	const boundQrCodeGeneratorPageSliceActions = bindActionCreators(
		qrCodeGeneratorPageSliceActions,
		dispatch,
	);

	return {
		businessDashboardSliceActions: boundBusinessDashboardSliceActions,
		qrCodeGeneratorPageSliceActions: boundQrCodeGeneratorPageSliceActions,
	};
};

const hocWithWidth = withWidth()(QrCodeGeneratorPage);
export default connect(mapStateToProps, mapDispatchToProps)(hocWithWidth);
