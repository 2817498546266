/* eslint-disable no-shadow */
import {
	CircularProgress,
	FormControlLabel,
	Switch,
	Button,
} from '@material-ui/core';
import { bindActionCreators, unwrapResult } from '@reduxjs/toolkit';
import { Formik } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import PageLoader from '../../../../../components/widgets/loaders/PageLoader/PageLoader';
import { reqStatusTypes } from '../../../../../configs/constants';
import { actions as userActions } from '../../../../../redux/slices/userSlice/userSlice';
import { getLoggedUserDetails } from '../../../../../utilities/userAuthentication';

import CustomerDetailsPageFormControls from './CustomerDetailsPageFormControls';
import {
	CustomerDetailsPageValidationSchema,
	CustomerDetailsPageInitialValues,
} from './helperValues';

import './CustomerDetailsPage.css';

class CustomerDetailsPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isEditable: false, // Toggle ability to edit some user details.
		};

		// Fn Bindings.
		this.handleFetchCustomerDetails = this.handleFetchCustomerDetails.bind(
			this,
		);
		this.handleUpdateCustomerDetails = this.handleUpdateCustomerDetails.bind(
			this,
		);
	}

	componentDidMount() {
		this.handleFetchCustomerDetails();
	}

	handleFetchCustomerDetails() {
		const { userActions } = this.props;
		const { getCustomerDetails: getCustomerDetailsAction } = userActions;

		const loggedUserDetails = getLoggedUserDetails();
		const { Email } = loggedUserDetails;

		getCustomerDetailsAction({ Email });
	}

	async handleUpdateCustomerDetails(options = {}) {
		const { userActions, userState } = this.props;
		const { customerDetails } = userState;
		const { updateCustomerDetails: updateCustomerDetailsAction } = userActions;

		const { formValues, setSubmitting, resetForm } = options;

		const fullUpdateDetails = {
			...customerDetails,
			...formValues,
		};

		// Avoiding update of password if not specifically set by user.
		if (formValues.Password === '') {
			delete fullUpdateDetails.Password;
		}

		await updateCustomerDetailsAction({
			fullUpdateDetails,
			routeParams: {
				OriginatedOrganizationID: customerDetails.OriginatedOrganizationID,
				UserID: customerDetails.UserID,
			},
		})
			.then(unwrapResult)
			.then((res) => {
				resetForm({
					values: res,
				});
				return toast.success('Successfully Updated.');
			})
			.catch((error) => {
				resetForm({
					values: customerDetails,
				});

				const errMsg = error.customErrMsg || error.message;
				return toast.error(`Error Occurred. ${errMsg}`);
			});

		setSubmitting(false);
		this.setState({
			isEditable: false,
		});
	}

	render() {
		// NOTE : Since both Details Showing & Updating are happening on single page, all elements are rendered inside "Formik" container.

		const { isEditable } = this.state;
		const { userState } = this.props;

		const {
			customerDetails,
			getCustomerDetailsReqStatus,
			getCustomerDetailsReqError,
		} = userState;

		const isFetching = getCustomerDetailsReqStatus === reqStatusTypes.pending;
		const isFetched = getCustomerDetailsReqStatus === reqStatusTypes.succeeded;
		const isError = getCustomerDetailsReqError;

		// This is specifically for CustomerDetails "Fetching" scenarios. CustomerDetails "Updating" scenarios are handled inside formik.
		if (!isFetched || isFetching || isError) {
			return (
				<div className='CustomerDetailsPage GC-DP-DashboardPage'>
					<PageLoader
						isFetching={!isFetched || isFetching}
						isError={isError}
						loadingMsg='Please wait while we are fetching your details.'
						errorMsg={isError}
						onErrorTryAgainFn={this.handleFetchCustomerDetails}
					/>
				</div>
			);
		}

		// Default props and styles for Input Elements depending on "Editable" State Or Not.
		const commonMaterialUI__InputProps = {
			fullWidth: true,
			disabled: !isEditable,
			className: isEditable
				? 'GC-DP-FormikField--editable'
				: 'GC-DP-FormikField--nonEditable',
			placeholder: isEditable ? '' : '-',
		};

		return (
			<div className='CustomerDetailsPage GC-DP-DashboardPage'>
				<div>
					<div className='CustomerDetailsPage__header GC-DP-DashboardPageHeader'>
						<h1>ACCOUNT DETAILS</h1>
					</div>

					<div className='CustomerDetailsPage__body GC-DP-DashboardPageBody'>
						<Formik
							initialStatus={{}}
							initialValues={{
								...CustomerDetailsPageInitialValues,
								...customerDetails,
							}}
							validationSchema={CustomerDetailsPageValidationSchema}
							onSubmit={async (formValues, { setSubmitting, resetForm }) => {
								await this.handleUpdateCustomerDetails({
									formValues,
									setSubmitting,
									resetForm,
								});
							}}
							component={(formikProps) => {
								const { isSubmitting, resetForm } = formikProps;

								return (
									<div className='CustomerDetailsPage__body__formikContainer'>
										<div className='CustomerDetailsPage__body__formikContainer__header'>
											<div className='CustomerDetailsPage__body__formikContainer__header__options'>
												<FormControlLabel
													control={
														<Switch
															checked={isEditable}
															onChange={() => {
																// Resetting Form with Current "customerDetails" values.
																resetForm({
																	values: customerDetails,
																});
																this.setState({
																	isEditable: !isEditable,
																});
															}}
															name='editDetails'
														/>
													}
													label='Edit Details'
												/>

												{isEditable && (
													<Button
														type='submit'
														variant='contained'
														color='secondary'
														disabled={isSubmitting}
														onClick={formikProps.handleSubmit}>
														<div>UPDATE</div>
														<div>
															&nbsp;&nbsp;
															{isSubmitting && <CircularProgress size={20} />}
														</div>
													</Button>
												)}
											</div>
										</div>

										<div className='CustomerDetailsPage__body__formikContainer__form'>
											<CustomerDetailsPageFormControls
												formikProps={formikProps}
												customerDetailsState={this.state}
												orgCustomerDetails={customerDetails}
												commonMaterialUI__InputProps={
													commonMaterialUI__InputProps
												}
											/>
										</div>
									</div>
								);
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userState: state.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundUserActions = bindActionCreators(userActions, dispatch);

	return {
		userActions: boundUserActions,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CustomerDetailsPage);
