import {
	InputAdornment,
	CircularProgress,
	FormHelperText,
} from '@material-ui/core';
import { VpnKey as VpnKeyIcon } from '@material-ui/icons';
import React from 'react';

import FormikTextField from '../../../../components/widgets/formikInputs/materialUI/FormikTextField/FormikTextField';
import './SignupEmailConfFormControls.css';

export default function SignupEmailConfFormControls(props) {
	const {
		// ***** Props Passdown From Formik ***** (Such as errors, touched, getFieldProps, etc...)
		handleSubmit,
		isSubmitting,
		status, // Manually setted values by us. In our case used to set any API errors occurred while submitting the form.

		// **** Custom Props Manually Passed By Us *****
		email,
		setCurrentFormStep,
		commonMaterialUI__InputProps,
	} = props;

	return (
		<form
			className='SignupEmailConfFormControls'
			onSubmit={handleSubmit}
			noValidate // To disable HTLML5 builtin validations.
		>
			<div className='SignupEmailConfFormControls__header'>
				<p>
					Please enter the Email Verification Code sent to
					<span> {email}</span> here. Is this not your email? &nbsp;
					<a
						href='#'
						onClick={() => {
							setCurrentFormStep(0);
						}}>
						Change Email
					</a>
				</p>
			</div>

			<div className='SignupEmailConfFormControls__inputs'>
				<FormikTextField
					name='EmailConfirmationCode'
					materialUIProps={{
						...commonMaterialUI__InputProps,
						required: true,
						placeholder: 'Email Confirmation Code*',
						InputProps: {
							startAdornment: (
								<InputAdornment position='start'>
									<VpnKeyIcon color='disabled' />{' '}
								</InputAdornment>
							),
						},
					}}
				/>
			</div>

			<div className='SignupEmailConfFormControls__footer'>
				<FormHelperText error filled>
					{status.error}
				</FormHelperText>

				<button
					type='submit'
					className='SignupEmailConfFormControls__footer__signupButton'
					disabled={isSubmitting}>
					<div>VALIDATE VERIFICATION CODE</div>
					<div className='SignupEmailConfFormControls__footer__signupButton__progress'>
						{isSubmitting && <CircularProgress size={20} />}
					</div>
				</button>
			</div>
		</form>
	);
}
