/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/sort-comp */
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import CustomerCheckInItemCard from '../../../../../components/pageSections/dashboardPage/common/CustomerCheckInItemCard/CustomerCheckInItemCard';
import DashboardSubInfoSection from '../../../../../components/pageSections/dashboardPage/common/DashboardSubInfoSection/DashboardSubInfoSection';
import PageLoader from '../../../../../components/widgets/loaders/PageLoader/PageLoader';
import { reqStatusTypes } from '../../../../../configs/constants';
import { sectionName as customerDashboardSectionName } from '../../../../../redux/slices/customerDashboard/customerDashboardConstants';
import { actions as myCheckInsPageSliceActions } from '../../../../../redux/slices/customerDashboard/myCheckInsPageSlice/myCheckInsPageSlice';
import { getLoggedUserDetails } from '../../../../../utilities/userAuthentication';

import './MyCheckInsPage.css';

class MyCheckInsPage extends Component {
	async handleGetCheckInDocuments() {
		const { myCheckInsPageSliceActions } = this.props;

		const {
			getCheckInDocuments: getCheckInDocumentsAction,
		} = myCheckInsPageSliceActions;

		const { userid } = getLoggedUserDetails();

		return await getCheckInDocumentsAction({
			reqData: { CustomerID: userid, limit: 50 },
		});
	}

	async fetchNecessaryInitialPageData() {
		await this.handleGetCheckInDocuments();
	}

	componentDidMount() {
		this.fetchNecessaryInitialPageData();
	}

	render() {
		const { myCheckInsPageSliceState } = this.props;

		const {
			getCheckInDocumentsReqStatus,
			getCheckInDocumentsReqError,
			myCheckInDocuments,
		} = myCheckInsPageSliceState;

		// ***** Multiple custom dynamic constants for easy usage. ******

		// Used to show a Loader to indicate some main async functionalities are in process.(Like fetchings started on ComponentDidMount).
		const isMainPageActionsRunning =
			getCheckInDocumentsReqStatus === reqStatusTypes.pending;

		const isMainPageActionsError = getCheckInDocumentsReqError;

		// Indicating Main Page Data Loading.
		if (isMainPageActionsRunning || isMainPageActionsError) {
			return (
				<div className='MyCheckInsPage GC-DP-DashboardPage'>
					<PageLoader
						isFetching={isMainPageActionsRunning}
						loadingMsg='Please wait while we are fetching the details.'
						isError={isMainPageActionsError}
						errorMsg={isMainPageActionsError}
						onErrorTryAgainFn={() => this.fetchNecessaryInitialPageData()}
						shouldHideWhenEmpty
					/>
				</div>
			);
		}

		return (
			<div className='MyCheckInsPage GC-DP-DashboardPage'>
				<div>
					<div className='MyCheckInsPage__header GC-DP-DashboardPageHeader'>
						<h1>MY CHECK INS</h1>
					</div>

					<div className='MyCheckInsPage__body GC-DP-DashboardPageBody'>
						<div className='MyCheckInsPage__body__checkIns'>
							{/* When User Don't Have any CheckIns */}
							{myCheckInDocuments.length === 0 && (
								<DashboardSubInfoSection
									className='MyCheckInsPage__body__checkIns__checkInItems--noItems'
									type='NOITEMS'
									description='Currently, No CheckIns Available For You.'
								/>
							)}

							{/* When User Have CheckIns */}
							{myCheckInDocuments.length > 0 && (
								<div className='MyCheckInsPage__body__checkIns__checkInItems'>
									{myCheckInDocuments
										.slice()
										.sort((a, b) => {
											return new Date(b.CreatedDate) - new Date(a.CreatedDate);
										})
										.map((checkInDoc, index) => {
											return (
												<CustomerCheckInItemCard
													key={index}
													checkInValues={checkInDoc}
													showUserDetails={false}
												/>
											);
										})}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		myCheckInsPageSliceState:
			state[customerDashboardSectionName].myCheckInsPage,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundMyCheckInsPageSliceActions = bindActionCreators(
		myCheckInsPageSliceActions,
		dispatch,
	);
	return {
		myCheckInsPageSliceActions: boundMyCheckInsPageSliceActions,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCheckInsPage);
