import { reqStatusTypes } from '../../../../configs/constants';
import { sectionName } from '../businessDashboardConstants';

const sliceName = 'loyaltySchemeSettingsPage';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,
	initialState: {
		// ******************************* getGlobalLoyaltySchemeSettings() *******************************
		getGlobalLoyaltySchemeSettingsReqStatus: reqStatusTypes.idle,
		getGlobalLoyaltySchemeSettingsReqError: '',
		getGlobalLoyaltySchemeSettingsReqLastUpdated: '',
		globalSchemeSettings: {},

		// ******************************* getLoyaltySchemeSettingsByLocationId() *******************************
		getLoyaltySchemeSettingsByLocationIdReqStatus: reqStatusTypes.idle,
		getLoyaltySchemeSettingsByLocationIdReqError: '',
		currentSelectedLocationsExistingSettings: {},
	},
};

export default sliceConstants;
