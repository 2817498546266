import globalValues from '../configs/globalValues';

// Adding custom console log to add some Development Only Features.
export const overrideConsoleLog = () => {
	console.customLog = (...params) => {
		const firstParam = params[0];

		const isDevelopment = globalValues.environment.IS_DEVELOPMENT;

		if (firstParam === 'DEV') {
			if (isDevelopment) {
				console.log(...params.slice(1));
			}
		} else {
			console.log(...params);
		}
	};
};

// Simple helper utility function to convert string numbers into numeric values.
export const transformStringValueToNumber = (value) => {
	const numericValue = Number(value);

	if (Number.isNaN(numericValue)) {
		return value;
	}
	return numericValue;
};
