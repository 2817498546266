import {
	CircularProgress,
	FormHelperText,
	FormLabel,
	Button,
} from '@material-ui/core';
import {
	Add as AddIcon,
	StarBorder as StarBorderIcon,
	Star as StarIcon,
} from '@material-ui/icons';
import React from 'react';
import ReactRating from 'react-rating';

import FormikTextField from '../../../../../../widgets/formikInputs/materialUI/FormikTextField/FormikTextField';

import './AddReviewModalFormControls.css';

export default function AddReviewModalFormControls(props) {
	const { formikProps, commonMaterialUI__InputProps } = props;

	const {
		handleSubmit,
		isSubmitting,
		status, // Manually setted values by us. In our case used to set any API errors occurred while submitting the form.
		values,
		touched,
		errors,
		setFieldValue,
	} = formikProps;

	return (
		<form
			className='AddReviewModalFormControls'
			onSubmit={handleSubmit}
			noValidate // To disable HTLML5 builtin validations.
		>
			<div className='AddReviewModalFormControls__inputs'>
				<div>
					<FormLabel>Review* :</FormLabel>
					<FormikTextField
						name='Review'
						materialUIProps={{
							...commonMaterialUI__InputProps,
							required: true,
							multiline: true,
							rows: 5,
							variant: 'outlined',
							placeholder: 'Enter your review here.',
						}}
					/>
				</div>

				<div className='AddReviewModalFormControls__inputs__starValue'>
					<FormLabel>Star* :</FormLabel>

					<div>
						<ReactRating
							emptySymbol={<StarBorderIcon />}
							fullSymbol={<StarIcon />}
							initialRating={values.Star}
							onClick={(selectedStarValue) => {
								setFieldValue(`Star`, selectedStarValue);
							}}
						/>
						<FormHelperText error filled>
							{touched.Star && errors.Star}
						</FormHelperText>
					</div>
				</div>
			</div>

			<div className='AddReviewModalFormControls__footer'>
				<FormHelperText error filled>
					{status.error}
				</FormHelperText>

				<div className='AddReviewModalFormControls__footer__buttons'>
					<Button
						type='submit'
						variant='contained'
						color='primary'
						endIcon={<AddIcon />}
						disabled={isSubmitting}
						onClick={() => {}}>
						<div>
							{isSubmitting && <CircularProgress size={20} />}
							&nbsp;&nbsp;
						</div>
						ADD
					</Button>
				</div>
			</div>
		</form>
	);
}
