const {
	REACT_APP__WEBSITE__BASEURL,

	REACT_APP__WITMEG_APISERVER___PRIVATE_KEY,
	REACT_APP__WITMEG_APISERVER___ISSUER,
	REACT_APP__WITMEG_APISERVER___AUDIENCE,
	REACT_APP__WITMEG_APISERVER___BASEURL,

	REACT_APP__WITMEG_LOYALTYSERVER___PRIVATE_KEY,
	REACT_APP__WITMEG_LOYALTYSERVER___ISSUER,
	REACT_APP__WITMEG_LOYALTYSERVER___AUDIENCE,
	REACT_APP__WITMEG_LOYALTYSERVER___BASEURL,

	REACT_APP__WITMEG_EMAILSERVER___PRIVATE_KEY,
	REACT_APP__WITMEG_EMAILSERVER___ISSUER,
	REACT_APP__WITMEG_EMAILSERVER___AUDIENCE,
	REACT_APP__WITMEG_EMAILSERVER___BASEURL,
	REACT_APP__WITMEG_EMAILSERVER___DEFAULT_EMAIL_FROM_NAME,
	REACT_APP__WITMEG_EMAILSERVER___DEFAULT_EMAIL_FROM_ADDRESS,

	NODE_ENV,
} = process.env;

const globalValues = {
	credentials: {
		WITMEG_APISERVER___PRIVATE_KEY: REACT_APP__WITMEG_APISERVER___PRIVATE_KEY,
		WITMEG_APISERVER___ISSUER: REACT_APP__WITMEG_APISERVER___ISSUER,
		WITMEG_APISERVER___AUDIENCE: REACT_APP__WITMEG_APISERVER___AUDIENCE,

		WITMEG_LOYALTYSERVER___PRIVATE_KEY: REACT_APP__WITMEG_LOYALTYSERVER___PRIVATE_KEY,
		WITMEG_LOYALTYSERVER___ISSUER: REACT_APP__WITMEG_LOYALTYSERVER___ISSUER,
		WITMEG_LOYALTYSERVER___AUDIENCE: REACT_APP__WITMEG_LOYALTYSERVER___AUDIENCE,

		WITMEG_EMAILSERVER___PRIVATE_KEY: REACT_APP__WITMEG_EMAILSERVER___PRIVATE_KEY,
		WITMEG_EMAILSERVER___ISSUER: REACT_APP__WITMEG_EMAILSERVER___ISSUER,
		WITMEG_EMAILSERVER___AUDIENCE: REACT_APP__WITMEG_EMAILSERVER___AUDIENCE,
		WITMEG_EMAILSERVER___DEFAULT_EMAIL_FROM_NAME: REACT_APP__WITMEG_EMAILSERVER___DEFAULT_EMAIL_FROM_NAME,
		WITMEG_EMAILSERVER___DEFAULT_EMAIL_FROM_ADDRESS: REACT_APP__WITMEG_EMAILSERVER___DEFAULT_EMAIL_FROM_ADDRESS,
	},

	baseURLS: {
		WEBSITE__BASEURL: REACT_APP__WEBSITE__BASEURL || window.location.origin,
		WITMEG_APISERVER___BASEURL: REACT_APP__WITMEG_APISERVER___BASEURL,
		WITMEG_LOYALTYSERVER___BASEURL: REACT_APP__WITMEG_LOYALTYSERVER___BASEURL,
		WITMEG_EMAILSERVER___BASEURL: REACT_APP__WITMEG_EMAILSERVER___BASEURL,
	},

	environment: {
		CURRENT_ENVIRNOMENT: NODE_ENV,
		IS_DEVELOPMENT: NODE_ENV === 'development',
	},
};

export default globalValues;

// *****************************************************************
// Simple function to notify if critical env values are not passed.
function checkAllCriticalEnvValuesAvailable() {
	const cricalEnvValueList = [
		REACT_APP__WITMEG_APISERVER___PRIVATE_KEY,
		REACT_APP__WITMEG_APISERVER___ISSUER,
		REACT_APP__WITMEG_APISERVER___AUDIENCE,
		REACT_APP__WITMEG_APISERVER___BASEURL,

		REACT_APP__WITMEG_LOYALTYSERVER___PRIVATE_KEY,
		REACT_APP__WITMEG_LOYALTYSERVER___ISSUER,
		REACT_APP__WITMEG_LOYALTYSERVER___AUDIENCE,
		REACT_APP__WITMEG_LOYALTYSERVER___BASEURL,

		REACT_APP__WITMEG_EMAILSERVER___PRIVATE_KEY,
		REACT_APP__WITMEG_EMAILSERVER___ISSUER,
		REACT_APP__WITMEG_EMAILSERVER___AUDIENCE,
		REACT_APP__WITMEG_EMAILSERVER___BASEURL,
	];

	const isAllCriticalEnvValuesAvailable = cricalEnvValueList.every(
		(envValue) => envValue,
	);

	if (!isAllCriticalEnvValuesAvailable) {
		console.error('NOTE : SOME CRITICAL ENV VALUES ARE MISSING');
	}
}
checkAllCriticalEnvValuesAvailable();
