/* eslint-disable promise/always-return */
import { Formik } from 'formik';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { checkUserAlreadyExist } from '../../../../utilities/apiRequests/witmegApiServerRequests';

import LinkUserToBusinessEmailEntryFormControls from './LinkUserToBusinessEmailEntryFormControls';

const LinkUserToBusinessEmailEntryFormValidationSchema = Yup.object().shape({
	Email: Yup.string().email('Invalid Email').required('Required'),
});

const LinkUserToBusinessEmailEntryFormInitialValues = {
	Email: '',
};

export default class LinkUserToBusinessEmailEntryForm extends Component {
	render() {
		const {
			commonMaterialUI__InputProps,
			redirectToLogin,
			redirectToSignup,
			businessName,
		} = this.props;

		return (
			<div className='LinkUserToBusinessEmailEntryForm'>
				<div>
					<Formik
						initialStatus={{}}
						initialValues={LinkUserToBusinessEmailEntryFormInitialValues}
						validationSchema={LinkUserToBusinessEmailEntryFormValidationSchema}
						onSubmit={async (...formikProps) => {
							const formValues = formikProps[0];
							const { setSubmitting, setStatus } = formikProps[1];

							try {
								const { Email } = formValues;

								await checkUserAlreadyExist({ Email })
									.then(() => {
										toast.info('Redirected to Login...');
										redirectToLogin({
											// These used in Login Page to pre-fill Email InputBox.
											customValues: {
												Email,
											},
										});
									})
									.catch((error) => {
										const errMsg = error.message;

										if (errMsg.includes('no documents in result')) {
											// When there is no account for provided email.
											toast.info('Redirected to Signup...');
											redirectToSignup({
												// These used in Signup Page to pre-fill Email InputBox.
												customValues: {
													Email,
												},
											});
										} else {
											throw error;
										}
									});

								/**
								 * Temporally disabled to avoid "Can't perform a React state update on an unmounted component." React Error. This occur because ReactRouter redirect before state updates.
								 * And more importantly, we don't need this currently because if process is success we do page redirects and no need of these updated state values.
								 */
								// setSubmitting(false);
								// setStatus({
								// 	error: '',
								// });
							} catch (error) {
								const customErrMsg = error.customErrMsg || 'Error Occurred';

								setSubmitting(false);
								setStatus({
									error: customErrMsg,
								});
							}
						}}
						component={(formikProps) => (
							<LinkUserToBusinessEmailEntryFormControls
								{...formikProps}
								commonMaterialUI__InputProps={commonMaterialUI__InputProps}
								businessName={businessName}
							/>
						)}
					/>
				</div>
			</div>
		);
	}
}
