import { DateTime } from 'luxon';

import { reqStatusTypes } from '../configs/constants';

export const handleAsyncThunkCancellation = (allOptions = {}) => {
	const {
		passedActionValues = {},
		reqStatusValue = '',
		reqLastUpdatedValue = '',
	} = allOptions;

	const { actionOptions = {} } = passedActionValues;
	const {
		forceFetch = false,
		useDurations = false,
		minimumRefetchDuration = 60 * 10, // Ten Minutes.
	} = actionOptions;

	const isAlreadySucceeded = reqStatusValue === reqStatusTypes.succeeded;
	const lastUpdatedTimeAsString = reqLastUpdatedValue;

	if (!isAlreadySucceeded || forceFetch || !useDurations) {
		return true;
	}

	const currentTime = DateTime.local();
	const LastUpdatedTime = DateTime.fromISO(lastUpdatedTimeAsString);
	const minimumDurationAsSeconds = minimumRefetchDuration;
	const elapsedDurationAsSeconds =
		currentTime.diff(LastUpdatedTime, 'seconds').toObject().seconds || 0;

	if (useDurations && elapsedDurationAsSeconds <= minimumDurationAsSeconds) {
		return false;
	}
	return true;
};
