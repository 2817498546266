/* eslint-disable no-param-reassign */
import { reqStatusTypes } from '../../../../configs/constants';

import {
	getReviewDocuments,
	authorizeReviewToBePost,
} from './customerReviewsPageSliceExtraActions';

const extraReducers = {
	// ******************************* getReviewDocuments() *******************************
	[getReviewDocuments.pending]: (state, action) => {
		state.getReviewDocumentsReqStatus = reqStatusTypes.pending;
		state.getReviewDocumentsReqError = '';
	},

	[getReviewDocuments.fulfilled]: (state, action) => {
		state.getReviewDocumentsReqStatus = reqStatusTypes.succeeded;
		state.currentLocationReviewDocuments = action.payload;
	},

	[getReviewDocuments.rejected]: (state, action) => {
		state.getReviewDocumentsReqStatus = reqStatusTypes.failed;
		state.getReviewDocumentsReqError = action.error.message;
		state.currentLocationReviewDocuments = [];
	},

	// ******************************* authorizeReviewToBePost() *******************************
	[authorizeReviewToBePost.pending]: (state, action) => {
		const actionArgs = action.meta.arg;
		const { LookupID } = actionArgs;

		state.authorizeReviewToBePostReqByReviewIds[LookupID] = {
			...state.authorizeReviewToBePostReqByReviewIds[LookupID],
			reqStatus: reqStatusTypes.pending,
			reqError: '',
		};
	},

	[authorizeReviewToBePost.fulfilled]: (state, action) => {
		const actionArgs = action.meta.arg;
		const { LookupID } = actionArgs;

		state.authorizeReviewToBePostReqByReviewIds[LookupID] = {
			...state.authorizeReviewToBePostReqByReviewIds[LookupID],
			reqStatus: reqStatusTypes.succeeded,
		};
	},

	[authorizeReviewToBePost.rejected]: (state, action) => {
		const actionArgs = action.meta.arg;
		const { LookupID } = actionArgs;

		state.authorizeReviewToBePostReqByReviewIds[LookupID] = {
			...state.authorizeReviewToBePostReqByReviewIds[LookupID],
			reqStatus: reqStatusTypes.failed,
			reqError: action.error.message,
		};
	},
};

export default extraReducers;
