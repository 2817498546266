/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';

import BaseModal from '../../../../widgets/modalWrappers/BaseModal/BaseModal';

import AddReviewModalForm from './forms/AddReviewModalForm/AddReviewModalForm';

import './AddReviewModal.css';

export default class AddReviewModal extends Component {
	render() {
		const { reviewRelatedDetails = {}, addReviewPostAction } = this.props;

		return (
			<div className='AddReviewModal'>
				<BaseModal
					className='AddReviewModal__BaseModal'
					customTitle='Add Review'
					customSubtitle='Please enter your honest review and star amount below.'
					{...this.props}>
					<div>
						<AddReviewModalForm
							reviewRelatedDetails={reviewRelatedDetails}
							addReviewPostAction={addReviewPostAction}
						/>
					</div>
				</BaseModal>
			</div>
		);
	}
}
