import { reqStatusTypes } from '../../../configs/constants';

const sliceConstants = {
	name: 'user',
	initialState: {
		// ******************************* getCustomerDetails() *******************************
		getCustomerDetailsReqStatus: reqStatusTypes.idle,
		getCustomerDetailsReqError: '',

		// ******************************* updateCustomerDetails() *******************************
		updateCustomerDetailsReqStatus: reqStatusTypes.idle,
		updateCustomerDetailsReqError: '',

		// ******************************* setLoadedLocationData() *******************************
		isLocationLinked: false,
		currentlyLinkedLocationDetails: {
			/*	EXAMPLE DATA STRUCTURE :

							Type(pin):'Loyalty'
							OrganizationID(pin):'5fe19e495fc97b30f8cba7f3'
							CompanyID(pin):'5fe19e4a5fc97b30f8cba7f4'
							LocationID(pin):'5fe19e4c5fc97b30f8cba7f5'
							CompanyName(pin):'Shen Res1'
							LocationName(pin):'Shen Res1'
							URLParameters(pin):'5fe19e4c5fc97b30f8cba7f5'
			*/
		},

		customerDetails: {},
	},
};

export default sliceConstants;
