import { TextField } from '@material-ui/core';
import { Field, getIn } from 'formik';

// NOTE : This component should only be used with <Formik> container.

export default function FormikTextField(props) {
	const {
		name = '',
		children,
		materialUIProps = {},
		otherProps = {},
		displayValueFn,
		transformValueFn,
	} = props;

	if (!name) return null;

	return (
		<Field name={name}>
			{(formikProps) => {
				const {
					field, // Contain { name, value, onChange, onBlur }
					form, // Also available values, setXXXX, handleXXXX, dirty, isValid, status, etc.
					meta,
				} = formikProps;

				const {
					touched,
					errors,
					getFieldProps, // Provide { name, value, onChange, onBlur }
					validateForm,
					setFieldValue,
				} = form;

				// If custom displayValueFn passed that fn is used to determine which value will be shown.
				const displayValue = displayValueFn
					? displayValueFn(field.value)
					: field.value;

				// If custom transformValueFn passed that fn is used to determine which value will be saved in formik.
				const onChangeHandler = transformValueFn
					? (e) => {
							const transformedValue = transformValueFn(e.target.value);
							setFieldValue(name, transformedValue);
					  }
					: field.onChange;

				return (
					<div>
						<TextField
							id={name}
							error={getIn(errors, name) && getIn(touched, name)}
							helperText={
								getIn(errors, name) && getIn(touched, name)
									? getIn(errors, name)
									: ''
							}
							{...getFieldProps(name)}
							{...materialUIProps}
							{...otherProps}
							value={displayValue}
							onChange={onChangeHandler}>
							{children}
						</TextField>
					</div>
				);
			}}
		</Field>
	);
}
