import { Formik } from 'formik';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { passwordResetRequest } from '../../../../utilities/apiRequests/witmegApiServerRequests';
import { sendEmail } from '../../../../utilities/apiRequests/witmegEmailServerRequests';
import { generatePasswordResetLinkEmail } from '../../../../utilities/email/emailTemplates/PasswordResetLinkEmailTemplate';

import PasswordResetRequestFormControls from './PasswordResetRequestFormControls';

const PasswordResetRequestFormValidationSchema = Yup.object().shape({
	UserEmail: Yup.string().email('Invalid Email/Username').required('Required'),
});

const PasswordResetRequestFormInitialValues = {
	UserEmail: '',
};
export default class PasswordResetRequestForm extends Component {
	render() {
		const { history: routerHistory, commonMaterialUI__InputProps } = this.props;

		return (
			<div className='PasswordResetRequestForm'>
				<div>
					<Formik
						initialStatus={{}}
						initialValues={PasswordResetRequestFormInitialValues}
						validationSchema={PasswordResetRequestFormValidationSchema}
						onSubmit={async (formValues, { setSubmitting, setStatus }) => {
							try {
								const apiReqBody = {
									UserEmail: formValues.UserEmail,
									OrganizationID: 'ecologital',
								};

								const passwordResetURL = await passwordResetRequest(apiReqBody);

								// Sending User a ResetLink Email.
								await sendEmail({
									To: formValues.UserEmail,
									Subject: 'WitMeg Loyalty - Reset Link',
									HTMLContent: generatePasswordResetLinkEmail({
										email: formValues.UserEmail,
										passwordResetLink: passwordResetURL,
									}),
								});

								toast.info(
									'Password reset link sent to your email. Redirected to login.',
								);
								setTimeout(() => {
									setSubmitting(false);
									routerHistory.push('/login');
								});
								setStatus({
									error: '',
								});
								setSubmitting(false);
							} catch (error) {
								const errMsg = error.customErrMsg || error.message;

								setStatus({
									error: errMsg,
								});
								setSubmitting(false);
							}
						}}
						component={(formikProps) => (
							<PasswordResetRequestFormControls
								{...formikProps}
								commonMaterialUI__InputProps={commonMaterialUI__InputProps}
							/>
						)}
					/>
				</div>
			</div>
		);
	}
}
