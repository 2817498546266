/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/sort-comp */
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import CustomerLoyaltyAwardsItemCard from '../../../../../components/pageSections/dashboardPage/common/CustomerLoyaltyAwardsItemCard/CustomerLoyaltyAwardsItemCard';
import DashboardSubInfoSection from '../../../../../components/pageSections/dashboardPage/common/DashboardSubInfoSection/DashboardSubInfoSection';
import PageLoader from '../../../../../components/widgets/loaders/PageLoader/PageLoader';
import { reqStatusTypes } from '../../../../../configs/constants';
import { sectionName as customerDashboardSectionName } from '../../../../../redux/slices/customerDashboard/customerDashboardConstants';
import { actions as myLoyaltyAwardsPageSliceActions } from '../../../../../redux/slices/customerDashboard/myLoyaltyAwardsPageSlice/myLoyaltyAwardsPageSlice';
import { getLoggedUserDetails } from '../../../../../utilities/userAuthentication';

import './MyLoyaltyAwardsPage.css';

class MyLoyaltyAwardsPage extends Component {
	async handleGetLoyaltyAwardsDocuments() {
		const { myLoyaltyAwardsPageSliceActions } = this.props;

		const {
			getLoyaltyAwardsDocuments: getLoyaltyAwardsDocumentsAction,
		} = myLoyaltyAwardsPageSliceActions;

		const { userid } = getLoggedUserDetails();

		return await getLoyaltyAwardsDocumentsAction({
			reqData: { CustomerID: userid, limit: 50 },
		});
	}

	async fetchNecessaryInitialPageData() {
		await this.handleGetLoyaltyAwardsDocuments();
	}

	componentDidMount() {
		this.fetchNecessaryInitialPageData();
	}

	render() {
		const { myLoyaltyAwardsPageSliceState } = this.props;

		const {
			getLoyaltyAwardsDocumentsReqStatus,
			getLoyaltyAwardsDocumentsReqError,
			myLoyaltyAwardsDocuments,
		} = myLoyaltyAwardsPageSliceState;

		// ***** Multiple custom dynamic constants for easy usage. ******

		// Used to show a Loader to indicate some main async functionalities are in process.(Like fetchings started on ComponentDidMount).
		const isMainPageActionsRunning =
			getLoyaltyAwardsDocumentsReqStatus === reqStatusTypes.pending;

		const isMainPageActionsError = getLoyaltyAwardsDocumentsReqError;

		// Indicating Main Page Data Loading.
		if (isMainPageActionsRunning || isMainPageActionsError) {
			return (
				<div className='MyLoyaltyAwardsPage GC-DP-DashboardPage'>
					<PageLoader
						isFetching={isMainPageActionsRunning}
						loadingMsg='Please wait while we are fetching the details.'
						isError={isMainPageActionsError}
						errorMsg={isMainPageActionsError}
						onErrorTryAgainFn={() => this.fetchNecessaryInitialPageData()}
						shouldHideWhenEmpty
					/>
				</div>
			);
		}

		return (
			<div className='MyLoyaltyAwardsPage GC-DP-DashboardPage'>
				<div>
					<div className='MyLoyaltyAwardsPage__header GC-DP-DashboardPageHeader'>
						<h1>MY LOYALTY AWARDS</h1>
					</div>

					<div className='MyLoyaltyAwardsPage__body GC-DP-DashboardPageBody'>
						<div className='MyLoyaltyAwardsPage__body__loyaltyAwards'>
							{/* When User Don't Have any LoyaltyAwards */}
							{myLoyaltyAwardsDocuments.length === 0 && (
								<DashboardSubInfoSection
									className='MyLoyaltyAwardsPage__body__loyaltyAwards__awardItems--noItems'
									type='NOITEMS'
									description='Currently, No Loyalty Awards Available For You.'
								/>
							)}

							{/* When User Have Loyalty Awards */}
							{myLoyaltyAwardsDocuments.length > 0 && (
								<div className='MyLoyaltyAwardsPage__body__loyaltyAwards__awardItems'>
									{myLoyaltyAwardsDocuments
										.slice()
										.sort((a, b) => {
											return new Date(b.CreatedDate) - new Date(a.CreatedDate);
										})
										.map((checkInDoc, index) => {
											return (
												<CustomerLoyaltyAwardsItemCard
													key={index}
													loyaltyAwardValues={checkInDoc}
													showUserDetails={false}
												/>
											);
										})}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		myLoyaltyAwardsPageSliceState:
			state[customerDashboardSectionName].myLoyaltyAwardsPage,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundMyLoyaltyAwardsPageSliceActions = bindActionCreators(
		myLoyaltyAwardsPageSliceActions,
		dispatch,
	);
	return {
		myLoyaltyAwardsPageSliceActions: boundMyLoyaltyAwardsPageSliceActions,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(MyLoyaltyAwardsPage);
