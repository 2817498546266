/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
import { Button, Icon } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import PageLoader from '../../components/widgets/loaders/PageLoader/PageLoader';
import { actions as userSliceActions } from '../../redux/slices/userSlice/userSlice';
import {
	fetchCustomerDetails,
	decryptLocationId,
} from '../../utilities/apiRequests/witmegApiServerRequests';
import {
	addLoyaltyCustomerToBusiness,
	checkLoyaltyCustomerAlreadyExistInBusiness,
} from '../../utilities/apiRequests/witmegLoyaltyServerRequests';
import {
	isUserLoggedIn,
	checkIsBusinessUser,
	getLoggedUserDetails,
} from '../../utilities/userAuthentication';

import LinkUserToBusinessEmailEntryForm from './forms/LinkUserToBusinessEmailEntryForm/LinkUserToBusinessEmailEntryForm';

import './LinkUserToBusinessPage.css';

class LinkUserToBusinessPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			// States involved in process of "Decrypting Parsed Original LocationID from URL Params"
			decryptedLocationDetails: {},
			isUserAlreadyExistInRequestedBusiness: false,
			isDecrypting: false,
			isDecryptingError: '',

			// States involved in process of "Linking User To Business"
			isUpdating: false,
			isUpdatingError: '',
		};

		this.handleDecryptingLocationId = this.handleDecryptingLocationId.bind(
			this,
		);
		this.handleAddCustomerToBusiness = this.handleAddCustomerToBusiness.bind(
			this,
		);
		this.redirectToLogin = this.redirectToLogin.bind(this);
		this.redirectToSignup = this.redirectToSignup.bind(this);
	}

	async handleDecryptingLocationId() {
		try {
			const {
				match: reactRouterMatch,
				history: reactRouterHistory,
				userSliceActions,
			} = this.props;

			const {
				setLinkedLocationData: setLinkedLocationDataAction,
			} = userSliceActions;

			const { params: reactRouterParams } = reactRouterMatch;
			const { orgId } = reactRouterParams;

			const userLogged = isUserLoggedIn();
			const loggedUserDetails = getLoggedUserDetails();
			const { Email } = loggedUserDetails;

			// Indicating Decrypting Process.
			this.setState({
				decryptedLocationDetails: {},
				isUserAlreadyExistInRequestedBusiness: false,
				isDecrypting: true,
				isDecryptingError: false,
			});

			const res = await decryptLocationId({
				ProcessString: orgId,
			}).catch((error) => {
				if (error.message.includes('Error in decrypting url')) {
					error.customErrMsg =
						'This seems to be invalid Business Linking URL. Please check the link and try again.';
				}

				throw error;
			});

			// Checking current logged user already available in requested Business.
			const alreadyExist =
				userLogged &&
				(await checkLoyaltyCustomerAlreadyExistInBusiness({
					Email,
					LocationID: res.LocationID,
				})
					.then((r) => {
						if (r.includes('Customer already exist')) {
							return true;
						}
						return false;
					})
					.catch((error) => {
						throw error;
					}));

			if (alreadyExist) {
				// IMPORTANT NOTE : For now we are directly redirecting to 'dashboard' if user already a member of requested Business.
				// 									So in that case other below codes may note be executed/mattered.
				toast.success('Location Linked Successfully.');
				setLinkedLocationDataAction({
					isLocationLinked: true,
					linkedLocationDetails: res,
				});
				return reactRouterHistory.push('/dashboard');
			}

			this.setState({
				decryptedLocationDetails: res,
				isUserAlreadyExistInRequestedBusiness: alreadyExist,
				isDecrypting: false,
				isDecryptingError: false,
			});
		} catch (error) {
			const errMsg = error.customErrMsg || 'Error Occurred';

			this.setState({
				decryptedLocationDetails: {},
				isUserAlreadyExistInRequestedBusiness: false,
				isDecrypting: false,
				isDecryptingError: errMsg,
			});
		}
	}

	async handleAddCustomerToBusiness() {
		try {
			const { decryptedLocationDetails } = this.state;

			const { history: reactRouterHistory, userSliceActions } = this.props;
			const {
				setLinkedLocationData: setLinkedLocationDataAction,
			} = userSliceActions;

			const userLogged = isUserLoggedIn();
			const loggedUserDetails = getLoggedUserDetails();
			const { Email, userid: loggedUserId } = loggedUserDetails;

			// Indicating Update Process.
			this.setState({
				isUpdating: true,
				isUpdatingError: false,
			});

			// Make sure updating only happen if user is logged.
			if (!userLogged) {
				return;
			}

			const customerDetails = await fetchCustomerDetails({ Email });

			const addUserToBusinessReqBody = {
				FirstName: customerDetails.FirstName,
				LastName: customerDetails.LastName,
				Email: customerDetails.Email,
				TelephoneNumber:
					customerDetails.Addresses && customerDetails.Addresses[0]
						? customerDetails.Addresses[0].Phone
						: '',
				MobileNumber:
					customerDetails.Addresses && customerDetails.Addresses[0]
						? customerDetails.Addresses[0].MobilePhone
						: '',
				Currency: 'GBP',
				Addresses: customerDetails.Addresses,

				CustomerID: loggedUserId,
				LocationID: decryptedLocationDetails.LocationID,
				CompanyID: decryptedLocationDetails.CompanyID,
				OrganizationID: decryptedLocationDetails.OrganizationID,

				LocationName: decryptedLocationDetails.LocationName,
				CompanyName: decryptedLocationDetails.CompanyName,
			};

			await addLoyaltyCustomerToBusiness(addUserToBusinessReqBody)
				.then(() => {
					// Setting Linked Location Details in Redux State.
					setLinkedLocationDataAction({
						isLocationLinked: true,
						linkedLocationDetails: decryptedLocationDetails,
					});

					toast.success('You have successfully added to the business.');
					return reactRouterHistory.push('/dashboard');
				})
				.catch((error) => {
					const errMsg = error.message;

					if (errMsg.includes('Customer already exist')) {
						// This clause is added to avoid/override displaying error, when this user already available in this business.
						toast.success('You already exist in this business.');
						return reactRouterHistory.push('/dashboard');
					}
					throw error;
				});

			/**
			 * Temporally disabled to avoid "Can't perform a React state update on an unmounted component." React Error. This occur because ReactRouter redirect before state updates.
			 * And more importantly, we don't need this currently because if process is success its get redirected and no need of these updated state values.
			 */
			// this.setState({
			// 	isUpdating: false,
			// 	isUpdatingError: '',
			// });
		} catch (error) {
			const errMsg = error.customErrMsg || 'Error Occurred.';

			this.setState({
				isUpdating: false,
				isUpdatingError: errMsg,
			});
		}
	}

	redirectToLogin(options = {}) {
		const { match: reactRouterMatch, history: reactRouterHistory } = this.props;
		const { url: userLinkingPath } = reactRouterMatch;

		const { customValues = {} } = options;

		reactRouterHistory.push({
			pathname: '/login',
			state: {
				shouldRedirect: true,
				redirectPath: userLinkingPath,
				customValues,
			},
		});
	}

	redirectToSignup(options = {}) {
		const { match: reactRouterMatch, history: reactRouterHistory } = this.props;
		const { url: userLinkingPath } = reactRouterMatch;

		const { customValues = {} } = options;

		reactRouterHistory.push({
			pathname: '/signup',
			state: {
				shouldRedirect: true,
				redirectPath: userLinkingPath,
				customValues,
			},
		});
	}

	componentDidMount() {
		this.handleDecryptingLocationId();
	}

	render() {
		const {
			isDecrypting,
			isDecryptingError,
			isUserAlreadyExistInRequestedBusiness,
			isUpdating,
			isUpdatingError,
			decryptedLocationDetails,
		} = this.state;

		const {
			history: reactRouterHistory,
			width: currentPageBreakpointSize,
		} = this.props;

		const userLogged = isUserLoggedIn();
		const isBusinessUser = checkIsBusinessUser();

		// Style for handling MaterialUI Inputs in small breakpoints.
		const formInputSize =
			currentPageBreakpointSize === 'xs' ? 'small' : 'medium';
		const commonMaterialUI__InputProps = {
			fullWidth: true,
			variant: 'outlined',
			size: formInputSize,
		};

		// This is used to hide "EmailEntry Form & Confirmation Buttons" when LocationID Decrypting happening.
		const shouldDisplayLocationLinkingContent =
			!isDecrypting && !isDecryptingError;

		const businessName = `${decryptedLocationDetails.CompanyName} - ${decryptedLocationDetails.LocationName}`;

		return (
			<div className='LinkUserToBusinessPage'>
				<div className='LinkUserToBusinessPage__container'>
					<div className='LinkUserToBusinessPage__header'>
						<img
							src='https://cdn.neurolage.com/ecologital/witmeg_logo.png'
							alt=''
						/>
						<h1>Linking Customer With Business</h1>
					</div>

					<div className='LinkUserToBusinessPage__body'>
						{/* This is the loader we show when decrypting LocationID happens. */}
						<div className='LinkUserToBusinessPage__body__mainPageLoader'>
							<PageLoader
								isFetching={isDecrypting}
								loadingMsg='Please wait while we process your requested page.'
								isError={isDecryptingError}
								errorMsg={isDecryptingError}
								shouldHideWhenEmpty
							/>
						</div>

						{/* This is only shown when URL decrypting is successful. */}
						<div
							style={{
								display: shouldDisplayLocationLinkingContent ? 'block' : 'none',
							}}>
							<div className='LinkUserToBusinessPage__body__title'>
								{/* Sub title that need appear on all forms goes here  */}
							</div>

							{!userLogged && !isUserAlreadyExistInRequestedBusiness && (
								<div className='LinkUserToBusinessPage__body__emailEntryForm'>
									<LinkUserToBusinessEmailEntryForm
										businessName={businessName}
										commonMaterialUI__InputProps={commonMaterialUI__InputProps}
										redirectToLogin={this.redirectToLogin}
										redirectToSignup={this.redirectToSignup}
									/>
								</div>
							)}

							{/*
								IMPORTANT NOTE : For now, we are directly redirecting to 'dashboard', if user already a member of requested Business. (On handleDecryptingLocationId)
								So in that case below won't be shown to user.
							*/}
							{userLogged && isUserAlreadyExistInRequestedBusiness && (
								<div className='LinkUserToBusinessPage__body__userAlreadyExist'>
									<div>
										You are already a user of this Business.
										<strong> ({businessName})</strong>. No need to add again.
										<br />
									</div>
									<br />
									<Button
										variant='contained'
										color='secondary'
										endIcon={<Icon>reply</Icon>}
										onClick={() => {
											return reactRouterHistory.push('/dashboard');
										}}>
										GOTO DASHBOARD
									</Button>
								</div>
							)}

							{userLogged &&
								isBusinessUser &&
								!isUserAlreadyExistInRequestedBusiness && (
									<div className='LinkUserToBusinessPage__body__businessUser'>
										<div>
											Sorry. You are a Business User. <br />
											This feature only available for Normal Users.
										</div>
										<br />
										<Button
											variant='contained'
											color='secondary'
											endIcon={<Icon>reply</Icon>}
											onClick={() => {
												reactRouterHistory.goBack();
											}}>
											GO BACK
										</Button>
									</div>
								)}

							{userLogged &&
								!isBusinessUser &&
								!isUserAlreadyExistInRequestedBusiness && (
									<div className='LinkUserToBusinessPage__body__reqPermission'>
										<div className='LinkUserToBusinessPage__body__reqPermission__loader'>
											<PageLoader
												isFetching={isUpdating}
												loadingMsg=''
												isError={isUpdatingError}
												errorMsg={isUpdatingError}
												// onErrorTryAgainFn={() => this.handleAddCustomerToBusiness()}
											/>
										</div>

										<div>
											<p style={{ textAlign: 'center' }}>
												We are linking you with <strong>{businessName}</strong>.
											</p>
										</div>

										<div className='LinkUserToBusinessPage__body__reqPermission__buttons'>
											<Button
												variant='contained'
												color='secondary'
												endIcon={<Icon>close</Icon>}
												disabled={!!isUpdating}
												onClick={() => {
													reactRouterHistory.push('/dashboard');
												}}>
												CANCEL
											</Button>
											<Button
												variant='contained'
												color='primary'
												endIcon={<Icon>add</Icon>}
												disabled={!!isUpdating}
												onClick={() => {
													this.handleAddCustomerToBusiness();
												}}>
												LINK
											</Button>
										</div>
									</div>
								)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		userSliceState: state.user,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundUserSliceActions = bindActionCreators(userSliceActions, dispatch);
	return {
		userSliceActions: boundUserSliceActions,
	};
};

const LinkUserToBusinessPageWithOtherHOC = withRouter(
	withWidth()(LinkUserToBusinessPage),
);

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(LinkUserToBusinessPageWithOtherHOC);
