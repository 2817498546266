/* eslint-disable no-param-reassign */
import { reqStatusTypes } from '../../../../configs/constants';

const standardReducers = {
	resetAllMyLoyaltyPointsPageSliceRequests: (state, action) => {
		// ******************************* getLoyaltyPointsForAllLocationsByCustomerId() *******************************
		state.getLoyaltyPointsForAllLocationsByCustomerIdReqStatus =
			reqStatusTypes.idle;
		state.getLoyaltyPointsForAllLocationsByCustomerIdReqError = '';
	},
};

export default standardReducers;
