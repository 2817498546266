import { unwrapResult } from '@reduxjs/toolkit';
import { Formik } from 'formik';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import AddReviewModalFormControls from './AddReviewModalFormControls';

import './AddReviewModalForm.css';

const AddReviewModalFormValidationSchema = Yup.object().shape({
	Review: Yup.string().min(3, 'Too Short.').required('Required'),
	Star: Yup.number()
		.integer('Must Select A Star Value.')
		.min(1, 'Must Select A Star Value')
		.required('Required'),
});

const AddReviewModalFormInitialValues = {
	Review: '',
	Star: 0,
};

// Default props and styles for Input Elements depending on custom logic.
const commonMaterialUI__InputProps = {
	fullWidth: true,
};

export default class AddReviewModalForm extends Component {
	render() {
		const { reviewRelatedDetails, addReviewPostAction } = this.props;
		const { ReviewType, CompanyName, LocationName } = reviewRelatedDetails;

		return (
			<div className='AddReviewModalForm'>
				<div className='AddReviewModalForm__header'>
					Adding {ReviewType} Type Review To {CompanyName} - {LocationName}
				</div>

				<Formik
					initialStatus={{}}
					initialValues={AddReviewModalFormInitialValues}
					validationSchema={AddReviewModalFormValidationSchema}
					onSubmit={async (formValues, { setSubmitting }) => {
						addReviewPostAction({
							reqBody: {
								...formValues,
								...reviewRelatedDetails,
							},
						})
							.then(unwrapResult)
							.then(() => {
								return toast.success('Review Successfully Added');
							})
							.catch(() => {
								return toast.error('Review Adding Failed.');
							});

						setSubmitting(false);
					}}
					component={(formikProps) => (
						<AddReviewModalFormControls
							formikProps={formikProps}
							commonMaterialUI__InputProps={commonMaterialUI__InputProps}
						/>
					)}
				/>
			</div>
		);
	}
}
