/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { reqStatusTypes } from '../../../../configs/constants';

import sliceConstants from './loyaltySchemeSettingsPageSliceConstants';

const standardReducers = {
	resetLoyaltySchemeSettingsPageSlice: (state, action) => {
		state = sliceConstants.initialState;
	},

	resetAllLoyaltySchemeSettingsPageSliceRequests: (state, action) => {
		// ******************************* getGlobalLoyaltySchemeSettings() *******************************
		state.getGlobalLoyaltySchemeSettingsReqStatus = reqStatusTypes.idle;
		state.getGlobalLoyaltySchemeSettingsReqError = '';

		// ******************************* getLoyaltySchemeSettingsByLocationId() *******************************
		state.getLoyaltySchemeSettingsByLocationIdReqStatus = reqStatusTypes.idle;
		state.getLoyaltySchemeSettingsByLocationIdReqError = '';
	},
};

export default standardReducers;
