/* eslint-disable no-param-reassign */
import { reqStatusTypes } from '../../../../configs/constants';

import { getLoyaltyPointsForAllLocationsByCustomerId } from './myLoyaltyPointsPageSliceExtraActions';

const extraReducers = {
	// ******************************* getLoyaltyPointsForAllLocationsByCustomerId() *******************************
	[getLoyaltyPointsForAllLocationsByCustomerId.pending]: (state, action) => {
		state.getLoyaltyPointsForAllLocationsByCustomerIdReqStatus =
			reqStatusTypes.pending;
		state.getLoyaltyPointsForAllLocationsByCustomerIdReqError = '';
	},

	[getLoyaltyPointsForAllLocationsByCustomerId.fulfilled]: (state, action) => {
		state.getLoyaltyPointsForAllLocationsByCustomerIdReqStatus =
			reqStatusTypes.succeeded;
		state.myLoyaltyPointsForAllLocations = action.payload;
	},

	[getLoyaltyPointsForAllLocationsByCustomerId.rejected]: (state, action) => {
		state.getLoyaltyPointsForAllLocationsByCustomerIdReqStatus =
			reqStatusTypes.failed;
		state.getLoyaltyPointsForAllLocationsByCustomerIdReqError =
			action.error.message;
		state.myLoyaltyPointsForAllLocations = [];
	},
};

export default extraReducers;
