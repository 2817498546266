import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { loginTypes, dashboardTypes } from '../../configs/constants';
import globalValues from '../../configs/globalValues';
import DashboardPage from '../../pages/DashboardPage/DashboardPage';
import DeveloperTestPage from '../../pages/DeveloperTestPage/DeveloperTestPage';
import ForgotPasswordPage from '../../pages/ForgotPasswordPage/ForgotPasswordPage';
import HomePage from '../../pages/HomePage/HomePage';
import LinkUserToBusinessPage from '../../pages/LinkUserToBusinessPage/LinkUserToBusinessPage';
import LoginPage from '../../pages/LoginPage/LoginPage';
import SignupPage from '../../pages/SignupPage/SignupPage';
import routerHistory from '../../utilities/routerHistory';
import {
	isUserLoggedIn,
	checkIsBusinessUser,
	removeUserIfExpired,
} from '../../utilities/userAuthentication';
import PrivateRoute from '../routes/PrivateRoute/PrivateRoute';
import Unauthorized from '../widgets/specificViews/Unauthorized';

import 'react-toastify/dist/ReactToastify.css';

import '../../styles/globalClasses/allGlobalClasses.css';
import '../../styles/overrides/allOverrides.css';

import './App.css';

// For each route change, Check UserToken expired (Through LocalStorage Value) and remove from localStorage.
// NOTE : This only handle expiration by checking token exp value in local storage. Token expiration error happen from API endpoints handled in Axios Instance. And Also in <PrivateRoute ...>'s.
routerHistory.listen(() => {
	removeUserIfExpired();
});

function App() {
	removeUserIfExpired(); // Initial Check.

	return (
		<div className='App'>
			<ToastContainer
				position='top-right'
				autoClose={2000}
				hideProgressBar
				newestOnTop
				closeOnClick
				pauseOnFocusLoss
				draggable={false}
				closeButton={false}
				pauseOnHover
			/>

			<Switch>
				<Route path='/signup'>
					<SignupPage />
				</Route>

				<Route path='/login'>
					<LoginPage loginType={loginTypes.customer} />
				</Route>

				<Route path='/businessLogin'>
					<LoginPage loginType={loginTypes.business} />
				</Route>

				<Route path='/forgotPassword'>
					<ForgotPasswordPage />
				</Route>

				<Route path='/org/customer/register/:orgId'>
					<LinkUserToBusinessPage />
				</Route>

				<PrivateRoute
					path='/dashboard'
					customRuleFn={() => {
						const userLogged = isUserLoggedIn();
						const isBusinessUser = checkIsBusinessUser();

						if (!userLogged) {
							return {
								isAuthorized: false,
								redirectTo: '/login',
							};
						}

						if (userLogged && isBusinessUser) {
							return {
								isAuthorized: false,
								unauthorizedMsg: "Sorry. You don't have access to this page.",
								unauthorizedPageComponent: <Unauthorized />,
							};
						}

						return {
							isAuthorized: true,
						};
					}}>
					<DashboardPage dashboardType={dashboardTypes.customer} />
				</PrivateRoute>

				<PrivateRoute
					path='/businessDashboard'
					customRuleFn={() => {
						const userLogged = isUserLoggedIn();
						const isBusinessUser = checkIsBusinessUser();

						if (!userLogged) {
							return {
								isAuthorized: false,
								redirectTo: '/businessLogin',
							};
						}

						if (userLogged && !isBusinessUser) {
							return {
								isAuthorized: false,
								unauthorizedMsg: "Sorry. You don't have access to this page.",
								unauthorizedPageComponent: <Unauthorized />,
							};
						}

						return {
							isAuthorized: true,
						};
					}}>
					<DashboardPage dashboardType={dashboardTypes.business} />
				</PrivateRoute>

				{/* IMPORTANT NOTE : This is a dummy testing page, that should only use/render in developer environment when necessary.  */}
				{globalValues.environment.IS_DEVELOPMENT && (
					<Route path='/developerTestPage'>
						<DeveloperTestPage />
					</Route>
				)}

				<Route path='/'>
					<HomePage />
				</Route>
			</Switch>
		</div>
	);
}

export default App;
