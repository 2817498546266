import { Button, Icon } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import './Unauthorized.css';

class Unauthorized extends Component {
	render() {
		const { history } = this.props;

		return (
			<div className='Unauthorized'>
				<div>
					<LockIcon />

					<h1>Sorry, You are not authorized to view this page.</h1>

					<Button
						variant='contained'
						size='large'
						startIcon={<Icon>reply</Icon>}
						onClick={() => {
							history.goBack();
						}}>
						GO BACK
					</Button>
				</div>
			</div>
		);
	}
}

export default withRouter(Unauthorized);
