import { Formik } from 'formik';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { loginTypes } from '../../../../configs/constants';
import {
	loginCustomer,
	loginBusiness,
} from '../../../../utilities/apiRequests/witmegApiServerRequests';
import { saveLoggedUser } from '../../../../utilities/userAuthentication';

import LoginFormControls from './LoginFormControls';

const LoginFormValidationSchema = Yup.object().shape({
	UserName: Yup.string().required('Required'),
	Password: Yup.string().required('Required'),
});

const LoginFormInitialValues = {
	UserName: '',
	Password: '',
};

export default class LoginForm extends Component {
	render() {
		const {
			history: routerHistory,
			commonMaterialUI__InputProps,
			reactRouterState,
			loginType,
		} = this.props;

		const {
			shouldRedirect,
			redirectPath,
			customValues = {},
		} = reactRouterState;

		const formInitialValues = {
			...LoginFormInitialValues,
			UserName: customValues.Email || LoginFormInitialValues.UserName,
		};

		return (
			<div className='LoginForm'>
				<div>
					<Formik
						initialStatus={{}}
						initialValues={formInitialValues}
						validationSchema={LoginFormValidationSchema}
						onSubmit={async (formValues, { setSubmitting, setStatus }) => {
							try {
								const apiReqBody = {
									UserName: formValues.UserName,
									Password: formValues.Password,
									OrganizationID: 'ecologital', // Only used in loginCustomer().
								};

								const resData =
									loginType === loginTypes.business
										? await loginBusiness(apiReqBody)
										: await loginCustomer(apiReqBody);
								const { AccessToken, RefreshToken } = resData;

								saveLoggedUser({
									AccessToken,
									RefreshToken,
									Email: formValues.UserName,
									isBusinessUser: loginType === loginTypes.business,
								});
								toast.success('You have logged successfully.');
								setStatus({
									error: '',
								});
								setSubmitting(false);
								setTimeout(() => {
									let redirectTo = '/dashboard';

									if (shouldRedirect) {
										redirectTo = redirectPath;
									} else if (loginType === loginTypes.business) {
										redirectTo = '/businessDashboard';
									} else {
										redirectTo = '/dashboard';
									}

									routerHistory.push(redirectTo);
								});
							} catch (error) {
								const errMsg = error.customErrMsg || error.message;

								setStatus({
									error: errMsg,
								});
								setSubmitting(false);
							}
						}}
						component={(formikProps) => (
							<LoginFormControls
								{...formikProps}
								commonMaterialUI__InputProps={commonMaterialUI__InputProps}
								loginType={loginType}
							/>
						)}
					/>
				</div>
			</div>
		);
	}
}
