import { reqStatusTypes } from '../../../../configs/constants';
import { sectionName } from '../customerDashboardConstants';

const sliceName = 'myReviewsPage';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,

	initialState: {
		// ******************************* getReviewDocuments() *******************************
		getReviewDocumentsReqStatus: reqStatusTypes.idle,
		getReviewDocumentsReqError: '',
		myReviewDocuments: [],

		// ******************************* addReviewPost() *******************************
		addReviewPostReqStatus: reqStatusTypes.idle,
		addReviewPostError: '',
	},
};

export default sliceConstants;
