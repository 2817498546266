/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/no-small-switch */
import apiEndpoints from '../../configs/apiEndpoints';
import { APIError, errorLogger } from '../errorHandlers';

import axiosWitmegApiServer from './axiosInstances/axiosWitmegApiServerInstance';

export async function signupCustomer(reqBody = {}, options = {}) {
	try {
		const {
			FirstName,
			LastName,
			UserName,
			Password,
			Email,
			OriginatedOrganizationID,
			Addresses,
		} = reqBody;

		const apiReqBody = {
			FirstName,
			LastName,
			UserName,
			Password,
			Email,
			OriginatedOrganizationID,
			Addresses,
		};

		const resObj = await axiosWitmegApiServer({
			url: apiEndpoints.signup,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		// Throwing Errors for errors that will happen even if Status is 200 & True.
		if (Status) {
			if (typeof Result === 'string') {
				switch (true) {
					case Result.includes(
						'Customer Already Exist in Global. Cannot insert again',
					): {
						throw new APIError(Result);
					}

					default: {
						break;
					}
				}
			}
		}

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes(
					'Customer Already Exist in Global. Cannot insert again',
				): {
					customErrMsg = 'A customer already exist for this email.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function loginCustomer(reqBody = {}, options = {}) {
	try {
		const { UserName, Password, OrganizationID } = reqBody;

		const apiReqBody = {
			UserName,
			Password,
			OrganizationID: OrganizationID || 'ecologital',
		};

		const resObj = await axiosWitmegApiServer({
			url: `${apiEndpoints.loginCustomer}`,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('mongo: no documents in result'): {
					customErrMsg =
						'No account exist for this username. Please check again.';
					break;
				}

				case errMsg.includes('Password does not match'): {
					customErrMsg = 'Your password is incorrect. Please check again.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function loginBusiness(reqBody = {}, options = {}) {
	try {
		const { UserName, Password } = reqBody;

		const apiReqBody = {
			UserName,
			Password,
		};

		const resObj = await axiosWitmegApiServer({
			url: `${apiEndpoints.loginBusiness}`,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('mongo: no documents in result'): {
					customErrMsg =
						'No account exist for this username. Please check again.';
					break;
				}

				case errMsg.includes('Password does not match'): {
					customErrMsg = 'Your password is incorrect. Please check again.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function checkUserAlreadyExist(reqBody = {}, options = {}) {
	try {
		const { Email } = reqBody;

		const apiReqBody = {
			Email,
		};

		const resObj = await axiosWitmegApiServer({
			url: apiEndpoints.checkUserExist,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('no documents in result'): {
					customErrMsg = "User don't exist for this email.";
					break;
				}

				case errMsg.includes('Customer Exist'): {
					customErrMsg = 'Account already exist for this user.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function passwordResetRequest(reqBody = {}, options = {}) {
	try {
		const { UserEmail, OrganizationID } = reqBody;

		const apiReqBody = {
			UserEmail,
			OrganizationID,
		};

		const resObj = await axiosWitmegApiServer({
			url: apiEndpoints.passwordResetRequest,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('no documents in result'): {
					customErrMsg =
						'No account exist for this username. Please check again.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function fetchCustomerDetails(reqBody = {}, options = {}) {
	try {
		const { Email } = reqBody;

		const apiReqBody = {
			Email,
		};

		const resObj = await axiosWitmegApiServer({
			url: `${apiEndpoints.fetchCustomerDetails}`,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('no documents in result'): {
					customErrMsg = "User don't exist for this email.";
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function updateCustomerDetails(
	reqBody = {},
	reqQueryParams = {},
	options = {},
) {
	try {
		const { OriginatedOrganizationID, UserID } = reqQueryParams;

		const apiReqBody = {
			...reqBody,
		};

		const apiReqUrl = `${apiEndpoints.updateCustomerDetails}/${OriginatedOrganizationID}/${UserID}`;

		const resObj = await axiosWitmegApiServer({
			url: apiReqUrl,
			method: 'PUT',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('no documents in result'): {
					customErrMsg = "User don't exist for this email.";
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function addCustomerToOrganization(reqBody = {}, options = {}) {
	try {
		const apiReqBody = {
			...reqBody,
		};

		const apiReqUrl = apiEndpoints.addCustomerToOrg;

		const resObj = await axiosWitmegApiServer({
			url: apiReqUrl,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}

		// Handling some errors come with Statue "True"
		if (Status) {
			if (typeof Result === 'string') {
				switch (true) {
					case Result.includes('Customer Already Exist'): {
						throw new APIError(Result);
					}

					default: {
						break;
					}
				}
			}
		}

		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('no documents in result'): {
					customErrMsg = "User don't exist for this email.";
					break;
				}

				case errMsg.includes('Customer Already Exist'): {
					customErrMsg = 'You already exist on this organization.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getOrgDetailsOfBusinessUser(reqBody = {}, options = {}) {
	/**
	 * FYI : Whats difference between "getOrgDetailsOfBusinessUser" vs "getFullOrgDetailsOfBusinessUser" is that
	 * 			"getFullOrgDetailsOfBusinessUser" return "Organization Name, Company Name and Location Name" along with other details.
	 */

	try {
		const { UserId } = reqBody;
		const apiReqUrl = `${apiEndpoints.getOrgDetailsOfBusinessUser}/${UserId}`;

		const resObj = await axiosWitmegApiServer({
			url: apiReqUrl,
			method: 'GET',
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}
		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex'): {
					customErrMsg = 'Invalid UserID.';
					break;
				}

				case errMsg.includes('Incorrect Path / values'): {
					customErrMsg = 'UserID not provided.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getFullOrgDetailsOfBusinessUser(
	reqBody = {},
	options = {},
) {
	/**
	 * FYI : Whats difference between "getOrgDetailsOfBusinessUser" vs "getFullOrgDetailsOfBusinessUser" is that
	 * 			"getFullOrgDetailsOfBusinessUser" return "Organization Name, Company Name and Location Name" along with other details.
	 */

	try {
		const { UserId } = reqBody;
		const apiReqUrl = `${apiEndpoints.getFullOrgDetailsOfBusinessUser}/${UserId}`;

		const resObj = await axiosWitmegApiServer({
			url: apiReqUrl,
			method: 'GET',
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}
		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex'): {
					customErrMsg = 'Invalid UserID.';
					break;
				}

				case errMsg.includes('Incorrect Path / values'): {
					customErrMsg = 'UserID not provided.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function decryptURLPortions(reqBody = {}, options = {}) {
	try {
		const { ProcessString } = reqBody;

		const apiReqBody = {
			Type: 'Loyalty',
			ProcessString,
		};

		const apiReqUrl = apiEndpoints.decryptURLPortions;

		const resObj = await axiosWitmegApiServer({
			url: apiReqUrl,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}
		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Error in decrypting'): {
					customErrMsg =
						'Decrypting failed due to invalid enc. value provided.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function encryptLocationId(reqBody = {}, options = {}) {
	try {
		const { ProcessString } = reqBody;

		const apiReqBody = {
			Type: 'Loyalty',
			ProcessString,
		};

		const apiReqUrl = apiEndpoints.encryptLocationId;

		const resObj = await axiosWitmegApiServer({
			url: apiReqUrl,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}
		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Error inconverting locationid int objectid'): {
					customErrMsg = 'Invalid LocationId Provided.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function decryptLocationId(reqBody = {}, options = {}) {
	try {
		const { ProcessString } = reqBody;

		const apiReqBody = {
			Type: 'Loyalty',
			ProcessString,
		};

		const apiReqUrl = apiEndpoints.decryptLocationId;

		const resObj = await axiosWitmegApiServer({
			url: apiReqUrl,
			method: 'POST',
			data: apiReqBody,
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}
		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('Error in decrypting url'): {
					customErrMsg = 'Invalid LocationId Provided.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}

export async function getLocationDetailsByLocationId(
	reqBody = {},
	options = {},
) {
	try {
		const { LocationId = '' } = reqBody;

		const apiReqUrl = `${apiEndpoints.getLocationDetailsByLocationId}/${LocationId}`;

		const resObj = await axiosWitmegApiServer({
			url: apiReqUrl,
			method: 'GET',
			...options,
		});

		const { Status, Result } = resObj.data;

		if (!Status) {
			throw new APIError(Result);
		}
		return Result;
	} catch (error) {
		const errMsg = error.message;
		let customErrMsg = error.customErrMsg || '';

		if (error.customErrType === 'APIERROR') {
			switch (true) {
				case errMsg.includes('encoding/hex: odd length hex string'): {
					customErrMsg = 'Invalid LocationId Provided.';
					break;
				}

				default: {
					customErrMsg = 'Error Occurred. Please try again.';
					break;
				}
			}
		}

		errorLogger(error, { customErrMsg });
		return Promise.reject(APIError(customErrMsg, error));
	}
}
