/* eslint-disable promise/always-return */
/* eslint-disable react/sort-comp */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-shadow */
import {
	CircularProgress,
	Button,
	IconButton,
	Switch,
	FormControlLabel,
	Tooltip,
} from '@material-ui/core';
import {
	Add as AddIcon,
	PermDataSetting as PermDataSettingIcon,
	Send as SendIcon,
} from '@material-ui/icons';
import { bindActionCreators, unwrapResult } from '@reduxjs/toolkit';
import { Formik } from 'formik';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import DashboardSubInfoSection from '../../../../../components/pageSections/dashboardPage/common/DashboardSubInfoSection/DashboardSubInfoSection';
import LocationSelector from '../../../../../components/pageSections/dashboardPage/common/LocationSelector/LocationSelector';
import PageLoader from '../../../../../components/widgets/loaders/PageLoader/PageLoader';
import { reqStatusTypes } from '../../../../../configs/constants';
import { sectionName as businessDashboardSectionName } from '../../../../../redux/slices/businessDashboard/businessDashboardConstants';
import { actions as businessDashboardSliceActions } from '../../../../../redux/slices/businessDashboard/businessDashboardSlice/businessDashboardSlice';
import { actions as loyaltySchemeSettingsPageSliceActions } from '../../../../../redux/slices/businessDashboard/loyaltySchemeSettingsPageSlice/loyaltySchemeSettingsPageSlice';
import { getLoggedUserDetails } from '../../../../../utilities/userAuthentication';

import {
	LoyaltySchemeSettingsFormValidationSchema,
	LoyaltySchemeSettingsFormInitialValues,
} from './helperValues';
import LoyaltySchemeSettingsFormControls from './LoyaltySchemeSettingsFormControls';

import './LoyaltySchemeSettingsPage.css';

class LoyaltySchemeSettingsPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedOptions: {}, // Will contain selected Organization, Company & Location.
			isEditable: false, // Track form elements should be editable.
			isNewSetting: false, // Track isNewSetting. (Used to display "Add" Specific Form)
		};

		this.fetchNecessaryInitialPageData = this.fetchNecessaryInitialPageData.bind(
			this,
		);
		this.handleAddOrUpdateLoyaltySchemeSettings = this.handleAddOrUpdateLoyaltySchemeSettings.bind(
			this,
		);

		this.reinitializeFormWithUpdatedValues = this.reinitializeFormWithUpdatedValues.bind(
			this,
		);
	}

	componentDidMount() {
		this.fetchNecessaryInitialPageData();
	}

	async fetchNecessaryInitialPageData() {
		const {
			loyaltySchemeSettingsPageSliceActions,
			businessDashboardSliceActions,
		} = this.props;

		const {
			getGlobalLoyaltySchemeSettings: getGlobalLoyaltySchemeSettingsAction,
		} = loyaltySchemeSettingsPageSliceActions;

		const {
			getFullOrgDetailsOfBusinessUser: getFullOrgDetailsOfBusinessUserAction,
		} = businessDashboardSliceActions;

		const { userid: UserId } = getLoggedUserDetails();

		getGlobalLoyaltySchemeSettingsAction({
			actionOptions: {
				useDurations: true,
				minimumRefetchDuration: 60 * 10, // Ten Minutes.
			},
		});
		getFullOrgDetailsOfBusinessUserAction({
			UserId,
			actionOptions: {
				useDurations: true,
				minimumRefetchDuration: 60 * 10, // Ten Minutes.
			},
		});
	}

	async handleAddOrUpdateLoyaltySchemeSettings(options = {}) {
		const { selectedOptions } = this.state;

		const {
			loyaltySchemeSettingsPageSliceState,
			loyaltySchemeSettingsPageSliceActions,
		} = this.props;

		const {
			globalSchemeSettings,
			currentSelectedLocationsExistingSettings,
		} = loyaltySchemeSettingsPageSliceState;

		const {
			addLoyaltySchemeSettings: addLoyaltySchemeSettingsAction,
			updateLoyaltySchemeSettings: updateLoyaltySchemeSettingsAction,
			getLoyaltySchemeSettingsByLocationId: getLoyaltySchemeSettingsByLocationIdAction,
		} = loyaltySchemeSettingsPageSliceActions;

		const { formValues, setSubmitting, resetForm } = options;

		const currentlySelectedLocationsExistingSettingId =
			currentSelectedLocationsExistingSettings.LoyaltySchemeSettingID;
		const isSettingsAlreadyExist = Boolean(
			// Having SettingId mean Setting Set already exist.
			currentlySelectedLocationsExistingSettingId,
		);

		// Determine whether we should Add OR Update and which actionFn to be used.
		const submitAction = isSettingsAlreadyExist
			? updateLoyaltySchemeSettingsAction
			: addLoyaltySchemeSettingsAction;

		const OrganizationID = selectedOptions.selectedOrganization.value;
		const CompanyID = selectedOptions.selectedCompany.value;
		const LocationID = selectedOptions.selectedLocation.value;

		const fullReqBody = {
			...globalSchemeSettings,
			...formValues,
			OrganizationID,
			CompanyID,
			LocationID,
		};

		// If its the "UPDATING" process we add exiting "LoyaltySchemeSettingID" for reqBody.
		if (isSettingsAlreadyExist) {
			fullReqBody.LoyaltySchemeSettingID = currentlySelectedLocationsExistingSettingId;
		} else {
			delete fullReqBody.LoyaltySchemeSettingID;
		}

		// Adding OR Updating
		await submitAction({
			fullReqBody,
		})
			.then(unwrapResult)
			.then(() => {
				return toast.success(
					`Successfully ${isSettingsAlreadyExist ? 'Updated.' : 'Added.'}`,
				);
			})
			.catch((error) => {
				const errMsg = error.customErrMsg || error.message;
				return toast.error(`${errMsg}`);
			});

		// Refetching Added/Updated Details, So redux store get updated with new result.
		await getLoyaltySchemeSettingsByLocationIdAction({
			LookupID: LocationID,
		});

		this.setState({
			// Resetting
			isEditable: false,
			isNewSetting: false,
		});

		setSubmitting(false);
	}

	reinitializeFormWithUpdatedValues(formikResetFormFn) {
		const { loyaltySchemeSettingsPageSliceState } = this.props;
		const {
			globalSchemeSettings,
			currentSelectedLocationsExistingSettings,
		} = loyaltySchemeSettingsPageSliceState;

		formikResetFormFn({
			values: {
				...LoyaltySchemeSettingsFormInitialValues,
				...globalSchemeSettings,
				...currentSelectedLocationsExistingSettings,
			},
		});
	}

	render() {
		const { selectedOptions, isNewSetting, isEditable } = this.state;
		const {
			selectedOrganization,
			selectedLocation,
			selectedLocationPathAsString,
		} = selectedOptions;

		const {
			loyaltySchemeSettingsPageSliceState,
			loyaltySchemeSettingsPageSliceActions,
			businessDashboardSliceState,
		} = this.props;

		const {
			globalSchemeSettings,
			getGlobalLoyaltySchemeSettingsReqStatus,
			getGlobalLoyaltySchemeSettingsReqError,
			getLoyaltySchemeSettingsByLocationIdReqStatus,
			getLoyaltySchemeSettingsByLocationIdReqError,
			currentSelectedLocationsExistingSettings,
		} = loyaltySchemeSettingsPageSliceState;

		const {
			getLoyaltySchemeSettingsByLocationId: getLoyaltySchemeSettingsByLocationIdAction,
			resetAllLoyaltySchemeSettingsPageSliceRequests: resetAllLoyaltySchemeSettingsPageSliceRequestsAction,
		} = loyaltySchemeSettingsPageSliceActions;

		const {
			currentUserFullOrgDetails,
			getFullOrgDetailsOfBusinessUserReqStatus,
			getFullOrgDetailsOfBusinessUserReqError,
		} = businessDashboardSliceState;

		// ***** Multiple custom dynamic constants for easy usage. ******
		const isLocationSelected =
			selectedLocation && Boolean(selectedLocation.label);

		const isOrgAdminForSelectedOrganization =
			isLocationSelected &&
			currentUserFullOrgDetails[selectedOrganization.index].IsOrganizationAdmin;

		// Only OrgAdmin of the Organization can Add/Update Settings.
		const isEditableAuthorized = isOrgAdminForSelectedOrganization;

		// Used to determine we should ADD OR UPDATE.
		const isSettingsAlreadyExist =
			currentSelectedLocationsExistingSettings.LoyaltySchemeSettingID;

		// Used to show a Loader to indicate some main async functionalities are in process.(Like fetchings started on ComponentDidMount).
		const isMainPageActionsRunning =
			getGlobalLoyaltySchemeSettingsReqStatus === reqStatusTypes.pending &&
			getFullOrgDetailsOfBusinessUserReqStatus === reqStatusTypes.pending;

		const isMainPageActionsError =
			getGlobalLoyaltySchemeSettingsReqError ||
			getFullOrgDetailsOfBusinessUserReqError;

		// Used to show a Loader to indicate some sub async functionalities in process. (Like getting specific settings of specific location)
		const isSubPageActionsRunning =
			getLoyaltySchemeSettingsByLocationIdReqStatus === reqStatusTypes.pending;

		const isSubPageActionsRunningError =
			getLoyaltySchemeSettingsByLocationIdReqStatus === reqStatusTypes.failed &&
			getLoyaltySchemeSettingsByLocationIdReqError;

		// Determine when Form Details should display. (Like when All Pre Async Loading are Finished and Location Also selected.)
		const shouldDisplayForm =
			(!isSubPageActionsRunning &&
				!isSubPageActionsRunningError &&
				isLocationSelected &&
				isSettingsAlreadyExist) ||
			(!isSubPageActionsRunning &&
				!isSubPageActionsRunningError &&
				isLocationSelected &&
				isNewSetting);

		// Default props and styles for Input Elements depending on "Editable" State Or Not.
		const commonMaterialUI__InputProps = {
			fullWidth: true,
			disabled: !isEditable,
			className: isEditable
				? 'GC-DP-FormikField--editable'
				: 'GC-DP-FormikField--nonEditable',
			placeholder: isEditable ? '' : '-',
		};

		// Indicating Main Page Data Loading.
		if (isMainPageActionsRunning || isMainPageActionsError) {
			return (
				<div className='LoyaltySchemeSettingsPage GC-DP-DashboardPage'>
					<PageLoader
						isFetching={isMainPageActionsRunning}
						loadingMsg='Please wait while we process your requested page.'
						isError={isMainPageActionsError}
						errorMsg={isMainPageActionsError}
						onErrorTryAgainFn={() => this.fetchNecessaryInitialPageData()}
						shouldHideWhenEmpty
					/>
				</div>
			);
		}

		return (
			<div className='LoyaltySchemeSettingsPage GC-DP-DashboardPage'>
				<div>
					<div className='LoyaltySchemeSettingsPage__header GC-DP-DashboardPageHeader'>
						<h1>LOYALTY SCHEME SETTINGS</h1>
					</div>

					<div className='LoyaltySchemeSettingsPage__body GC-DP-DashboardPageBody'>
						<div className='LoyaltySchemeSettingsPage__body__locationSelector GC-DP-DashboardPage__LocationSelectorWrapper'>
							<LocationSelector
								orgDetails={currentUserFullOrgDetails || []}
								instructionMsg='Please select the Location, where you want to Add/Change Loyalty Scheme by filtering through Organization and Company List. Then click on the button.'
								buttonTitle=''
								buttonIcon={<SendIcon />}
								onButtonClickFn={async (options) => {
									// Getting Existing Location Settings For Selected Location. (If Available)
									await getLoyaltySchemeSettingsByLocationIdAction({
										LookupID: options.selectedLocation.value,
									})
										.then(unwrapResult)
										.then(() => {})
										.catch((error) => {
											const errMsg = error.customErrMsg || error.message;
											return toast.error(`${errMsg}`);
										});

									// Updating our Page's local state with selected options.
									this.setState({
										selectedOptions: options,
										// Resetting
										isEditable: false,
										isNewSetting: false,
									});
								}}
							/>
						</div>

						<Formik
							initialStatus={{}}
							initialValues={{
								...LoyaltySchemeSettingsFormInitialValues,
								...globalSchemeSettings,
								...currentSelectedLocationsExistingSettings,
							}}
							validationSchema={LoyaltySchemeSettingsFormValidationSchema}
							enableReinitialize
							onSubmit={async (formValues, { setSubmitting, resetForm }) => {
								await this.handleAddOrUpdateLoyaltySchemeSettings({
									formValues,
									setSubmitting,
									resetForm,
								});

								this.reinitializeFormWithUpdatedValues(resetForm);
							}}
							component={(formikProps) => {
								const { isSubmitting, resetForm } = formikProps;

								return (
									<div className='LoyaltySchemeSettingsPage__body__formikContainer'>
										{/* Used to indicate sub actions like "Loading existing User Settings For Selected Location"  */}
										{(isSubPageActionsRunning ||
											isSubPageActionsRunningError) && (
											<div className='LoyaltySchemeSettingsPage__body__formikContainer__subPageActionLoader'>
												<PageLoader
													isFetching={isSubPageActionsRunning}
													loadingMsg=''
													isError={isSubPageActionsRunningError}
													errorMsg={isSubPageActionsRunningError}
													onErrorTryAgainTitle='Cancel'
													onErrorTryAgainFn={() => {
														// Resetting all LoyaltySchemeSettingsPageSliceRequests to RESET errors.
														// So user will be able cancel error view and see initial component and retry again.
														resetAllLoyaltySchemeSettingsPageSliceRequestsAction();

														// Resetting Local State
														this.setState({
															selectedOptions: {},
															isEditable: false,
															isNewSetting: false,
														});
													}}
													shouldHideWhenEmpty
												/>
											</div>
										)}

										{/* When No Location Is Selected */}
										{!isSubPageActionsRunning &&
											!isSubPageActionsRunningError &&
											!isLocationSelected && (
												<DashboardSubInfoSection
													className='LoyaltySchemeSettingsPage__body__formikContainer__getStarted'
													type='GETSTARTED'
													description='Select A Location To Get Started.'
													icon={<PermDataSettingIcon color='disabled' />}
												/>
											)}

										{/* When Selected Location Don't Have a Setting */}
										{!isSubPageActionsRunning &&
											!isSubPageActionsRunningError &&
											isLocationSelected &&
											!isNewSetting &&
											!isSettingsAlreadyExist && (
												<DashboardSubInfoSection
													type='CUSTOM'
													className='LoyaltySchemeSettingsPage__body__formikContainer__noSettings'>
													<div>
														<div>No Settings Available For</div>
														<div className='LoyaltySchemeSettingsPage__body__formikContainer__noSettings__selectedItem'>
															{selectedOptions.selectedCompany.label} -{' '}
															{selectedOptions.selectedLocation.label}
														</div>

														<div className='LoyaltySchemeSettingsPage__body__formikContainer__noSettings__addButton'>
															<Tooltip
																title={
																	isEditableAuthorized
																		? ''
																		: 'You are not authorized to add new settings to this location.'
																}>
																<div>
																	<IconButton
																		color='primary'
																		disabled={!isEditableAuthorized}
																		onClick={() => {
																			this.setState({
																				// Resetting
																				isNewSetting: true,
																				isEditable: true,
																			});
																		}}>
																		<AddIcon fontSize='large' />
																	</IconButton>
																</div>
															</Tooltip>
														</div>
													</div>
												</DashboardSubInfoSection>
											)}

										{/* Form for Viewing/Add/Edit/Update */}
										{shouldDisplayForm && (
											<div className='LoyaltySchemeSettingsPage__body__settingsForm'>
												<div className='LoyaltySchemeSettingsPage__body__settingsForm__header'>
													{/* Only Applicable When Updating */}
													{!isNewSetting && (
														<Tooltip
															title={
																isEditableAuthorized
																	? ''
																	: 'You are not authorized to edit settings of this location.'
															}>
															<FormControlLabel
																control={
																	<Switch
																		checked={isEditable}
																		disabled={!isEditableAuthorized}
																		onChange={() => {
																			this.reinitializeFormWithUpdatedValues(
																				resetForm,
																			);
																			this.setState({
																				isEditable: !isEditable,
																			});
																		}}
																		name='editSettings'
																	/>
																}
																label='Edit Settings'
															/>
														</Tooltip>
													)}

													{isEditable && isEditableAuthorized && (
														<div>
															<Button
																type='submit'
																variant='contained'
																color='primary'
																disabled={isSubmitting}
																onClick={(...e) => {
																	formikProps.handleSubmit(...e);
																}}>
																<div>
																	{isSettingsAlreadyExist ? 'UPDATE' : 'ADD'}
																</div>
																<div>
																	&nbsp;&nbsp;
																	{isSubmitting && (
																		<CircularProgress size={20} />
																	)}
																</div>
															</Button>

															<Button
																type='submit'
																variant='contained'
																color='secondary'
																disabled={isSubmitting}
																onClick={() => {
																	this.reinitializeFormWithUpdatedValues(
																		resetForm,
																	);

																	this.setState({
																		// Resetting
																		isNewSetting: false,
																		isEditable: false,
																	});
																}}>
																<div>CANCEL</div>
															</Button>
														</div>
													)}
												</div>

												<div className='LoyaltySchemeSettingsPage__body__settingsForm__formControls'>
													<div className='LoyaltySchemeSettingsPage__body__settingsForm__formControls__description'>
														{selectedLocationPathAsString}
													</div>

													<LoyaltySchemeSettingsFormControls
														formikProps={formikProps}
														isEditable={isEditable}
														globalSchemeSettings={globalSchemeSettings}
														commonMaterialUI__InputProps={
															commonMaterialUI__InputProps
														}
													/>
												</div>
											</div>
										)}
									</div>
								);
							}}
						/>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		businessDashboardSliceState:
			state[businessDashboardSectionName].businessDashboard,
		loyaltySchemeSettingsPageSliceState:
			state[businessDashboardSectionName].loyaltySchemeSettingsPage,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundBusinessDashboardSliceActions = bindActionCreators(
		businessDashboardSliceActions,
		dispatch,
	);
	const boundLoyaltySchemeSettingsPageSliceActions = bindActionCreators(
		loyaltySchemeSettingsPageSliceActions,
		dispatch,
	);

	return {
		businessDashboardSliceActions: boundBusinessDashboardSliceActions,
		loyaltySchemeSettingsPageSliceActions: boundLoyaltySchemeSettingsPageSliceActions,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(LoyaltySchemeSettingsPage);
