/* eslint-disable no-param-reassign */
import { reqStatusTypes } from '../../../../configs/constants';

import { generateQrValue } from './qrCodeGeneratorPageSliceExtraActions';

const extraReducers = {
	// ******************************* generateQrValue() *******************************
	[generateQrValue.pending]: (state, action) => {
		state.generateQrValueReqStatus = reqStatusTypes.pending;
		state.generateQrValueReqError = '';
	},

	[generateQrValue.fulfilled]: (state, action) => {
		const {
			LocationID,
			generatedQrValue,
			generatedQrValueDescription,
		} = action.payload;

		state.generateQrValueReqStatus = reqStatusTypes.succeeded;
		state.generatedQrValue = generatedQrValue;
		state.generatedQrValueDescription = generatedQrValueDescription;
		state.allGeneratedQrValuesAndDescByLocationId[LocationID] = {
			generatedQrValue,
			generatedQrValueDescription,
			generatedQrValueLastUpdated: new Date().toISOString(),
		};
	},

	[generateQrValue.rejected]: (state, action) => {
		const {
			condition: rejectedFromCondition,
			arg: actionArguments,
		} = action.meta;

		const { LocationID } = actionArguments;

		// Handling Rejection from Action Condition for Throttle needs. So we update state as request is fulfilled.
		if (rejectedFromCondition) {
			state.generateQrValueReqStatus = reqStatusTypes.succeeded;
			state.generateQrValueReqError = '';

			state.generatedQrValue =
				state.allGeneratedQrValuesAndDescByLocationId[
					LocationID
				].generatedQrValue;
			state.generatedQrValueDescription =
				state.allGeneratedQrValuesAndDescByLocationId[
					LocationID
				].generatedQrValueDescription;
			return;
		}

		// Actual Rejections Handling.
		state.generateQrValueReqStatus = reqStatusTypes.failed;
		state.generateQrValueReqError = action.error.message;
		state.generatedQrValue = '';
		state.generatedQrValueDescription = '';
		delete state.allGeneratedQrValuesAndDescByLocationId[LocationID];
	},
};

export default extraReducers;
