/* eslint-disable no-shadow */
/* eslint-disable promise/always-return */
import { Menu, MenuItem, CircularProgress } from '@material-ui/core';
import {
	LocalActivity as LocalActivityIcon,
	Email as EmailIcon,
	Call as CallIcon,
	StarBorder as StarBorderIcon,
	Star as StarIcon,
	Loyalty as LoyaltyIcon,
	CheckCircle as CheckCircleIcon,
	HighlightOff as HighlightOffIcon,
	MoreHoriz as MoreHorizIcon,
} from '@material-ui/icons';
import { unwrapResult, bindActionCreators } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import React, { Component } from 'react';
import ReactRating from 'react-rating';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { reqStatusTypes } from '../../../../../configs/constants';
import { sectionName as businessDashboardSectionName } from '../../../../../redux/slices/businessDashboard/businessDashboardConstants';
import { actions as customerReviewsPageSliceActions } from '../../../../../redux/slices/businessDashboard/customerReviewsPageSlice/customerReviewsPageSlice';
import { sectionName as customerDashboardSectionName } from '../../../../../redux/slices/customerDashboard/customerDashboardConstants';
import { actions as myReviewsPageSliceActions } from '../../../../../redux/slices/customerDashboard/myReviewsPageSlice/myReviewsPageSlice';

import './CustomerReviewItemCard.css';

class CustomerReviewItemCard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			cardMenuAnchorEl: null, // Used to toggle CardMenu Open/Close.
		};

		this.toggleCardMenu = this.toggleCardMenu.bind(this);
	}

	toggleCardMenu(e) {
		const { cardMenuAnchorEl } = this.state;

		this.setState({
			cardMenuAnchorEl: cardMenuAnchorEl ? null : e.currentTarget,
		});
	}

	render() {
		const { cardMenuAnchorEl } = this.state;

		const {
			// Redux Related Props
			customerReviewsPageSliceState,
			customerReviewsPageSliceActions,

			// Directly Passed Props
			reviewValues = {}, // Specific Review Document Values.
			showAdminDetails = false, // Determine, showing/hiding admin specific sections.
			refreshDataFn, // This is used to refetch review data for current location/user, After some data change happened successfully. (Ex. After Review is Authorized)
		} = this.props;

		const {
			authorizeReviewToBePostReqByReviewIds,
		} = customerReviewsPageSliceState;

		const {
			authorizeReviewToBePost: authorizeReviewToBePostAction,
		} = customerReviewsPageSliceActions;

		const {
			Review = '',
			ReviewType = '',
			ReviewID = '',
			Star = 0,
			IsReadytoPost = false,
			IsPosted = false,
			CompanyName = '',
			LocationName = '',
			CreatedDate = new Date().toISOString(),
			CustomersDetail = [{}],
		} = reviewValues;

		const {
			FirstName = '',
			LastName = '',
			Email = '',
			MobileNumber = '',
			TelephoneNumber = '',
			TotalPoints = '',
		} = CustomersDetail[0];

		// ************************ Specific Redux Action Related State Values (Admin Actions) *************************
		// Getting current specific " Review Item's " ---> "authorizeReviewToBePost" req. action related redux state.
		const currentReviewItem__AuthorizeReviewToBePostReqData =
			authorizeReviewToBePostReqByReviewIds[ReviewID] || {};

		// To show progress circle when any item related async action is running.
		const isItemActionRunning =
			currentReviewItem__AuthorizeReviewToBePostReqData.reqStatus ===
			reqStatusTypes.pending;

		return (
			<div className='CustomerReviewItemCard'>
				<div className='CustomerReviewItemCard__logo'>
					<LocalActivityIcon />
				</div>

				{showAdminDetails && (
					<div className='CustomerReviewItemCard__menu'>
						<div className='CustomerReviewItemCard__menu__menuContent'>
							<div className='CustomerReviewItemCard__menu__menuContent__menuIcon'>
								{isItemActionRunning && (
									<div className='CustomerReviewItemCard__menu__menuContent__menuIcon__progress'>
										<CircularProgress size={15} />
									</div>
								)}

								<div className='CustomerReviewItemCard__menu__menuContent__menuIcon__icon'>
									<MoreHorizIcon onClick={this.toggleCardMenu} />
								</div>
							</div>

							<Menu
								id='CustomerReviewItemCard__menu__menuContent__menu'
								anchorEl={cardMenuAnchorEl}
								keepMounted
								open={Boolean(cardMenuAnchorEl)}
								onClose={this.toggleCardMenu}
								getContentAnchorEl={null}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}>
								<MenuItem
									disabled={IsReadytoPost}
									onClick={async () => {
										this.toggleCardMenu();

										await authorizeReviewToBePostAction({
											LookupID: ReviewID,
										})
											.then(unwrapResult)
											.then(async () => {
												toast.success('Successfully authorized the Review.');

												// Re-fetching All Review Data, so user can see updated data.
												await refreshDataFn();
											})
											.catch((error) => {
												toast.error(error.message);
											});
									}}>
									Authorize Review
								</MenuItem>
							</Menu>
						</div>
					</div>
				)}

				<div className='CustomerReviewItemCard__details'>
					<div className='CustomerReviewItemCard__details__reviewGeneralData'>
						<div className='CustomerReviewItemCard__details__reviewGeneralData__title'>
							Review for {CompanyName} - {LocationName}
						</div>

						<div className='CustomerReviewItemCard__details__reviewGeneralData__reviewText'>
							<div>{Review}</div>
						</div>

						<div className='CustomerReviewItemCard__details__reviewGeneralData__reviewStars'>
							<div>
								<ReactRating
									emptySymbol={<StarBorderIcon />}
									fullSymbol={<StarIcon />}
									initialRating={Star}
									readonly
								/>
							</div>
						</div>
					</div>

					<div className='CustomerReviewItemCard__details__adminData'>
						<div className='CustomerReviewItemCard__details__adminData__reviewType'>
							<span>
								<strong>Review Type : </strong> {ReviewType}
							</span>
						</div>

						{showAdminDetails && (
							<>
								<div>
									<span>
										<strong>Authorized To Post : </strong>
										{IsReadytoPost ? <CheckCircleIcon /> : <HighlightOffIcon />}
									</span>
								</div>

								<div>
									<span>
										<strong>Posted : </strong>
										{IsPosted ? <CheckCircleIcon /> : <HighlightOffIcon />}
									</span>
								</div>
							</>
						)}
					</div>

					<div className='CustomerReviewItemCard__details__bottom'>
						{showAdminDetails && (
							<div className='CustomerReviewItemCard__details__customerDetails'>
								<div className='CustomerReviewItemCard__details__customerDetails__name'>
									<span>
										{FirstName} {LastName}
									</span>
								</div>

								<div className='CustomerReviewItemCard__details__customerDetails__totalPoints'>
									<span>
										<LoyaltyIcon /> User Points :{TotalPoints}
									</span>
								</div>

								<div className='CustomerReviewItemCard__details__customerDetails__contacts'>
									<span>
										{Email && (
											<>
												<EmailIcon /> {Email}
											</>
										)}
									</span>
									<span>
										{(MobileNumber || TelephoneNumber) && (
											<>
												<CallIcon />
												{MobileNumber} / {TelephoneNumber}
											</>
										)}
									</span>
								</div>
							</div>
						)}

						<div className='CustomerReviewItemCard__details__reviewGeneralData__time'>
							<div>{DateTime.fromISO(CreatedDate).toRelative()}</div>
						</div>
					</div>
				</div>

				<div className='CustomerReviewItemCard__points'>
					<div>
						<div className='CustomerReviewItemCard__points__pointValue'>
							<div>
								<div>{Star}</div>
								<div>STARS</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		myReviewsPageSliceState: state[customerDashboardSectionName].myReviewsPage,
		customerReviewsPageSliceState:
			state[businessDashboardSectionName].customerReviewsPage,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundMyReviewsPageSliceActions = bindActionCreators(
		myReviewsPageSliceActions,
		dispatch,
	);

	const boundCustomerReviewsPageSliceActions = bindActionCreators(
		customerReviewsPageSliceActions,
		dispatch,
	);

	return {
		myReviewsPageSliceActions: boundMyReviewsPageSliceActions,
		customerReviewsPageSliceActions: boundCustomerReviewsPageSliceActions,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(CustomerReviewItemCard);
