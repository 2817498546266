import { CircularProgress, Button } from '@material-ui/core';
import { Error as ErrorIcon, SettingsBackupRestore } from '@material-ui/icons';
import React from 'react';

import './PageLoader.css';

const PageLoader = (props) => {
	const {
		isFetching,
		isError,
		loadingMsg = '',
		errorMsg = '',
		onErrorTryAgainFn,
		onErrorTryAgainTitle = 'Try Again',
		shouldHideWhenEmpty = true,
		className = '',
		progressProps = {},
	} = props;

	if (shouldHideWhenEmpty && !isFetching && !isError) {
		return null;
	}

	return (
		<div className={`PageLoader ${className}`}>
			<div>
				<div className='PageLoader__progress'>
					{isFetching && !isError && (
						<CircularProgress
							color='primary'
							thickness={10}
							{...progressProps}
						/>
					)}
					{isError && <ErrorIcon color='error' fontSize='large' />}
				</div>

				<div className='PageLoader__info'>
					{isFetching && !isError && (
						<div
							className='PageLoader__info__fetching'
							style={{
								marginTop: loadingMsg ? '15px' : 0,
							}}>
							{loadingMsg}
						</div>
					)}

					{isError && (
						<div className='PageLoader__info__error'>
							<div>{errorMsg}</div>
							<div className='PageLoader__info__error__buttons'>
								{onErrorTryAgainFn && (
									<Button
										variant='contained'
										color='primary'
										startIcon={<SettingsBackupRestore />}
										onClick={onErrorTryAgainFn}>
										{onErrorTryAgainTitle}
									</Button>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default PageLoader;
