/* eslint-disable react/jsx-pascal-case */
import {
	ClearAll as ClearAllIcon,
	BlurOff as BlurOffIcon,
	ErrorOutline as ErrorOutlineIcon,
} from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

import './DashboardItemCard.css';

// Helper Component for "DashboardItemCard". Define default footer structure.
const DashboardItemCard__Footer = (props) => {
	const { footerLink = '/dashboard', footerTitle = 'VIEW ALL' } = props;

	return (
		<div className='DashboardItemCardFooter'>
			<ClearAllIcon />
			<Link to={footerLink}>{footerTitle}</Link>
		</div>
	);
};

// Helper Component for "DashboardItemCard". Define what show when no body items available.
const DashboardItemCard__Empty = (props) => {
	return (
		<div
			style={{
				alignItems: 'center',
				display: 'flex',
				height: '100%',
				justifyContent: 'center',
				textAlign: 'center',
			}}>
			<div>
				<BlurOffIcon color='disabled' style={{ fontSize: 50 }} />
				<div style={{ color: 'gray', fontSize: '0.8em' }}>
					NO ITEMS AVAILABLE YET
				</div>
			</div>
		</div>
	);
};

// Helper Component for "DashboardItemCard". Define what show when errors available.
const DashboardItemCard__Error = (props) => {
	return (
		<div
			style={{
				alignItems: 'center',
				display: 'flex',
				height: '100%',
				justifyContent: 'center',
				textAlign: 'center',
			}}>
			<div>
				<ErrorOutlineIcon color='disabled' style={{ fontSize: 50 }} />
				<div style={{ color: 'gray', fontSize: '0.8em' }}>
					SORRY. SOME ERROR OCCURED IN THIS SECTION.
				</div>
			</div>
		</div>
	);
};

const DashboardItemCard = (props) => {
	const {
		children,
		header,
		isEmpty = false,
		isError = false,
		footerProps = { showDeafultFooter: false },
	} = props;

	return (
		<div className='DashboardItemCard'>
			<div className='DashboardItemCard__header'>{header}</div>
			<div className='DashboardItemCard__body'>
				{isError && <DashboardItemCard__Error />}
				{!isError && isEmpty && <DashboardItemCard__Empty />}
				{!isError && !isEmpty && children}
			</div>
			<div className='DashboardItemCard__footer'>
				{!isError && !isEmpty && footerProps.showDeafultFooter && (
					<DashboardItemCard__Footer {...footerProps} />
				)}
			</div>
		</div>
	);
};

export default DashboardItemCard;
