import { createAsyncThunk } from '@reduxjs/toolkit';

import { getLoyaltyPointsForAllLocationsByCustomerId as getLoyaltyPointsForAllLocationsByCustomerIdAPI } from '../../../../utilities/apiRequests/witmegLoyaltyServerRequests';

import sliceConstants from './myLoyaltyPointsPageSliceConstants';

export const getLoyaltyPointsForAllLocationsByCustomerId = createAsyncThunk(
	`${sliceConstants.name}/getLoyaltyPointsForAllLocationsByCustomerId`,
	async (options = {}, thunkAPI) => {
		const { reqData } = options;

		try {
			return await getLoyaltyPointsForAllLocationsByCustomerIdAPI({
				...reqData,
			});
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

const extraActions = {
	getLoyaltyPointsForAllLocationsByCustomerId,
};

export default extraActions;
