import { createAsyncThunk } from '@reduxjs/toolkit';

import sliceConstants from './dashboardHomePageSliceConstants';

export const dummyFn = createAsyncThunk(
	`${sliceConstants.name}/dummyFn`,
	async (options = {}, thunkAPI) => {
		try {
			console.log(options);
			return 'DUMMY 2';
		} catch (error) {
			const errMsg = error.customErrMsg || error.message;
			return Promise.reject(errMsg);
		}
	},
);

const extraActions = { dummyFn };

export default extraActions;
