import globalValues from '../../configs/globalValues';

export const emailConfigsDefaults = {
	To: '',
	FromName:
		globalValues.credentials.WITMEG_EMAILSERVER___DEFAULT_EMAIL_FROM_NAME ||
		'WitMeg - Loyalty',
	From:
		globalValues.credentials.WITMEG_EMAILSERVER___DEFAULT_EMAIL_FROM_ADDRESS ||
		'loyalty@witmeg.com',
	Subject: 'WITMEG - LOYALTY',
	HTMLContent: `<h1>Hello User</h1>`,

	Channel: 'Email',
	ServiceOrigin: 'Loyalty',
	EmailType: 'Standard',
	TemplateName: 'loyaltytemplate',
	MessageReceivedAt: new Date().toISOString(),
};
