import { Formik } from 'formik';
import React, { Component } from 'react';
import * as Yup from 'yup';

import { genericErrorHandler } from '../../../../utilities/errorHandlers';

import SignupEmailConfFormControls from './SignupEmailConfFormControls';

const SignupEmailConfFormValidationSchema = Yup.object().shape({
	EmailConfirmationCode: Yup.string().required('Required'),
});

const SignupEmailConfFormInitialValues = {
	EmailConfirmationCode: '',
};

export default class SignupEmailConfForm extends Component {
	render() {
		const {
			customFormData,
			setCurrentFormStep,
			currentFormStep,
			commonMaterialUI__InputProps,
		} = this.props;
		const { Email } = customFormData;

		return (
			<div className='SignupEmailConfForm'>
				<div>
					<Formik
						initialStatus={{}}
						initialValues={SignupEmailConfFormInitialValues}
						validationSchema={SignupEmailConfFormValidationSchema}
						onSubmit={async (formValues, { setSubmitting, setStatus }) => {
							try {
								const { emailConfirmationCode } = customFormData;

								if (
									formValues.EmailConfirmationCode === emailConfirmationCode
								) {
									// Email Validation Successful.
									setStatus({
										error: '',
									});
									setSubmitting(false);
									setCurrentFormStep(currentFormStep + 1);
								} else {
									// Email Validation Failed.
									setStatus({
										error: 'Entered Email Verification Code is invalid.',
									});
									setSubmitting(false);
								}
							} catch (error) {
								const { errMsg } = genericErrorHandler(error);

								setStatus({
									error: errMsg,
								});
								setSubmitting(false);
							}
						}}
						component={(formikProps) => (
							<SignupEmailConfFormControls
								{...formikProps}
								email={Email}
								setCurrentFormStep={setCurrentFormStep}
								commonMaterialUI__InputProps={commonMaterialUI__InputProps}
							/>
						)}
					/>
				</div>
			</div>
		);
	}
}
