/* eslint-disable no-param-reassign */
import { reqStatusTypes } from '../../../../configs/constants';

import { getLoyaltyAwardsDocuments } from './customerLoyaltyAwardsPageSliceExtraActions';

const extraReducers = {
	// ******************************* getLoyaltyAwardsDocuments() *******************************
	[getLoyaltyAwardsDocuments.pending]: (state, action) => {
		state.getLoyaltyAwardsDocumentsReqStatus = reqStatusTypes.pending;
		state.getLoyaltyAwardsDocumentsReqError = '';
	},

	[getLoyaltyAwardsDocuments.fulfilled]: (state, action) => {
		state.getLoyaltyAwardsDocumentsReqStatus = reqStatusTypes.succeeded;
		state.currentLocationLoyaltyAwardsDocuments = action.payload;
	},

	[getLoyaltyAwardsDocuments.rejected]: (state, action) => {
		state.getLoyaltyAwardsDocumentsReqStatus = reqStatusTypes.failed;
		state.getLoyaltyAwardsDocumentsReqError = action.error.message;
		state.currentLocationLoyaltyAwardsDocuments = [];
	},
};

export default extraReducers;
