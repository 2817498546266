/**
 * IMPORTANT NOTE : This is a dummy testing page, that should only use/render in developer environment when necessary.
 */

import React, { Component } from 'react';

import { getLoyaltySchemeSettingsByLocationSettingId } from '../../utilities/apiRequests/witmegLoyaltyServerRequests';

import './DeveloperTestPage.css';

export default class DeveloperTestPage extends Component {
	render() {
		return (
			<div className='DeveloperTestPage'>
				<div>
					<h1> Developer Test Page</h1>
				</div>

				<div>
					<button
						type='button'
						onClick={() => {
							getLoyaltySchemeSettingsByLocationSettingId({
								LookupID: '5ff3fcfdf7ee1353f1f00da5',
							})
								.then((r) => console.log('SUCCESS', r))
								.catch((error) => console.log('ERROR', error));
						}}>
						FIRE
					</button>
				</div>
			</div>
		);
	}
}
