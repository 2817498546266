import { reqStatusTypes } from '../../../../configs/constants';
import { sectionName } from '../businessDashboardConstants';

const sliceName = 'customerReviewsPage';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,

	initialState: {
		// ******************************* getReviewDocuments() *******************************
		getReviewDocumentsReqStatus: reqStatusTypes.idle,
		getReviewDocumentsReqError: '',
		currentLocationReviewDocuments: [],

		// ******************************* authorizeReviewToBePost() *******************************
		authorizeReviewToBePostReqByReviewIds: {
			/** Pattern Ex.-
				[REVIEWID]: {
					reqStatus : '',
					reqError: '',
				},
			*/
		},
	},
};

export default sliceConstants;
