import {
	InputAdornment,
	CircularProgress,
	FormHelperText,
} from '@material-ui/core';
import { Person as PersonIcon, VpnKey as VpnKeyIcon } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

import FormikTextField from '../../../../components/widgets/formikInputs/materialUI/FormikTextField/FormikTextField';
import { loginTypes } from '../../../../configs/constants';

import './LoginFormControls.css';

export default function LoginFormControls(props) {
	const {
		// ***** Props Passdown From Formik ***** (Such as errors, touched, getFieldProps, etc...)
		handleSubmit,
		isSubmitting,
		status, // Manually setted values by us. In our case used to set any API errors occurred while submitting the form.

		// **** Custom Props Manually Passed By Us *****
		commonMaterialUI__InputProps,
		loginType,
	} = props;

	return (
		<form
			className='LoginFormControls'
			onSubmit={handleSubmit}
			noValidate // To disable HTLML5 builtin validations.
		>
			<div className='LoginFormControls__inputs'>
				<FormikTextField
					name='UserName'
					materialUIProps={{
						...commonMaterialUI__InputProps,
						required: true,
						placeholder: 'Username*',
						InputProps: {
							startAdornment: (
								<InputAdornment position='start'>
									<PersonIcon color='disabled' />{' '}
								</InputAdornment>
							),
						},
					}}
				/>

				<FormikTextField
					name='Password'
					materialUIProps={{
						...commonMaterialUI__InputProps,
						required: true,
						type: 'password',
						placeholder: 'Password*',
						InputProps: {
							startAdornment: (
								<InputAdornment position='start'>
									<VpnKeyIcon color='disabled' />{' '}
								</InputAdornment>
							),
						},
					}}
				/>
			</div>

			<div className='LoginFormControls__footer'>
				<FormHelperText error filled>
					{status.error}
				</FormHelperText>

				<button
					type='submit'
					className='LoginFormControls__footer__loginButton'
					disabled={isSubmitting}>
					<div>LOGIN</div>
					<div className='LoginFormControls__footer__loginButton__progress'>
						{isSubmitting && <CircularProgress size={20} />}
					</div>
				</button>

				<div className='LoginFormControls__footer__links'>
					<div
						style={{
							display: loginType === loginTypes.business ? 'none' : 'flex',
						}}>
						<p className='LoginFormControls__footer__links__signup'>
							<span>Don&apos;t have an account ? </span>
							<Link to='/signup'>Signup</Link>
						</p>

						<p className='LoginFormControls__footer__links__forgotPassword'>
							<Link to='/forgotPassword'>Forgot Password</Link>
						</p>
					</div>

					{/* NOTE : Temporally Disabled LoginType Changer URL Links. */}
					{/* <div>
						<p
							className='LoginFormControls__footer__links__changeLoginType'
							style={{
								marginTop: loginType === loginTypes.business ? '15px' : 0,
							}}>
							<span>
								Business Account ?{' '}
								{loginType === loginTypes.business
									? 'Customer Account ?'
									: 'Business Account ?'}
							</span>

							{loginType === loginTypes.business ? (
								<Link to='/login'>Customer Login</Link>
							) : (
								<Link to='/businessLogin'>Business Login</Link>
							)}
						</p>
					</div> */}
				</div>
			</div>
		</form>
	);
}
