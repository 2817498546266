import {
	InputAdornment,
	CircularProgress,
	FormHelperText,
	Grid,
	MenuItem,
} from '@material-ui/core';
import {
	Business as BusinessIcon,
	Email as EmailIcon,
	LocationCity as LocationCityIcon,
	LocationOn as LocationOnIcon,
	Person as PersonIcon,
	PersonAdd as PersonAddIcon,
	Phone as PhoneIcon,
	VpnKey as VpnKeyIcon,
} from '@material-ui/icons';
import { countries as countryList } from 'countries-list';
import React from 'react';

import FormikTextField from '../../../../components/widgets/formikInputs/materialUI/FormikTextField/FormikTextField';
import './SignupFormControls.css';

const formattedCountryList = Object.values(countryList)
	.sort((a, b) => {
		if (a.name < b.name) {
			return -1;
		}
		if (a.name > b.name) {
			return 1;
		}
		return 0;
	})
	.map((country) => {
		return (
			<MenuItem key={country.name} value={country.name}>
				{country.name}
			</MenuItem>
		);
	});

export default function SignupFormControls(props) {
	const {
		// ***** Props Passdown From Formik ***** (Such as errors, touched, getFieldProps, etc...)
		handleSubmit,
		isSubmitting,
		values, // Form input values
		errors, // Form error values
		status, // Manually setted values by us. In our case used to set any API errors occurred while submitting the form.

		// **** Custom Props Manually Passed By Us *****
		commonMaterialUI__InputProps,
	} = props;

	return (
		<form
			className='SignupFormControls'
			onSubmit={handleSubmit}
			noValidate // To disable HTLML5 builtin validations.
		>
			<div className='SignupFormControls__inputs'>
				<Grid container spacing={1}>
					<Grid item md={6} xs={12}>
						<FormikTextField
							name='FirstName'
							materialUIProps={{
								...commonMaterialUI__InputProps,
								required: true,
								placeholder: 'First Name*',
								InputProps: {
									startAdornment: (
										<InputAdornment position='start'>
											<PersonIcon color='disabled' />
										</InputAdornment>
									),
								},
							}}
						/>
					</Grid>

					<Grid item md={6} xs={12}>
						<FormikTextField
							name='LastName'
							materialUIProps={{
								...commonMaterialUI__InputProps,
								required: true,
								placeholder: 'LastName*',
								InputProps: {
									startAdornment: (
										<InputAdornment position='start'>
											<PersonAddIcon color='disabled' />
										</InputAdornment>
									),
								},
							}}
						/>
					</Grid>
				</Grid>

				<FormikTextField
					name='Email'
					materialUIProps={{
						...commonMaterialUI__InputProps,
						required: true,
						placeholder: 'Email*',
						disabled: true,
						InputProps: {
							startAdornment: (
								<InputAdornment position='start'>
									<EmailIcon color='disabled' />{' '}
								</InputAdornment>
							),
						},
					}}
				/>

				<Grid container spacing={1}>
					<Grid item md={6} xs={12}>
						<FormikTextField
							name='Password'
							materialUIProps={{
								...commonMaterialUI__InputProps,
								required: true,
								type: 'password',
								placeholder: 'Password*',
								InputProps: {
									startAdornment: (
										<InputAdornment position='start'>
											<VpnKeyIcon color='disabled' />{' '}
										</InputAdornment>
									),
								},
							}}
						/>
					</Grid>

					<Grid item md={6} xs={12}>
						<FormikTextField
							name='ConfirmPassword'
							materialUIProps={{
								...commonMaterialUI__InputProps,
								required: true,
								type: 'password',
								placeholder: 'Confirm Password*',
							}}
						/>
					</Grid>
				</Grid>

				<br />

				<FormikTextField
					name='AddressLine1'
					materialUIProps={{
						...commonMaterialUI__InputProps,
						required: true,
						placeholder: 'Address Line 1*',
						InputProps: {
							startAdornment: (
								<InputAdornment position='start'>
									<BusinessIcon color='disabled' />
								</InputAdornment>
							),
						},
					}}
				/>
				<FormikTextField
					name='AddressLine2'
					materialUIProps={{
						...commonMaterialUI__InputProps,
						placeholder: 'Address Line 2',
						required: false,
						InputProps: {
							startAdornment: (
								<InputAdornment position='start'>
									<BusinessIcon color='disabled' />
								</InputAdornment>
							),
						},
					}}
				/>
				<Grid container spacing={1}>
					<Grid item md={6} xs={12}>
						<FormikTextField
							name='StateProvinceRegion'
							materialUIProps={{
								...commonMaterialUI__InputProps,
								placeholder: 'State / Province / Region*',
								required: true,
								InputProps: {
									startAdornment: (
										<InputAdornment position='start'>
											<BusinessIcon color='disabled' />
										</InputAdornment>
									),
								},
							}}
						/>
					</Grid>

					<Grid item md={6} xs={12}>
						<FormikTextField
							name='PostZipCode'
							materialUIProps={{
								...commonMaterialUI__InputProps,
								placeholder: 'Post / ZipCode*',
								required: true,
							}}
						/>
					</Grid>
				</Grid>

				<FormikTextField
					name='City'
					materialUIProps={{
						...commonMaterialUI__InputProps,
						placeholder: 'City*',
						required: true,
						InputProps: {
							startAdornment: (
								<InputAdornment position='start'>
									<LocationCityIcon color='disabled' />
								</InputAdornment>
							),
						},
					}}
				/>

				{/* IMPORTANT : This is internally SelectBox */}
				<div className='SignupFormControls__inputs__countrySelect'>
					{/*  Temporary Solution : Using custom placeholder for this select because MaterialUI placeholder messup/don't match with other inputs. */}
					{values.Country === '' ? (
						<div
							className='SignupFormControls__inputs__countrySelect__customPlaceholder'
							style={{
								marginTop: errors.Country ? '-12px' : '0',
							}}>
							Country*
						</div>
					) : (
						''
					)}

					<FormikTextField
						name='Country'
						materialUIProps={{
							...commonMaterialUI__InputProps,
							select: true,
							defaultValue: '',
							placeholder: 'Country*',
							required: true,
							InputProps: {
								startAdornment: (
									<InputAdornment position='start'>
										<LocationOnIcon color='disabled' />
									</InputAdornment>
								),
							},
						}}>
						{formattedCountryList}
					</FormikTextField>
				</div>

				<FormikTextField
					name='Phone'
					materialUIProps={{
						...commonMaterialUI__InputProps,
						placeholder: 'Phone*',
						required: true,
						InputProps: {
							startAdornment: (
								<InputAdornment position='start'>
									<PhoneIcon color='disabled' />
								</InputAdornment>
							),
						},
					}}
				/>
			</div>

			<div className='SignupFormControls__footer'>
				<FormHelperText error filled>
					{status.error}
				</FormHelperText>

				<p>
					Your data will be located in United States.<a href='#'>Change</a>
				</p>

				<button
					type='submit'
					className='SignupFormControls__footer__signupButton'
					disabled={isSubmitting}>
					<div>SIGN UP FOR FREE</div>
					<div className='SignupFormControls__footer__signupButton__progress'>
						{isSubmitting && <CircularProgress size={20} />}
					</div>
				</button>

				<p className='SignupFormControls__footer__acknowledgeParagraph'>
					By clicking on &quot;SIGN UP FOR FREE&quot; you agree to our
					<a href='#'>terms</a>
					and acknowledge reading our
					<a href='#'>privacy notice</a>
				</p>
			</div>
		</form>
	);
}
