import { reqStatusTypes } from '../../../../configs/constants';
import { sectionName } from '../customerDashboardConstants';

const sliceName = 'myLoyaltyPointsPage';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,

	initialState: {
		// ******************************* getLoyaltyPointsForAllLocationsByCustomerId() *******************************
		getLoyaltyPointsForAllLocationsByCustomerIdReqStatus: reqStatusTypes.idle,
		getLoyaltyPointsForAllLocationsByCustomerIdReqError: '',
		myLoyaltyPointsForAllLocations: [],
	},
};

export default sliceConstants;
