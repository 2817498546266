/* eslint-disable no-param-reassign */
import { reqStatusTypes } from '../../../configs/constants';

import {
	getCustomerDetails,
	updateCustomerDetails,
} from './userSliceExtraActions';

const extraReducers = {
	// ******************************* getCustomerDetails() *******************************
	[getCustomerDetails.pending]: (state, action) => {
		// If data already successfully fetched avoiding loading animations.
		if (state.getCustomerDetailsReqStatus === reqStatusTypes.succeeded) {
			return;
		}

		state.getCustomerDetailsReqStatus = reqStatusTypes.pending;
		state.getCustomerDetailsReqError = '';
	},

	[getCustomerDetails.fulfilled]: (state, action) => {
		state.getCustomerDetailsReqStatus = reqStatusTypes.succeeded;
		state.customerDetails = action.payload;
	},

	[getCustomerDetails.rejected]: (state, action) => {
		state.getCustomerDetailsReqStatus = reqStatusTypes.failed;
		state.getCustomerDetailsReqError = action.error.message;
		state.customerDetails = {};
	},

	// ******************************* updateCustomerDetails() *******************************
	[updateCustomerDetails.pending]: (state, action) => {
		state.updateCustomerDetailsReqStatus = reqStatusTypes.pending;
		state.updateCustomerDetailsReqError = '';
	},

	[updateCustomerDetails.fulfilled]: (state, action) => {
		state.updateCustomerDetailsReqStatus = reqStatusTypes.succeeded;
		state.customerDetails = action.payload;
	},

	[updateCustomerDetails.rejected]: (state, action) => {
		state.updateCustomerDetailsReqStatus = reqStatusTypes.failed;
		state.updateCustomerDetailsReqError = action.error.message;
	},
};

export default extraReducers;
