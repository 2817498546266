import { reqStatusTypes } from '../../../../configs/constants';
import { sectionName } from '../businessDashboardConstants';

const sliceName = 'qrCodeGeneratorPage';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,

	initialState: {
		// ******************************* generateQrValue() *******************************
		generateQrValueReqStatus: reqStatusTypes.idle,
		generateQrValueReqError: '',
		generatedQrValue: '',
		generatedQrValueDescription: '',
		allGeneratedQrValuesAndDescByLocationId: {
			// Pattern Ex.-
			// [LOCATIONID]: {
			// 		generatedQrValue
			// 		generatedQrValueDescription
			// 		generatedQrValueLastUpdated
			// },
		},
	},
};

export default sliceConstants;
