/* eslint-disable react/no-unused-state */
import { Button } from '@material-ui/core';
import { Send as SendIcon } from '@material-ui/icons';
import React, { Component } from 'react';
import ReactSelect from 'react-select';

import {
	genOrganizationSelectValue,
	genCompanySelectValue,
	genLocationSelectValue,
	handleAutoSelections,
} from './locationSelectorHelpers';

import './LocationSelector.css';

export default class LocationSelector extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedOrganization: '',
			selectedCompany: '',
			selectedLocation: '',
			selectedLocationPathAsString: '',
		};

		this.handleSelectionChanges = this.handleSelectionChanges.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		const autoSelectionStateUpdates = handleAutoSelections(props, state);

		return {
			...autoSelectionStateUpdates,
		};
	}

	// Global OnChange Handler for all Select Boxes.
	handleSelectionChanges(selectedOption, selectorDetails) {
		const { selectedOrganization, selectedCompany } = this.state;
		const { name } = selectorDetails;

		// Resetting Child Selects (Ex. Company, Location) when Parent Selector Changed.
		let moreStateUpdates = {};
		if (name === 'selectedOrganization') {
			moreStateUpdates = {
				...moreStateUpdates,
				selectedCompany: '',
				selectedLocation: '',
			};
		} else if (name === 'selectedCompany') {
			moreStateUpdates = {
				...moreStateUpdates,
				selectedLocation: '',
			};
		} else if (name === 'selectedLocation') {
			moreStateUpdates = {
				...moreStateUpdates,

				// Setting more calculated values when location is selected.
				selectedLocationPathAsString: `${selectedOrganization.label} - ${selectedCompany.label} - ${selectedOption.label}`,
			};
		}

		this.setState({
			...moreStateUpdates,
			[name]: selectedOption,
		});
	}

	// Generate list of Select Options as needed when user is selecting through "Organization -> Company -> Location"
	calculateSelectOptions() {
		const { selectedOrganization, selectedCompany } = this.state;
		const { orgDetails = [] } = this.props;

		const selectOptions = {
			organizations: [],
			companies: [],
			locations: [],
		};

		orgDetails.forEach((org, orgIndex) => {
			// Adding Full Organization List
			const orgItem = genOrganizationSelectValue(org, { index: orgIndex });
			selectOptions.organizations.push(orgItem);

			// Adding Companies of SelectedOrganization
			if (selectedOrganization.index === orgIndex) {
				org.Companies.forEach((com, comIndex) => {
					const comItem = genCompanySelectValue(com, { index: comIndex });
					selectOptions.companies.push(comItem);

					// Adding Locations of SelectedCompany
					if (selectedCompany.index === comIndex) {
						com.LocationIDs.forEach((loc, locIndex) => {
							const locItem = genLocationSelectValue(loc, {
								index: locIndex,
							});
							selectOptions.locations.push(locItem);
						});
					}
				});
			}
		});

		return selectOptions;
	}

	render() {
		const {
			selectedOrganization,
			selectedCompany,
			selectedLocation,
			selectedLocationPathAsString,
		} = this.state;

		const {
			instructionMsg = '',
			buttonIcon = '',
			buttonTitle = '',
			onButtonClickFn = () => {},
		} = this.props;

		const selectOptions = this.calculateSelectOptions();

		return (
			<div className='LocationSelector'>
				<div className='LocationSelector__selectorsWrapper'>
					<div className='LocationSelector__selectorsWrapper__headerInfo'>
						{instructionMsg}
					</div>

					<div className='LocationSelector__selectorsWrapper__selectors'>
						<ReactSelect
							name='selectedOrganization'
							value={selectedOrganization}
							placeholder='Select a Organization'
							onChange={this.handleSelectionChanges}
							options={selectOptions.organizations}
						/>

						<ReactSelect
							name='selectedCompany'
							value={selectedCompany}
							placeholder='Select a Company'
							isDisabled={selectOptions.companies.length === 0}
							onChange={this.handleSelectionChanges}
							options={selectOptions.companies}
						/>

						<ReactSelect
							name='selectedLocation'
							value={selectedLocation}
							placeholder='Select a Location'
							isDisabled={selectOptions.locations.length === 0}
							onChange={this.handleSelectionChanges}
							options={selectOptions.locations}
						/>

						<Button
							variant='contained'
							color='primary'
							endIcon={buttonIcon || <SendIcon />}
							disabled={!selectedLocation}
							onClick={() => {
								onButtonClickFn({
									selectedOrganization,
									selectedCompany,
									selectedLocation,
									selectedLocationPathAsString,
								});
							}}>
							{buttonTitle}
						</Button>
					</div>
				</div>
			</div>
		);
	}
}
