import { reqStatusTypes } from '../../../../configs/constants';
import { sectionName } from '../customerDashboardConstants';

const sliceName = 'myCheckInsPage';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,

	initialState: {
		// ******************************* getCheckInDocuments() *******************************
		getCheckInDocumentsReqStatus: reqStatusTypes.idle,
		getCheckInDocumentsReqError: '',
		myCheckInDocuments: [],
	},
};

export default sliceConstants;
