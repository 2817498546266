import React from 'react';
import './CustomLabeledFormField.css';

function CustomLabeledFormField(props = {}) {
	const { label = '', content = '', isSubItem = false } = props;

	return (
		<div
			className={`CustomLabeledFormField ${
				isSubItem ? 'CustomLabeledFormField--sub' : ''
			}`}>
			<div
				className={`CustomLabeledFormField__label ${
					isSubItem ? 'CustomLabeledFormField__label--sub' : ''
				}`}>
				{label}
			</div>
			<div
				className={`CustomLabeledFormField__content ${
					isSubItem ? 'CustomLabeledFormField__content--sub' : ''
				}`}>
				{content || <div style={{ color: 'gray' }}>-</div>}
			</div>
		</div>
	);
}

export default CustomLabeledFormField;
