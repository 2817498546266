/* eslint-disable no-param-reassign */
import { reqStatusTypes } from '../../../../configs/constants';

import {
	getGlobalLoyaltySchemeSettings,
	getLoyaltySchemeSettingsByLocationId,
} from './loyaltySchemeSettingsPageSliceExtraActions';

const extraReducers = {
	// ******************************* getGlobalLoyaltySchemeSettings() *******************************
	[getGlobalLoyaltySchemeSettings.pending]: (state, action) => {
		state.getGlobalLoyaltySchemeSettingsReqStatus = reqStatusTypes.pending;
		state.getGlobalLoyaltySchemeSettingsReqError = '';
	},

	[getGlobalLoyaltySchemeSettings.fulfilled]: (state, action) => {
		state.getGlobalLoyaltySchemeSettingsReqStatus = reqStatusTypes.succeeded;
		state.getGlobalLoyaltySchemeSettingsReqLastUpdated = new Date().toISOString();
		state.globalSchemeSettings = action.payload;
	},

	[getGlobalLoyaltySchemeSettings.rejected]: (state, action) => {
		state.getGlobalLoyaltySchemeSettingsReqStatus = reqStatusTypes.failed;
		state.getGlobalLoyaltySchemeSettingsReqError = action.error.message;
		state.globalSchemeSettings = {};
	},

	// ******************************* getLoyaltySchemeSettingsByLocationId() *******************************
	[getLoyaltySchemeSettingsByLocationId.pending]: (state, action) => {
		state.getLoyaltySchemeSettingsByLocationIdReqStatus =
			reqStatusTypes.pending;
		state.getLoyaltySchemeSettingsByLocationIdReqError = '';
	},

	[getLoyaltySchemeSettingsByLocationId.fulfilled]: (state, action) => {
		state.getLoyaltySchemeSettingsByLocationIdReqStatus =
			reqStatusTypes.succeeded;
		state.currentSelectedLocationsExistingSettings = action.payload;
	},

	[getLoyaltySchemeSettingsByLocationId.rejected]: (state, action) => {
		state.getLoyaltySchemeSettingsByLocationIdReqStatus = reqStatusTypes.failed;
		state.getLoyaltySchemeSettingsByLocationIdReqError = action.error.message;
		state.currentSelectedLocationsExistingSettings = {};
	},
};

export default extraReducers;
