import { reqStatusTypes } from '../../../../configs/constants';
import { sectionName } from '../customerDashboardConstants';

const sliceName = 'customerDashboard';
const currentStatePath = `${sectionName}.${sliceName}`;

const sliceConstants = {
	name: sliceName,
	parentSectionName: sectionName,
	currentStatePath,
	initialState: {
		// ******************************* dummyFn() *******************************
		dummyReqStatus: reqStatusTypes.idle,
		dummyReqError: '',
		dummyValue: 'DUMMY',
	},
};

export default sliceConstants;
