/* eslint-disable jsx-a11y/no-static-element-interactions */
import { CircularProgress } from '@material-ui/core';
import { Cancel as CancelIcon } from '@material-ui/icons';
import React, { Component } from 'react';
import ReactModal from 'react-modal';
import './BaseModal.css';

const reactModalCustomStyles = {
	overlay: {
		backgroundColor: '#403b3bbf',
		zIndex: 10000,
	},
	content: {
		padding: 0,
		backgroundColor: 'transparent',
		border: 'none',
	},
};

export default class BaseModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isModalOpen: false, // Used only if Modal Opening/Closing handled by this comp. itself.
		};

		this.toggleModalOpen = this.toggleModalOpen.bind(this);
	}

	toggleModalOpen() {
		// Used only if Modal Opening/Closing handled by this comp. itself.
		const { isModalOpen } = this.state;

		this.setState({
			isModalOpen: !isModalOpen,
		});
	}

	render() {
		const { isModalOpen } = this.state;

		const {
			nativeModalProps = {},
			className = '',
			children,

			customIsOpen,
			customToggleFn,
			customToggleButtonComp = () => {},

			customTitle = '',
			customSubtitle = '',
			showProgressAnim = false,
			shouldRenderEvenClosed = false,
		} = this.props;

		const isOpen = customIsOpen || nativeModalProps.isOpen || isModalOpen;
		const closeFn = customToggleFn || this.toggleModalOpen;

		const shouldRenderModal = shouldRenderEvenClosed ? true : isOpen;

		return (
			<div className='BaseModal'>
				<div className='BaseModal__toggleButton'>
					{customToggleButtonComp &&
						customToggleButtonComp({
							nativeToggleFn: this.toggleModalOpen,
						})}
				</div>

				{shouldRenderModal && (
					<ReactModal
						style={reactModalCustomStyles}
						ariaHideApp={false}
						isOpen={isOpen}
						{...nativeModalProps}
						onRequestClose={() => {
							closeFn();
						}}>
						{/* // NOTE : This Element is used to make Modal is Responsive to ModalContent. */}
						<div
							className='BaseModal__specialPositionsHelperWrapper'
							onClick={() => {
								closeFn();
							}}
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								height: '100%',
								overflow: 'auto',
							}}>
							<div
								className={`BaseModal__mainContentWrapper ${className}`}
								onClick={(e) => {
									e.stopPropagation(); // Stopping clicks on modal content going through "specialPositionsHelperWrapper" and 'overlayWrapper'.
								}}
								style={{
									position: 'relative',
									margin: '0 auto',
									overflow: 'auto',
									border: '1px solid #ccc',
									background: '#fff',
									WebkitOverflowScrolling: 'touch',
									borderRadius: '3px',
									outline: 'none',
									minWidth: '200px',
									padding: '20px',
								}}>
								<div className='BaseModal__mainContentWrapper__header'>
									<div className='BaseModal__mainContentWrapper__header__closeButton'>
										<CancelIcon
											onClick={() => {
												closeFn();
											}}
										/>
									</div>

									<div className='BaseModal__mainContentWrapper__header__details'>
										<div className='BaseModal__mainContentWrapper__header__details__left'>
											<div className='BaseModal__mainContentWrapper__header__details__title'>
												<h1>{customTitle}</h1>
											</div>
											<div className='BaseModal__mainContentWrapper__header__details__subtitle'>
												<p>{customSubtitle}</p>
											</div>
										</div>

										<div className='BaseModal__mainContentWrapper__header__details__right'>
											<div className='BaseModal__mainContentWrapper__header__details__progressAnim'>
												{showProgressAnim && <CircularProgress size={30} />}
											</div>
										</div>
									</div>
								</div>

								{/* // NOTE : This Element is where custom modal content will be rendered. */}
								<div className='BaseModal__mainContentWrapper__content'>
									{children}
								</div>
							</div>
						</div>
					</ReactModal>
				)}
			</div>
		);
	}
}
