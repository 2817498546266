import { MenuItem } from '@material-ui/core';
import { countries as countryList } from 'countries-list';
import * as Yup from 'yup';

const NotANumberErrMsg = 'Must be a number.';
const NotAIntegerErrMsg = 'Must be a integer.';

export const LoyaltySchemeSettingsFormInitialValues = {
	Schemes: {
		Sales: {
			SalesAmount: 0,
			IsActive: true,
		},
		SignUp: {
			SignUpPoints: 0,
			IsActive: true,
		},
		Referral: {
			ReferralPoints: 0,
			IsActive: true,
		},
		SpecialPurchase: {
			SpecialPurchasePoints: 0,
			IsActive: true,
		},
		Review: {
			ReviewPoints: 0,
			IsActive: true,
		},
		CheckIn: {
			CheckInPoints: 0,
			CheckInPurchasePoints: 0,
			IsActive: true,
		},
	},
	VoucherGenerationLimit: 0,
	PointsValuation: 0,
	Currency: 'GBP',
};

export const LoyaltySchemeSettingsFormValidationSchema = Yup.object().shape({
	Schemes: Yup.object().shape({
		Sales: Yup.object().shape({
			SalesAmount: Yup.number()
				.typeError(NotANumberErrMsg)
				.integer(NotAIntegerErrMsg)
				.required('Required'),
			IsActive: Yup.string().required('Required'),
		}),
		SignUp: Yup.object().shape({
			SignUpPoints: Yup.number()
				.typeError(NotANumberErrMsg)
				.integer(NotAIntegerErrMsg)
				.required('Required'),
			IsActive: Yup.string().required('Required'),
		}),
		Referral: Yup.object().shape({
			ReferralPoints: Yup.number()
				.typeError(NotANumberErrMsg)
				.integer(NotAIntegerErrMsg)
				.required('Required'),
			IsActive: Yup.string().required('Required'),
		}),
		SpecialPurchase: Yup.object().shape({
			SpecialPurchasePoints: Yup.number()
				.typeError(NotANumberErrMsg)
				.integer(NotAIntegerErrMsg)
				.required('Required'),
			IsActive: Yup.string().required('Required'),
		}),
		Review: Yup.object().shape({
			ReviewPoints: Yup.number()
				.typeError(NotANumberErrMsg)
				.integer(NotAIntegerErrMsg)
				.required('Required'),
			IsActive: Yup.string().required('Required'),
		}),
		CheckIn: Yup.object().shape({
			CheckInPoints: Yup.number()
				.typeError(NotANumberErrMsg)
				.integer(NotAIntegerErrMsg)
				.required('Required'),
			CheckInPurchasePoints: Yup.number()
				.typeError(NotANumberErrMsg)
				.integer(NotAIntegerErrMsg)
				.required('Required'),
			IsActive: Yup.string().required('Required'),
		}),
	}),
	VoucherGenerationLimit: Yup.number()
		.typeError(NotANumberErrMsg)
		.integer(NotAIntegerErrMsg)
		.required('Required'),
	PointsValuation: Yup.number()
		.typeError(NotANumberErrMsg)
		.integer(NotAIntegerErrMsg)
		.required('Required'),
	Currency: Yup.string().required('Required'),
});

const currencyList = Object.values(countryList)
	.map((country) => {
		return country.currency.split(',');
	})
	.flat();

const uniqueCurrencyList = [...new Set(currencyList)].sort((a, b) => {
	if (a < b) {
		return -1;
	}
	if (a > b) {
		return 1;
	}
	return 0;
});

export const formattedCurrencyList = uniqueCurrencyList.map((currency) => {
	return (
		<MenuItem key={currency} value={currency}>
			{currency}
		</MenuItem>
	);
});
