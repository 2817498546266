/* eslint-disable sonarjs/no-small-switch */
/* eslint-disable no-param-reassign */
import axios from 'axios';

import globalValues from '../../../configs/globalValues';
import { generateToken__WitmegApiServer } from '../../tokenGenerators';

import { handleTokenExpirationApiProblem } from './helpers';

// Creating Instance
const axiosWitmegApiServerInstance = axios.create({
	baseURL: globalValues.baseURLS.WITMEG_APISERVER___BASEURL,
});

// Adding Request Interceptors (Doing things before request is sent)
axiosWitmegApiServerInstance.interceptors.request.use(
	function (config) {
		// Adding Required & Suitable Authorization Headers.
		config.headers = {
			...config.headers,
			Authorization: `Bearer ${generateToken__WitmegApiServer()}`,
		};

		return config;
	},
	function (error) {
		return Promise.reject(error);
	},
);

// Adding Response Interceptor (Doing things after response is received)
axiosWitmegApiServerInstance.interceptors.response.use(
	function (response) {
		// Handling when API says "Provided UserToken is Invalid/Expired".
		handleTokenExpirationApiProblem(response);

		// Any status code that lie within the range of 2xx cause this function to trigger. (Do something with response data)
		return response;
	},
	function (error) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger. (Do something with response error)

		const customErrType = 'AXIOSERROR';
		let customErrMsg = 'Error Occurred. Please Try Again';

		if (error.response) {
			// The request was made and the server responded with a status code
			// that falls out of the range of 2xx
		} else if (error.request) {
			// The request was made but no response was received
			switch (true) {
				case error.message.includes('Network Error'):
					customErrMsg = 'Network Error';
					break;

				default:
					break;
			}
		} else {
			// Something happened in setting up the request that triggered an Error
		}

		error.customErrType = customErrType;
		error.customErrMsg = customErrMsg;

		return Promise.reject(error);
	},
);

export default axiosWitmegApiServerInstance;
