/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/sort-comp */
import { bindActionCreators } from '@reduxjs/toolkit';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import DashboardSubInfoSection from '../../../../../components/pageSections/dashboardPage/common/DashboardSubInfoSection/DashboardSubInfoSection';
import PageLoader from '../../../../../components/widgets/loaders/PageLoader/PageLoader';
import { reqStatusTypes } from '../../../../../configs/constants';
import { sectionName as customerDashboardSectionName } from '../../../../../redux/slices/customerDashboard/customerDashboardConstants';
import { actions as myLoyaltyPointsPageSliceActions } from '../../../../../redux/slices/customerDashboard/myLoyaltyPointsPageSlice/myLoyaltyPointsPageSlice';
import { getLoggedUserDetails } from '../../../../../utilities/userAuthentication';

import './MyLoyaltyPointsPage.css';

class MyLoyaltyPointsPage extends Component {
	async handleGetLoyaltyPointsForAllLocationsByCustomerId() {
		const { myLoyaltyPointsPageSliceActions } = this.props;
		const {
			getLoyaltyPointsForAllLocationsByCustomerId: getLoyaltyPointsForAllLocationsByCustomerIdAction,
		} = myLoyaltyPointsPageSliceActions;

		const { userid } = getLoggedUserDetails();

		return await getLoyaltyPointsForAllLocationsByCustomerIdAction({
			reqData: { CustomerID: userid },
		});
	}

	async fetchNecessaryInitialPageData() {
		await this.handleGetLoyaltyPointsForAllLocationsByCustomerId();
	}

	componentDidMount() {
		this.fetchNecessaryInitialPageData();
	}

	render() {
		const { myLoyaltyPointsPageSliceState } = this.props;

		const {
			getLoyaltyPointsForAllLocationsByCustomerIdReqStatus,
			getLoyaltyPointsForAllLocationsByCustomerIdReqError,
			myLoyaltyPointsForAllLocations,
		} = myLoyaltyPointsPageSliceState;

		// ***** Multiple custom dynamic constants for easy usage. ******

		// Used to show a Loader to indicate some main async functionalities are in process.(Like fetchings started on ComponentDidMount).
		const isMainPageActionsRunning =
			getLoyaltyPointsForAllLocationsByCustomerIdReqStatus ===
			reqStatusTypes.pending;

		const isMainPageActionsError = getLoyaltyPointsForAllLocationsByCustomerIdReqError;

		// Indicating Main Page Data Loading.
		if (isMainPageActionsRunning || isMainPageActionsError) {
			return (
				<div className='MyLoyaltyPointsPage GC-DP-DashboardPage'>
					<PageLoader
						isFetching={isMainPageActionsRunning}
						loadingMsg='Please wait while we are fetching the details.'
						isError={isMainPageActionsError}
						errorMsg={isMainPageActionsError}
						onErrorTryAgainFn={() => this.fetchNecessaryInitialPageData()}
						shouldHideWhenEmpty
					/>
				</div>
			);
		}

		return (
			<div className='MyLoyaltyPointsPage GC-DP-DashboardPage'>
				<div>
					<div className='MyLoyaltyPointsPage__header GC-DP-DashboardPageHeader'>
						<h1>MY TOTAL LOYALTY POINTS</h1>
					</div>

					<div className='MyLoyaltyPointsPage__body GC-DP-DashboardPageBody'>
						<div className='MyLoyaltyPointsPage__body__totalLoyaltyPoints'>
							{/* When User Don't Have any Locations with Points */}
							{myLoyaltyPointsForAllLocations.length === 0 && (
								<DashboardSubInfoSection
									className='MyLoyaltyPointsPage__body__totalLoyaltyPoints__totalPointItem--noItems'
									type='NOITEMS'
									description="Currently, You don't have any locations with points."
								/>
							)}

							{/* When User Have Locations with Points  */}
							{myLoyaltyPointsForAllLocations.length > 0 && (
								<div className='MyLoyaltyPointsPage__body__totalLoyaltyPoints__totalPointItem'>
									{myLoyaltyPointsForAllLocations
										.slice()
										.sort((a, b) => {
											return b.CompanyName - a.CompanyName;
										})
										.map((loyaltyPointDoc, index) => {
											return (
												<div className='TotalLoyaltyPointItemCard' key={index}>
													<div className='TotalLoyaltyPointItemCard__locationName'>
														{`${loyaltyPointDoc.CompanyName} - ${loyaltyPointDoc.LocationName}`}
													</div>
													<div className='TotalLoyaltyPointItemCard__locationPoints'>
														{loyaltyPointDoc.TotalPoints}
													</div>
												</div>
											);
										})}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		myLoyaltyPointsPageSliceState:
			state[customerDashboardSectionName].myLoyaltyPointsPage,
	};
};

const mapDispatchToProps = (dispatch) => {
	const boundMyLoyaltyPointsPageSliceActions = bindActionCreators(
		myLoyaltyPointsPageSliceActions,
		dispatch,
	);
	return {
		myLoyaltyPointsPageSliceActions: boundMyLoyaltyPointsPageSliceActions,
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(MyLoyaltyPointsPage);
