import { BlurOff as BlurOffIcon } from '@material-ui/icons';
import React from 'react';

import './DashboardSubInfoSection.css';

const DashboardSubInfoSection = (props) => {
	const {
		className = '',
		icon = '',
		description = '',
		type = '',
		shouldVisible = true,
		children,
	} = props;

	let compIcon = icon;
	let compDescription = description || '';

	switch (type) {
		case 'NOITEMS': {
			compIcon = icon || <BlurOffIcon color='disabled' />;
			compDescription = description || 'Currently, No Items Available Here.';
			break;
		}

		case 'GETSTARTED': {
			break;
		}

		default: {
			break;
		}
	}

	if (!shouldVisible) {
		return null;
	}

	if (children) {
		return (
			<div className={`DashboardSubInfoSection ${className}`}>
				<div>{children}</div>
			</div>
		);
	}

	return (
		<div className={`DashboardSubInfoSection ${className}`}>
			<div>
				<div>{compIcon}</div>
				<div>{compDescription}</div>
			</div>
		</div>
	);
};

export default DashboardSubInfoSection;
