import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
	ExitToApp as ExitToAppIcon,
	Home as HomeIcon,
	CropFree as CropFreeIcon,
	PermDataSetting as PermDataSettingIcon,
	PinDrop as PinDropIcon,
	Loyalty as LoyaltyIcons,
	LocalActivity as LocalActivityIcon,
} from '@material-ui/icons';
import React from 'react';
import { Switch, Route, Link } from 'react-router-dom';

import { logoutUser } from '../../../utilities/userAuthentication';

import CustomerCheckInsPage from './subPages/CustomerCheckInsPage/CustomerCheckInsPage';
import CustomerLoyaltyAwardsPage from './subPages/CustomerLoyaltyAwardsPage/CustomerLoyaltyAwardsPage';
import CustomerReviewsPage from './subPages/CustomerReviewsPage/CustomerReviewsPage';
import DashboardHome from './subPages/DashboardHome/DashboardHome';
import LoyaltySchemeSettingsPage from './subPages/LoyaltySchemeSettingsPage/LoyaltySchemeSettingsPage';
import QrCodeGeneratorPage from './subPages/QrCodeGeneratorPage/QrCodeGeneratorPage';

// Defining which route path render which Component.
const baseRoutePath = '/businessDashboard';
export const routePaths = {
	dashboardHome: {
		path: baseRoutePath,
		component: <DashboardHome />,
	},
	customerCheckIns: {
		path: `${baseRoutePath}/customerCheckIns`,
		component: <CustomerCheckInsPage />,
	},
	customerLoyaltyAwards: {
		path: `${baseRoutePath}/customerLoyaltyAwards`,
		component: <CustomerLoyaltyAwardsPage />,
	},
	customerReviews: {
		path: `${baseRoutePath}/customerReviews`,
		component: <CustomerReviewsPage />,
	},
	qrGenerator: {
		path: `${baseRoutePath}/qrGenerator`,
		component: () => <QrCodeGeneratorPage />,
	},
	loyaltySchemeSettings: {
		path: `${baseRoutePath}/loyaltySchemeSettings`,
		component: () => <LoyaltySchemeSettingsPage />,
	},
};

export const BusinessDashboard__MenuLinks = (props) => {
	const { currentRoutePath, history } = props;

	return (
		<div>
			<List>
				<Link to={routePaths.dashboardHome.path}>
					<ListItem
						button
						selected={currentRoutePath === routePaths.dashboardHome.path}>
						<ListItemIcon>
							<HomeIcon />
						</ListItemIcon>
						<ListItemText primary='Home' />
					</ListItem>
				</Link>

				<Divider />

				<Link to={routePaths.customerCheckIns.path}>
					<ListItem
						button
						selected={currentRoutePath === routePaths.customerCheckIns.path}>
						<ListItemIcon>
							<PinDropIcon />
						</ListItemIcon>
						<ListItemText primary='Customer CheckIns' />
					</ListItem>
				</Link>

				<Link to={routePaths.customerLoyaltyAwards.path}>
					<ListItem
						button
						selected={
							currentRoutePath === routePaths.customerLoyaltyAwards.path
						}>
						<ListItemIcon>
							<LoyaltyIcons />
						</ListItemIcon>
						<ListItemText primary='Customer Awards' />
					</ListItem>
				</Link>

				<Link to={routePaths.customerReviews.path}>
					<ListItem
						button
						selected={currentRoutePath === routePaths.customerReviews.path}>
						<ListItemIcon>
							<LocalActivityIcon />
						</ListItemIcon>
						<ListItemText primary='Customer Reviews' />
					</ListItem>
				</Link>

				<Divider />

				<Link to={routePaths.qrGenerator.path}>
					<ListItem
						button
						selected={currentRoutePath === routePaths.qrGenerator.path}>
						<ListItemIcon>
							<CropFreeIcon />
						</ListItemIcon>
						<ListItemText primary='QR Generator' />
					</ListItem>
				</Link>

				<Link to={routePaths.loyaltySchemeSettings.path}>
					<ListItem
						button
						selected={
							currentRoutePath === routePaths.loyaltySchemeSettings.path
						}>
						<ListItemIcon>
							<PermDataSettingIcon />
						</ListItemIcon>
						<ListItemText primary='Loyalty Settings' />
					</ListItem>
				</Link>
			</List>

			<Divider />

			<List>
				<ListItem
					button
					onClick={() => {
						logoutUser({ redirectTo: '/businessLogin' });
					}}>
					<ListItemIcon>
						<ExitToAppIcon />
					</ListItemIcon>
					<ListItemText primary='Log Out' />
				</ListItem>
			</List>
		</div>
	);
};

export const BusinessDashboard__MenuRoutes = (props) => {
	return (
		<Switch>
			<Route path={routePaths.qrGenerator.path}>
				{routePaths.qrGenerator.component}
			</Route>

			<Route path={routePaths.customerCheckIns.path}>
				{routePaths.customerCheckIns.component}
			</Route>

			<Route path={routePaths.customerLoyaltyAwards.path}>
				{routePaths.customerLoyaltyAwards.component}
			</Route>

			<Route path={routePaths.customerReviews.path}>
				{routePaths.customerReviews.component}
			</Route>

			<Route path={routePaths.loyaltySchemeSettings.path}>
				{routePaths.loyaltySchemeSettings.component}
			</Route>

			<Route path={routePaths.dashboardHome.path}>
				{routePaths.dashboardHome.component}
			</Route>
		</Switch>
	);
};
